import { useDispatch, useSelector } from "react-redux";
import {
  LOAD_PAGE,
  CHANGE_MODE,
  TOGGLE_DRAWER,
  SET_ACTIVE_PAGE,
} from "redux/actions";

const useApplication = () => {
  const dispatch = useDispatch();
  const app = useSelector((store) => store.app);

  const loadTransition = (isLoaded, loadingTime = 7000) => {
    return (dispatch) => {
      setTimeout(() => {
        dispatch({ type: LOAD_PAGE, isLoaded });
      }, loadingTime);
    };
  };

  const toggleDrawerOpen = (toggle) =>
    dispatch({ type: TOGGLE_DRAWER, toggle });

  const setPage = (data) => dispatch({ type: SET_ACTIVE_PAGE, data });

  const changeMode = (mode) => dispatch({ type: CHANGE_MODE, mode });

  return {
    app,
    loadTransition,
    changeMode,
    toggleDrawerOpen,
    setPage,
  };
};

export default useApplication;
