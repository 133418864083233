import { useDispatch, useSelector } from "react-redux";
import {
  loginRequest,
  personalDataRequest,
  validateMfaRequest,
  logoutRequest,
  forgotPasswordRequest,
  resetPassword,
  resendOtpForMfa,
} from "api/auth";
import Cookies from "js-cookie";
import { AUTH, UPDATE_USER_PERMISSIONS, UPDATE_MFA_STATE } from "redux/actions";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const useAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((store) => store.auth);

  const changeMFAState = (mfaState) => {
    dispatch({ type: UPDATE_MFA_STATE, ...mfaState });
  };
  const authenticate = useCallback((authData) => {
    if (!authData.mfa) {
      Cookies.set("access_token", authData.token);
      changeMFAState({
        mfa: false,
        uuid: null,
      });
    } else {
      changeMFAState({
        mfa: true,
        uuid: authData.uuid,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const storeForgetPassDetails = useCallback((authData) => {
    localStorage.setItem("token", authData.token, {
      secure: true,
      httpOnly: true,
    });
    localStorage.setItem("uuid", authData.uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validateMfa = (code, callback) => {
    validateMfaRequest({
      mfa: code,
      uuid: user.uuid,
    })
      .then((res) => {
        authenticate(res.data);
        callback(res);
      })
      .catch((error) => {
        callback(null, error);
      });
  };

  /**
   * TODO: ADD REQUEST TO REMOVE TOKEN
   */
  const logout = (redirect) => {
    logoutRequest().finally(() => {
      Object.keys(Cookies.get()).forEach((cookie) => {
        if (cookie !== "device_id") {
          Cookies.remove(cookie);
          localStorage.clear();
        }
      });
      if (redirect) {
        window.location.replace("/");
      }
    });
  };

  const login = (email, password, callback) => {
    loginRequest({
      email,
      password,
    })
      .then((res) => {
        if (res.data.mfa) {
          authenticate(res.data);
          navigate("/mfa");
        } else {
          authenticate(res.data);
          localStorage.setItem("userData", res.data, {
            secure: true,
            httpOnly: true,
          });
          callback(res);
        }
      })
      .catch((error) => {
        callback(null, error);
      });
  };

  const forgotPassword = (email, callback) => {
    forgotPasswordRequest({
      email,
    })
      .then((res) => {
        storeForgetPassDetails(res.data);
        callback(res);
      })
      .catch((error) => {
        callback(null, error);
      });
  };

  const resetPasswordReq = (password, confirmpass, token, callback) => {
    resetPassword(token, {
      uuid: localStorage.getItem("uuid"),
      password,
      confirmpass,
    })
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(null, error);
      });
  };

  const userData = (callback) =>
    personalDataRequest()
      .then((res) => {
        dispatch({
          type: AUTH,
          user: res?.data?.user,
          permissions: res?.data?.permissions || [],
        });
        callback && callback();
      })
      .catch((e) => {
        console.log("error", e);
      });

  const updateUserPermissions = async (data) => {
    dispatch({ type: UPDATE_USER_PERMISSIONS, permissions: data });
  };
  const resendMfaOtp = async (callback) => {
    resendOtpForMfa({ uuid: user.uuid })
      .then((res) => {
        callback(res);
      })
      .catch((error) => {
        callback(error);
      });
  };

  return {
    user,
    isAdmin: user.role === "admin",
    loggedIn: user.loggedIn,
    login,
    logout,
    forgotPassword,
    userData,
    updateUserPermissions,
    validateMfa,
    resetPasswordReq,
    resendMfaOtp,
  };
};

export default useAuth;
