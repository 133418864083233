import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Link,
  Box,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import useActivityTracking from "hooks/useActivityTracking";
import LoadingButton from "components/LoadingButton";
import ContentBlock from "../ContentBlock";
import useStyles from "./styles-jss";

const TimelineWidget = ({ filterDate }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const {
    getActivityTracking,
    getMoreActivityTracking,
    activityTrackingReducer,
  } = useActivityTracking();
  const navigate = useNavigate();
  dayjs.extend(relativeTime);

  useEffect(() => {
    getActivityTracking({ from: filterDate[0], to: filterDate[1] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate]);

  useEffect(() => {
    if (page > 1) {
      getMoreActivityTracking({ from: filterDate[0], to: filterDate[1], page });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getMembershipTitle = (item) => {
    const entityData = JSON.parse(item.entity.data);
    switch (item.entity.action) {
      case "created":
        return (
          <>
            Created new{" "}
            <Link
              href="#"
              onClick={() => navigate(`/app/members/${entityData.id}`)}
            >
              Membership
            </Link>
          </>
        );
      case "updated":
        return (
          <>
            <Link
              href="#"
              onClick={() => navigate(`/app/members/${entityData.id}`)}
            >
              Membership
            </Link>{" "}
            updated
          </>
        );
      default:
        return "";
    }
  };

  const getInvoiceTitle = (item) => {
    const entityData = JSON.parse(item.entity.data);
    switch (item.entity.action) {
      case "created":
        return (
          <>
            Created new{" "}
            <Link
              href="#"
              onClick={() => navigate(`/app/invoices/${entityData.id}`)}
            >
              Invoice
            </Link>
          </>
        );
      case "updated":
        return (
          <>
            <Link
              href="#"
              onClick={() => navigate(`/app/invoices/${entityData.id}`)}
            >
              Invoice
            </Link>{" "}
            updated
          </>
        );
      default:
        return "";
    }
  };

  const getContractorTitle = (item) => {
    const entityData = JSON.parse(item.entity.data);
    switch (item.entity.action) {
      case "created":
        return (
          <>
            Created new{" "}
            <Link
              href="#"
              onClick={() => navigate(`/app/contractors/${entityData.id}`)}
            >
              Contractor
            </Link>
          </>
        );
      case "updated":
        return (
          <>
            <Link
              href="#"
              onClick={() => navigate(`/app/contractors/${entityData.id}`)}
            >
              Contractor
            </Link>{" "}
            updated
          </>
        );
      default:
        return "";
    }
  };

  const getJobTitle = (item) => {
    const entityData = JSON.parse(item.entity.data);
    switch (item.entity.action) {
      case "created":
        return (
          <>
            Created new{" "}
            <Link
              href="#"
              onClick={() => navigate(`/app/jobs/${entityData.id}`)}
            >
              Job
            </Link>
          </>
        );
      case "updated":
        return (
          <>
            <Link
              href="#"
              onClick={() => navigate(`/app/jobs/${entityData.id}`)}
            >
              Job
            </Link>{" "}
            updated
          </>
        );
      default:
        return "";
    }
  };

  const getNotableText = (action) => {
    switch (action) {
      case "created":
        return "Created new";
      case "updated":
        return "Updated";
      case "deleted":
        return "Deleted";
      default:
        return "";
    }
  };

  const getNoteTitle = (item) => {
    const entityData = JSON.parse(item.entity.data);
    const notableText = getNotableText(item.entity.action);
    switch (entityData.notable_type) {
      case "App\\Models\\Membership":
        return (
          <>
            {notableText}{" "}
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/members/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Note
            </Link>{" "}
            on Membership
          </>
        );
      case "App\\Models\\Contractor":
        return (
          <>
            {notableText}{" "}
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/contractors/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Note
            </Link>{" "}
            on Contractor
          </>
        );
      case "App\\Models\\Invoice":
        return (
          <>
            {notableText}{" "}
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/invoices/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Note
            </Link>{" "}
            on Invoice
          </>
        );
      case "App\\Models\\Job":
        return (
          <>
            {notableText}{" "}
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/jobs/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Note
            </Link>{" "}
            on Job
          </>
        );
      case "App\\Models\\Vendor":
        return (
          <>
            {notableText}{" "}
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/vendors/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Note
            </Link>{" "}
            on Vendor
          </>
        );
      default:
        return "";
    }
  };

  const getActivityTrackingItem = (item) => {
    let title = "";
    switch (item.trackable_type) {
      case "App\\Models\\Logs\\MembershipLog":
        title = getMembershipTitle(item);
        break;
      case "App\\Models\\Logs\\InvoiceLog":
        title = getInvoiceTitle(item);
        break;
      case "App\\Models\\Logs\\JobLog":
        title = getJobTitle(item);
        break;
      case "App\\Models\\Logs\\ContractorLog":
        title = getContractorTitle(item);
        break;
      case "App\\Models\\Logs\\NoteLog":
        title = getNoteTitle(item);
        break;
      default:
        title = "";
        break;
    }

    const time = dayjs(item.entity.created_at).fromNow();
    const desc = `${item.user.first_name} ${item.user.last_name}`;
    return { time, title, desc };
  };

  return (
    <ContentBlock
      whiteBg
      noMargin
      title="Recent Activity"
      icon="ion-ios-time-outline"
      desc=""
    >
      <div className={classes.activityWrap}>
        {activityTrackingReducer.data.length > 0 ? (
          <List>
            {activityTrackingReducer.data.map((activityTrackingItem, index) => {
              const item = getActivityTrackingItem(activityTrackingItem);
              return (
                <ListItem
                  key={index.toString()}
                  className={classes.activityList}
                >
                  <ListItemIcon>
                    <div className={classes.timeDot}>
                      <time>{item.time}</time>
                      <span />
                    </div>
                  </ListItemIcon>
                  <ListItemText
                    primary={item.title}
                    className={classes.activityText}
                    secondary={item.desc}
                  />
                </ListItem>
              );
            })}
          </List>
        ) : (
          <Typography
            textAlign="center"
            fontWeight="bold"
            fontStyle="italic"
            variant="body1"
          >
            No Data
          </Typography>
        )}
        <Box mt={2} display="flex" justifyContent="center">
          {activityTrackingReducer.meta.total &&
          activityTrackingReducer.data.length <
            activityTrackingReducer.meta.total ? (
            <LoadingButton
              loading={activityTrackingReducer.loadingMore}
              loadingposition="center"
              variant="outline"
              type="submit"
              size="small"
              onClick={() => setPage(page + 1)}
            >
              Load More
            </LoadingButton>
          ) : (
            ""
          )}
        </Box>
      </div>
    </ContentBlock>
  );
};

export default TimelineWidget;
