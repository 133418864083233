import React from "react";
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

const PhoneNumbers = () => {
  return (
    <Box>
      <Typography variant="h6">Phone Numbers</Typography>
      <Box sx={{ overflow: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Extension</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Box>
    </Box>
  );
};

export default PhoneNumbers;
