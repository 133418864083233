import React, { useEffect } from "react";
import FormField from "components/FormField";
import { Autocomplete, Tooltip } from "@mui/material";
import useUtils from "hooks/useUtils";
import BlockIcon from "@mui/icons-material/Block";
import useStyles from "./styles";

const ServiceLocationPicker = ({
  state_code,
  onChange,
  value,
  enabled,
  disabled,
  ...others
}) => {
  const {
    getServiceLocations,
    utils: { serviceLocations },
  } = useUtils();
  const classes = useStyles();

  useEffect(() => {
    getServiceLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Autocomplete
        options={serviceLocations}
        value={value}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onChange={(_ev, newValue) => onChange(newValue)}
        getOptionLabel={(option) => option.label || ""}
        renderOption={(props, option) => (
          <li
            {...props}
            style={{ pointerEvents: option.enabled === 0 ? "none" : "auto" }}
          >
            &nbsp;&nbsp;&nbsp;{option.label}{" "}
            {option.enabled === 0 && (
              <Tooltip title="Disabled service location">
                <BlockIcon fontSize="12" className={classes.disabledIcon} />
              </Tooltip>
            )}
          </li>
        )}
        renderInput={(params) => <FormField {...others} {...params} />}
      />
    </>
  );
};

ServiceLocationPicker.defaultProps = {
  required: false,
  enabled: true,
  disabled: true,
};

export default ServiceLocationPicker;
