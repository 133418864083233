import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import useQueue from "hooks/useQueue";
import TaskQueueTab from "./TaskQueueTab";

const TaskQueueClosed = () => {
  const [loading, setLoading] = useState(false);
  const {
    queue: { closedTask, meta, query: queueQuery },
    getClosedTasks,
    changeTask,
    changeTaskUser,
  } = useQueue();

  const query = useMemo(() => queueQuery, [queueQuery]);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      const fetchTasks = async () => {
        setLoading(true);
        try {
          await getClosedTasks(query);
        } finally {
          setLoading(false);
          hasFetched.current = true; // Ensure we fetch only once
        }
      };
      fetchTasks();
    }
    // eslint-disable-next-line
  }, []);

  const handleChangeAssignee = useCallback(
    async (taskId, newUserId) => {
      setLoading(true);
      try {
        await changeTaskUser(taskId, newUserId);
        await getClosedTasks(query);
      } catch (error) {
        console.error("Error changing assignee:", error);
      } finally {
        setLoading(false);
      }
    },
    [query, changeTaskUser, getClosedTasks]
  );

  const memoizedProps = useMemo(
    () => ({
      fetchTasks: getClosedTasks,
      taskData: closedTask,
      meta,
      query,
      changeTask,
      changeTaskUser: handleChangeAssignee,
      loading,
    }),
    [
      getClosedTasks,
      closedTask,
      meta,
      query,
      changeTask,
      handleChangeAssignee,
      loading,
    ]
  );

  return <TaskQueueTab {...memoizedProps} />;
};

export default TaskQueueClosed;
