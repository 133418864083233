import React, { Fragment, useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  Divider,
} from "@mui/material";
import useStyles from "./styles";
import Menu from "@mui/material/Menu";
import useSystem from "WebR/hooks/useSystem";
import MessageCard from "./MessageCard";
import Toast from "components/Toast";
import { sendMsgRequest } from "api/connect";
import Swal from "sweetalert2";

function ContactList({ contacts }) {
  const classes = useStyles();
  const [isMessageCardVisible, setMessageCardVisibility] = useState(null);
  const [loading, setLoading] = useState(false);
  const opend = Boolean(isMessageCardVisible);
  const { call } = useSystem();

  const handleCloseMsg = () => {
    setMessageCardVisibility(null);
  };

  const handleSendMessage = async (message, CellPhone) => {
    let convertedNumber = CellPhone.replace(/[\s()-]/g, "");
    try {
      setLoading(true);

      sendMsgRequest({
        to: convertedNumber,
        text: message,
      })
        .then((res) => {
          setMessageCardVisibility(null);
          Toast.fire({
            position: "bottom-end",
            icon: "success",
            title: "Message Sent Successfully!",
          });
        })
        .finally(() => {
          setLoading(false); // Set loading to false when data is fetched
        });
    } catch (error) {
      Toast.fire({
        position: "bottom-end",
        icon: "success",
        title: "Error Sending error message",
        timer: 3000,
      });
      console.error("Error sending message:", error.message);
      handleCloseMsg();
    }
  };

  const callContact = (number, nameValue) => {
    if (localStorage.getItem("status") === "offline") {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-right",
        text: "You Are Not Online!",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    } else if (localStorage.getItem("status") === "online") {
      const numericPart = number.replace(/[^\d]/g, "");
      call("+" + numericPart, nameValue);
    }
  };

  const getItem = (dataArray) =>
    dataArray.map((data) => (
      <Fragment key={data.id}>
        <ListItem button key={data.id}>
          <ListItemText
            primary={`${data.first_name} ${data.last_name}`}
            secondary={`Membership #${data.id}`}
          />
          <ListItemSecondaryAction>
            <Tooltip title="Call">
              <IconButton
                onClick={() =>
                  callContact(
                    data.id,
                    data.cell_phone,
                    `${data.first_name} ${data.last_name}`
                  )
                }
                className={classes.tealText}
                aria-label="Tel"
              >
                <PhoneIcon />
              </IconButton>
            </Tooltip>
            <Menu
              id={`menu-${data.id}`}
              anchorEl={isMessageCardVisible}
              open={opend}
              onClose={handleCloseMsg}
            >
              <MessageCard
                onSend={handleSendMessage}
                CellPhone={data.cell_phone}
                loading={loading}
              />
            </Menu>
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </Fragment>
    ));
  return <List>{getItem(contacts)}</List>;
}

export default ContactList;