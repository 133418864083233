import { SEARCH_PAYMENTS_RESULTS } from "redux/actions/paymentsActions";

const initialState = {
  loading: true,
  search: "",
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

const payments = (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH_PAYMENTS_RESULTS:
      return {
        ...state,
        results: action.results.data,
        meta: action.results,
        loading: false,
      };
    default:
      return state;
  }
};
export default payments;
