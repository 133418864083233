import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button } from "@mui/material";
import dayjs from "dayjs";
import { Toaster } from "react-hot-toast";
import "../../../../../utils/sweetalert.css";

const PaymentRow = ({ data }) => {
  const navigate = useNavigate();

  if (data?.status !== "REFUND") {
    return (
      <TableRow>
        <TableCell component="th" scope="row" width="5%">
          {data?.id}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          {data?.payer_name}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          {data?.description}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          {data?.company_name || "N/A"}{" "}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          $ {data?.amount}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          {" "}
          {data?.payment_method}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          {`${dayjs(data?.created_at).format("MM/DD/YYYY")}`}
        </TableCell>
        <TableCell component="th" scope="row" width="10%">
          {data?.status}
        </TableCell>
        <TableCell component="th" scope="row" width="25%">
          {/* <div style={{ display: "flex", gap: "8px", width: "100%" } > */}
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ mr: 0.5 }}
            onClick={() => navigate(`/app/invoices/${data.invoice_id}`)}
          >
            View
          </Button>
        </TableCell>
        <Toaster
          position="top-right"
          reverseOrder={false}
          toastOptions={{ duration: 5000 }}
        />
      </TableRow>
    );
  } else {
    return <TableRow></TableRow>;
  }
};

export default PaymentRow;
