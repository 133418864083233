import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { CircularProgress, MenuItem, Box, Typography } from "@mui/material";
import useUtils from "hooks/useUtils";

const defaultInputProps = {
  select: true,
  size: "small",
};

const ApiSelect = ({
  source,
  reducer,
  inputProps,
  itemLabel,
  showEmpty,
  showEmptyLabel,
}) => {
  const [loading, setLoading] = useState(false);
  const { utils, setValues } = useUtils();
  const [data, setData] = useState(
    reducer && utils[`${reducer}Loaded`] ? utils[reducer] : []
  );

  useEffect(() => {
    (!reducer || (reducer && utils[`${reducer}Loaded`] === false)) &&
      (async () => {
        setLoading(true);
        const res = await source();
        setData(res.data.response);
        if (reducer) {
          setValues(reducer, res.data.response);
        }
        setLoading(false);
      })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormField {...inputProps} {...defaultInputProps}>
      {loading && (
        <Box p={1} display="flex" justifyContent="space-between">
          <Typography variant="subtitle2">Please wait...</Typography>
          <CircularProgress size={20} />
        </Box>
      )}
      {showEmpty && <MenuItem value="0">{showEmptyLabel}</MenuItem>}
      {!loading &&
        data?.map((item) => (
          <MenuItem value={item?.id} key={item?.id}>
            {item[itemLabel]}
          </MenuItem>
        ))}
    </FormField>
  );
};

ApiSelect.defaultProps = {
  itemLabel: "name",
  showEmpty: false,
  showEmptyLabel: "Unassigned",
};
export default ApiSelect;
