import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ".././../../../utils/sweetalert.css";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Button,
  Box,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import useApplication from "hooks/useApplication";
import useVendors from "hooks/useVendors";
import VendorBlocks from "components/VendorBlocks";
import SearchInput from "components/SearchInput";
import "../../../../utils/sweetalert.css";
import useAccessControl from "hooks/useAccessControl";

const Vendors = () => {
  const { setPage } = useApplication();
  const { canCreateVendor } = useAccessControl();
  const navigate = useNavigate();
  const {
    vendorsReducer: { query, loading },
    search,
  } = useVendors();

  useEffect(() => {
    setPage({
      title: "Vendors Directory",
      path: "/app/vendors",
    });
    search(query);
    // eslint-disable-next-line
  }, []);

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  return (
    <VendorBlocks
      title="Vendors"
      loading={loading}
      actions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="memberSearchBox"
        >
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          {canCreateVendor() && (
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: "auto" }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/vendors/create")}
            >
              Add Vendor
            </Button>
          )}
        </Box>
      }
    >
      <div style={{ overflow: "auto" }}>
        <Table size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#ID</TableCell>
              <TableCell>Alpha</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Last check</TableCell>
              <TableCell>Last voucher</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </div>
    </VendorBlocks>
  );
};
export default Vendors;
