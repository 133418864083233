import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Notes from "components/Notes";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import { updateVendorRequest } from "api/vendors";
import useVendors from "hooks/useVendors";
import VendorForm from "../Create/components/Form";
import "../../../../utils/sweetalert.css";

const Create = () => {
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const {
    vendorsReducer: { results },
    getVendor,
  } = useVendors();

  const [vendor, setVendor] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPage({
      title: "Update Vendor",
      path: "/app/vendors",
    });
    if (!results.length) {
      setLoading(true);
      getVendor(id).then((response) => {
        setVendor(response.data);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTabValue((prevTabValue) =>
      location?.state?.notes ? "1" : prevTabValue
    );
  }, [location]);

  const setVendorForm = () => {
    if (results.find((vendor) => vendor.id === +id)) {
      return results.find((vendor) => vendor.id === +id);
    }
    return vendor;
  };

  const onSubmit = async (values) => {
    try {
      const response = await updateVendorRequest(values, id);
      if (response.data) {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: "Vendor Updated Successfully!",
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        }).then((result) => {
          navigate("/app/vendors");
        });
      }
    } catch (error) {
      Swal.fire({
        toast: true,
        position: "top-right",
        icon: "Error!",
        title: error?.response?.data?.message || "Something went wrong!",
        timer: 4000,
        showConfirmButton: false,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/app/vendors");
        }
      });
    }
  };

  return (
    <PaperBlock title="Update Vendor" loading={loading}>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
            <Tab label="Vendor information" value="0" />
            <Tab label="Notes" value="1" />
          </TabList>
        </Box>
        <TabPanel value="0">
          <VendorForm
            withHeadingLine={false}
            onSubmit={onSubmit}
            form={id && setVendorForm()}
          />
        </TabPanel>
        <TabPanel value="1">
          <Notes notableType="Vendors" notableId={id} />
        </TabPanel>
      </TabContext>
    </PaperBlock>
  );
};
export default Create;
