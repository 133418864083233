import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import { BATCH_LOADING, BATCH_SET_PENDING } from "redux/actions";
import {
  INVOICES_BATCHES_CHANGE_PAGE,
  SEARCH_INVOICES_BATCHES,
  SEARCH_INVOICES_BATCHES_RESULTS,
} from "redux/actions/invoicesBatchesActions";
import {
  approveBatchRequest,
  getInvoicesBatchesRequest,
  getPendingBatchRequest,
  processBatchRequest,
  rejectBatchRequest,
} from "../api/batch";

const useBatch = () => {
  const invoicesBatchesReducer = useSelector((state) => state.invoicesBatches);
  const batch = useSelector((state) => state.batch);
  const dispatch = useDispatch();

  const searchInvoicesBatches = debounce((query) => {
    dispatch({ type: SEARCH_INVOICES_BATCHES, query });
    getInvoicesBatchesRequest(query).then((res) => {
      dispatch({ type: SEARCH_INVOICES_BATCHES_RESULTS, results: res.data });
    });
  }, 600);

  const changePage = (newPage) => {
    dispatch({ type: INVOICES_BATCHES_CHANGE_PAGE, page: newPage + 1 });
  };

  const getInvoicesBatches = debounce((query) => {
    getInvoicesBatchesRequest(query).then((res) => {
      dispatch({ type: SEARCH_INVOICES_BATCHES_RESULTS, results: res.data });
    });
  }, 600);

  const getPendingBatch = () => {
    dispatch({ type: BATCH_LOADING, loading: true });
    getPendingBatchRequest()
      .then((res) => {
        dispatch({
          type: BATCH_SET_PENDING,
          pending: res.data.pending,
          processed: res.data.processed,
          total: res.data.total,
          batch_id: res.data.batch_id,
          completed: res.data.completed,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const processBatch = (batch_id) => processBatchRequest(batch_id);

  const approveBatch = (invoice, batchId) => {
    return approveBatchRequest(batchId, invoice);
  };
  const rejectBatch = (batchId) => {
    return rejectBatchRequest(batchId);
  };

  return {
    searchInvoicesBatches,
    changePage,
    invoicesBatchesReducer,
    getInvoicesBatches,
    batch,
    getPendingBatch,
    processBatch,
    rejectBatch,
    approveBatch,
  };
};

export default useBatch;
