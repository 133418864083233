import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import FormField from "components/FormField";
import LinkBtn from "components/LinkBtn";
import { jobRequestByClient } from "api/jobs";

import useStyles from "./style-jss";
import Swal from "sweetalert2";
import "../../../utils/sweetalert.css";

const JobRequest = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [state, _setState] = useState({
    first_name: "",
    last_name: "",
    membership_id: "",
    contact_first: "",
    contact_last: "",
    email: "",
    phone_no: "",
    service_address: "",
    zip: "",
    job_description: "",
  });

  const setState = (values) => _setState({ ...state, ...values });

  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    jobRequestByClient(state)
      .then((res) => {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: "Job Request Submited Successfully!",
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        }).then(() => {
          window.location.replace("/");
        });
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.jobRequestWrap}>
            <div className={classes.headerSection}>
              <Typography
                textAlign="center"
                color="primary"
                variant="h5"
                className={classes.title}
                gutterBottom
              >
                Job Request Form
              </Typography>
              <Grid container spacing={4}>
                <Grid item md={8}>
                  <Typography variant="caption" component="p">
                    Whether you have a small repair job, ongoing maintenance or
                    a major project, just fill out the form below to request
                    help from a HOC-authorized contractor.
                  </Typography>
                  <Box pt={1} pb={1}>
                    <Typography variant="caption" component="p">
                      For seasonal project suggestions, see our seasonal{" "}
                      <LinkBtn to="#">
                        checklist of home-maintenance task (PDF document).
                      </LinkBtn>
                    </Typography>
                  </Box>
                  <Typography variant="caption" component="p">
                    After submitting your request, you will receive a
                    confirmation e-mail from the Club explaining the next steps.
                  </Typography>
                  <Typography variant="caption" component="p">
                    (Note: These personal replies are only sent during our
                    normal business hours.)
                  </Typography>
                  <Box mt={1}>
                    <Typography variant="subtitle1" component="p">
                      Items with an asterisk(
                      <span className={classes.brandColor}>*</span>) must be
                      completed
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={4}>
                  <Typography
                    textAlign="center"
                    color="primary"
                    variant="subtitle1"
                  >
                    NOTE:
                    <br />
                    If this is an emergency, you should call the Club instead of
                    using form.
                  </Typography>
                  <Typography textAlign="center" variant="subtitle1">
                    Call: 206-622-3500
                  </Typography>
                </Grid>
              </Grid>
            </div>
            <section className={classes.formWrap}>
              <form onSubmit={handleSubmit}>
                <section className={classes.formSection}>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h6"
                    color="primary"
                  >
                    Member Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <FormField
                        label="Member First Name"
                        name="first_name"
                        value={state.first_name}
                        onChange={(e) =>
                          setState({ first_name: e.target.value })
                        }
                        required
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormField
                        label="Member Last Name"
                        name="last_name"
                        value={state.last_name}
                        onChange={(e) =>
                          setState({ last_name: e.target.value })
                        }
                        required
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormField
                        label="Membership"
                        name="membership"
                        type="number"
                        value={state.membership_id}
                        onChange={(e) =>
                          setState({ membership_id: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Typography variant="subtitle1">
                        Person requesting service if different than above
                      </Typography>
                    </Grid>
                    <Grid item md={4}>
                      <FormField
                        label="First Name"
                        name="contact_first"
                        value={state.contact_first}
                        onChange={(e) =>
                          setState({ contact_first: e.target.value })
                        }
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormField
                        label="Last Name"
                        name="contact_last"
                        value={state.contact_last}
                        onChange={(e) =>
                          setState({ contact_last: e.target.value })
                        }
                      />
                    </Grid>
                  </Grid>
                </section>
                <section className={classes.formSection}>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h6"
                    color="primary"
                  >
                    Contact & Address Information
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <FormField
                        label="Email Address"
                        name="email_address"
                        type="email"
                        value={state.email}
                        onChange={(e) => setState({ email: e.target.value })}
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormField
                        label="Best Phone Number"
                        name="phone_no"
                        value={state.phone_no}
                        onChange={(e) => setState({ phone_no: e.target.value })}
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormField
                        label="Address for service"
                        name="service_address"
                        value={state.service_address}
                        onChange={(e) =>
                          setState({ service_address: e.target.value })
                        }
                        required
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormField
                        label="Zip Code"
                        name="zip"
                        value={state.zip}
                        onChange={(e) => setState({ zip: e.target.value })}
                        required
                      />
                    </Grid>
                  </Grid>
                </section>
                <FormControlLabel
                  control={<Checkbox color="primary" name="update_records" />}
                  label={t("jobRequest:job_request_checkboxText")}
                />
                <section className={classes.formSection}>
                  <Typography
                    className={classes.sectionTitle}
                    variant="h6"
                    color="primary"
                  >
                    Service Requested
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item md={12}>
                      <Typography>
                        Explain you need for the services of a HOC-authorized
                        contractor: *
                      </Typography>
                      <FormField
                        name="job_description"
                        multiline
                        value={state.job_description}
                        onChange={(e) =>
                          setState({ job_description: e.target.value })
                        }
                        rows={6}
                        required
                      />
                    </Grid>
                  </Grid>
                </section>
                <div className={classes.btnArea}>
                  <Grid container justifyContent="center" spacing={2}>
                    <Grid item md={7}>
                      <Button
                        disabled={loading}
                        role="submit"
                        type="submit"
                        variant="contained"
                        fullWidth
                        color="primary"
                        size="large"
                      >
                        Submit Job Request
                      </Button>
                      <br />
                      {loading && (
                        <Box display="flex" justifyContent="center" pt={1}>
                          <CircularProgress />
                        </Box>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </form>
            </section>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default JobRequest;
