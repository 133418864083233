import { SET_BREADCRUMB_CUSTOM_PLACE } from "redux/actions";

const initialState = {
  breadcrumbCustomPlace: null,
};

const ui = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_BREADCRUMB_CUSTOM_PLACE:
      return { ...state, breadcrumbCustomPlace: action.breadcrumbCustomPlace };
    default:
      return state;
  }
};
export default ui;
