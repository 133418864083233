import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormField from "components/FormField";
import JobCategoryPicker from "components/Inputs/JobCategoryPicker";
import JobCodePicker from "components/Inputs/JobCodePicker";
import JobEstimates from "components/JobSheetModal/components/JobEstimates";
import useAccessControl from "hooks/useAccessControl";
import Dispatched from "./Dispatched";
import { getJob, removeContractorRequest } from "api/jobs";

const JobDetails = ({
  category_id,
  code_id,
  description,
  setJob,
  state,
  onRefresh,
  contractorId,
}) => {
  const { canReadJobEstimate } = useAccessControl();
  const [estimatesPanel, setEstimatesPanel] = useState("estimate");
  const [contractorPanel] = useState("contractor");
  const [, setDispatchPanel] = useState("dispatch");

  const handleEstimatePanelChange = (_event, isExpanded) => {
    setEstimatesPanel(isExpanded ? "estimate" : false);
  };
  const handleContractorPanelChange = (_event, isExpanded) => {
    setDispatchPanel(isExpanded ? "dispatch" : false);
  };
  const [states, _setState] = useState({
    estimates: [],
    estimates_request: [],
    note: "",
    loadingEstimates: false,
    loading: true,
    viewEstimate: false,
    activeTab: "0",
  });

  const removeContractor = () => {
    removeContractorRequest(state.id).then((res) => {
      setState(res.data);
    });
  };
  const setState = (values) => _setState({ ...states, ...values });

  const onSendEstimates = () => {
    if (onRefresh) {
      onRefresh();
    }
  };
  const [job, setJobs] = useState({});
  const changeJob = (values) => {
    const updatedJob = { ...job, ...values };
    setJobs(updatedJob);
  };

  const getData = () => {
    getJob(job.id).then((res) => {
      setJobs(res.data);
      setState({ loading: false });
    });
  };
  useEffect(() => {
    job.id ? getData() : console.log("Job ID is undefined");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.id]);
  return (
    <>
      <Grid container spacing={2} className="csmtJobDetailContent">
        <Grid item sm={12} md={12}>
          <JobCategoryPicker
            value={category_id}
            handleChange={(e) => setJob({ category_id: e.target.value })}
          />
        </Grid>
        <Grid item sm={12} md={12} sx={{ mt: 2 }}>
          <JobCodePicker
            category_id={category_id}
            value={code_id}
            handleChange={(e) => setJob({ code_id: e.target.value })}
          />
        </Grid>

        <Grid item sm={12} md={12}>
          <Typography
            marginTop={2}
            gutterBottom
            borderBottom={"solid 1px #ddd"}
            variant="subtitle2"
          >
            Job Details<sup>*</sup>
          </Typography>
          <FormField
            required
            value={description || ""}
            onChange={(e) => setJob({ description: e.target.value })}
            placeholder="Add job details"
            multiline
            rows={4}
            sx={{ width: "100%" }}
          />
          {state?.id != null && canReadJobEstimate() && (
            <Accordion
              expanded={estimatesPanel === "estimate"}
              onChange={handleEstimatePanelChange}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={"bold"}>Estimates</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider />
                {contractorId && (
                  <JobEstimates
                    open
                    job_id={state?.id}
                    job={state}
                    onRefresh={onSendEstimates}
                    hideOpenEstimate
                    contractorId={contractorId}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {(state.contractor || state.status === "DISPATCH") && (
            <Accordion
              expanded={contractorPanel === "contractor"}
              onChange={handleContractorPanelChange}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography fontWeight={"bold"}>
                  Contractor{" "}
                  {!state.dispatched && <>(Dispatch Request Pending)</>}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Divider />
                <Dispatched
                  job={job}
                  removeContractor={removeContractor}
                  onSubmit={(data) => changeJob(data)}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default JobDetails;
