/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { Box, Checkbox, FormHelperText } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import debounce from "lodash.debounce";
import dayjs from "dayjs";
import { getJobsRequest } from "api/jobs";

const JobPickerTable = ({ value, handleChange, helperText, error }) => {
  const [jobs, setJobs] = useState([]);
  const searchJobs = debounce((query) => {
    getJobsRequest(query).then((res) => {
      setJobs(res.data.result);
    });
  }, 600);

  useEffect(() => {
    getJobsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "select",
      headerName: "",
      flex: 0.01,
      renderCell: (data) => (
        <Checkbox
          checked={value == data.row.id}
          onClick={(e) => {
            if (value === data.row.id) {
              handleChange(null);
            } else {
              handleChange(data.row.id, data.row);
            }
          }}
        />
      ),
    },
    {
      field: "id",
      headerName: "Id",
      flex: 1,
      renderCell: (data) => `#${data.row.id}`,
    },
    {
      field: "code_description",
      headerName: "Job Type",
      flex: 3,
      renderCell: (data) => `${data.row?.category?.name}`,
    },
    {
      field: "membership_id",
      headerName: "Member ID",
      flex: 1.3,
      type: "number",
      renderCell: (data) => `#${data.row.membership_id}`,
    },
    {
      field: "member_name",
      headerName: "Member Name",
      flex: 3,
      renderCell: (data) =>
        `${data.row.contact?.first_name} ${data.row.contact?.last_name}`,
    },
    {
      field: "created_at",
      headerName: "Date",
      type: "number",
      flex: 1.3,
      renderCell: (data) =>
        `${dayjs(data.row.created_at).format("MM/DD/YYYY")}`,
    },
  ];

  return (
    <Box mt={3}>
      <Box mb={1}>
        <FormField
          label="Search Job"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            handleChange(null);
            searchJobs(e.target.value);
          }}
        />
      </Box>
      {jobs.length > 0 && (
        <DataGrid
          rows={jobs}
          columns={columns}
          pageSize={5}
          checkboxSelection={false}
          hideFooter
          autoHeight
          autoPageSize
          density="compact"
        />
      )}
      {helperText && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </Box>
  );
};

JobPickerTable.defaultProps = {
  error: false,
  helperText: "",
};

export default JobPickerTable;
