import { Box, Button, Grid } from "@mui/material";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { createCheckRequest } from "api/check";

const CheckForm = ({ form, onSubmit, withHeadingLine }) => {
  const navigate = useNavigate();
  const id = useLocation();
  const ContractorValidationSchema = Yup.object().shape({
    first_name: Yup.string().required("First name is required"),
    last_name: Yup.string().required("Last name is required"),
    check_number: Yup.string()
      .required("Check number is required")
      .test("check-number", "Invalid check number format", (value) => {
        const regex = /^[0-9]{3,12}$/; // 3 to 12 digits
        return regex.test(value);
      }),
    numeric_amount: Yup.string().required("Check amount is required"),
    word_amount: Yup.string()
      .required("Check word amount is required")
      .test("word-amount", "Invalid word amount format", (value) => {
        const regex = /^[a-zA-Z\s]+$/; // only allow letters and spaces
        return regex.test(value);
      }),
    bank_name: Yup.string().required("Bank name is required"),
    check_date: Yup.string().required(" Date is required"),
    check_image: Yup.string().required("Check image is required"),
  });

  const handleImage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("check_image", file);
    try {
      const response = await createCheckRequest(formData);
      localStorage.setItem("checkForm", response.data.result.id);
    } catch (error) {
      console.log("ERROR::", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      ...form,
      invoice_id: id.state || 0,
    },

    validationSchema: ContractorValidationSchema,
    onSubmit: async (values) => {
      const checkId = localStorage.getItem("checkForm");
      await onSubmit({ ...values, checkId });
    },
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          {withHeadingLine && <HeadingLine title="Check Information" />}
          <Grid container spacing={2} mt={1}>
            <input
              type="hidden"
              name="invoice_id"
              value={formik.values.invoice_id}
            />
            <Grid item xs={12} md={3}>
              <FormField
                label="First name *"
                value={formik.values.first_name}
                onChange={formik.handleChange}
                name="first_name"
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Last name *"
                value={formik.values.last_name}
                onChange={formik.handleChange}
                name="last_name"
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Check number *"
                value={formik.values.check_number}
                onChange={formik.handleChange}
                name="check_number"
                type="number"
                error={
                  formik.touched.check_number &&
                  Boolean(formik.errors.check_number)
                }
                helperText={
                  formik.touched.check_number && formik.errors.check_number
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Numeric Amount *"
                value={formik.values.numeric_amount}
                onChange={formik.handleChange}
                name="numeric_amount"
                type="number"
                error={
                  formik.touched.numeric_amount &&
                  Boolean(formik.errors.numeric_amount)
                }
                helperText={
                  formik.touched.numeric_amount && formik.errors.numeric_amount
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={3}>
              <FormField
                label="Words Amount *"
                value={formik.values.word_amount}
                onChange={formik.handleChange}
                name="word_amount"
                error={
                  formik.touched.word_amount &&
                  Boolean(formik.errors.word_amount)
                }
                helperText={
                  formik.touched.word_amount && formik.errors.word_amount
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Bank name *"
                value={formik.values.bank_name}
                onChange={formik.handleChange}
                name="bank_name"
                error={
                  formik.touched.bank_name && Boolean(formik.errors.bank_name)
                }
                helperText={formik.touched.bank_name && formik.errors.bank_name}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Account number (optional)"
                value={formik.values.account_number}
                onChange={formik.handleChange}
                name="account_number"
                type="number"
                error={
                  formik.touched.account_number &&
                  Boolean(formik.errors.account_number)
                }
                helperText={
                  formik.touched.account_number && formik.errors.account_number
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Routing number (optional)"
                value={formik.values.routing_number}
                onChange={formik.handleChange}
                name="routing_number"
                type="number"
                error={
                  formik.touched.routing_number &&
                  Boolean(formik.errors.routing_number)
                }
                helperText={
                  formik.touched.routing_number && formik.errors.routing_number
                }
              />
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={2} mt={1}>
          <Grid item xs={12} md={3}>
            <FormField
              label="Note field"
              value={formik.values.note}
              onChange={formik.handleChange}
              name="note"
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormField
              label="Date *"
              type="date"
              name="check_date"
              onChange={formik.handleChange}
              value={formik.values.check_date}
              InputLabelProps={{ shrink: true }}
              format="YYYY/MM/DD"
              error={
                formik.touched.check_date && Boolean(formik.errors.check_date)
              }
              helperText={formik.touched.check_date && formik.errors.check_date}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <input
              type="file"
              accept="image/png, image/jpg, image/jpeg"
              className="form-control-file"
              onChange={handleImage}
            />
            {formik.values.check_image && (
              <img
                src={formik.values.check_image}
                alt="Checkmark icon"
                width="100"
                height="100"
                className="check-images"
              />
            )}
          </Grid>
        </Grid>
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => navigate("/app/invoices")}
          >
            Cancel
          </Button>
        </Box>
      </form>
    </>
  );
};
CheckForm.defaultProps = {
  withHeadingLine: true,
  form: {
    invoice_id: "",
    first_name: "",
    last_name: "",
    check_number: "",
    numeric_amount: "",
    word_amount: "",
    bank_name: "",
    account_number: "",
    routing_number: "",
    note: "",
    check_date: "",
  },
};
export default CheckForm;
