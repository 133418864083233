import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";
import useUtils from "hooks/useUtils";

const JobCategoryPicker = ({ value, handleChange }) => {
  const { getJobCategories } = useUtils();
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getJobCategories().then((res) => {
      setOptions(res.data);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <FormField
      required
      name="category_id"
      select
      InputLabelProps={{ shrink: true }}
      placeholder={loading ? "Please Wait..." : ""}
      label="Job Category"
      value={options.some(option => option.id === value) ? value : ""}
      onChange={handleChange}
    >
      {options.map((category) => (
        <MenuItem
          key={`${category.id}-category`}
          value={category.id}
        >{`${category.code} - ${category.name}`}</MenuItem>
      ))}
    </FormField>
  );
};

export default JobCategoryPicker;
