import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import FormField from "components/FormField";

const BlockedReason = ({ blockId, open, setOpen }) => {
  const [reasonMessage, setReasonMessage] = useState("");

  // Function to close the dialog box
  const handleClose = () => setOpen(false);

  // Function to store the value of the entered reason
  const handleChangeReasonMessage = (e) => {
    setReasonMessage(e.target.value);
  };

  // Function to block the number functionality
  const handleBlockNumber = async () => {
    // const response = await blockContactNumber(blockId,reasonMessage);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography fontWeight="bold" variant="subtitle1">
              Reason For Blocking
            </Typography>
          </Box>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box className="mt-2">
            <FormField
              label="Message"
              name="reasonMessage"
              value={reasonMessage}
              onChange={handleChangeReasonMessage}
              multiline
              maxRows={8}
              minRows={2}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ textAlign: "end", mt: 1.5, gap: "15px", display: "flex" }}>
            <Button onClick={handleClose}>CANCEL</Button>
            <Button variant="contained" onClick={handleBlockNumber}>
              BLOCK NUMBER
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default BlockedReason;
