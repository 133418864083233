import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { Autocomplete } from "@mui/material";
import { getCitiesByStateRequest } from "api/utilities";

const CityPicker = ({ state_code, onChange, value, ...others }) => {
  const [cities, setCities] = useState([]);
  const [, setLoaded] = useState(false);

  useEffect(() => {
    if (state_code) {
      setLoaded(false);
      getCitiesByStateRequest({ state_code }).then((res) => {
        const uniqueCities = Array.from(
          new Map(res.data.cities.map((city) => [city.label, city])).values()
        );
        setCities(uniqueCities);
        setLoaded(true);
        if (value && uniqueCities.find((city) => city.id === value.id)) {
          onChange(value);
        }
      });
    }
    // eslint-disable-next-line
  }, [state_code]);

  return (
    <>
      <Autocomplete
        options={cities}
        autocomplete="off"
        value={value}
        getOptionLabel={(option) =>
          option ? option.city || option.label || "" : ""
        }
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        onChange={(_ev, newValue) => onChange(newValue)}
        renderInput={(params) => <FormField {...others} {...params} />}
      />
    </>
  );
};

CityPicker.defaultProps = {
  required: false,
};
export default CityPicker;
