import {
  getRolesRequest,
  createRolesRequest,
  updateRolesRequest,
} from "../api/roles";

const useRoles = () => {
  const getRoles = () => getRolesRequest();

  const createRole = (data) => {
    return createRolesRequest(data);
  };

  const updateRole = async (data) => {
    return await updateRolesRequest(data).catch((err) => {
      console.log(err);
    });
  };

  return {
    getRoles,
    createRole,
    updateRole,
  };
};

export default useRoles;
