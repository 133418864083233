import { Box } from "@mui/material";
import {
  BillingDetails,
  BillingTable,
  AdditionalFields,
} from "pages/App/Batch/Process/components";
import { useEffect } from "react";
import {
  DISCOUNT_TYPE_FIXED_AMOUNT,
  DISCOUNT_TYPE_PERCENTAGE,
} from "utils/constants";
import { calculateSum } from "utils/functions";

const Invoice = ({ invoice, onChange, withActions, errors }) => {
  const handleChangeItems = (items) => {
    onChange({
      ...invoice,
      items,
    });
  };

  const handleSaveNewService = (serviceData) => {
    const newItems = invoice.items;
    newItems.push({
      ...serviceData,
      invoice_batch_invoice_id: invoice.id,
    });
    onChange({
      ...invoice,
      items: newItems,
    });
  };

  const handleDeleteServices = () => {
    onChange({
      ...invoice,
      items: [],
    });
  };

  const handleChangeDetails = (data) => {
    onChange({
      ...invoice,
      ...data,
    });
  };

  const handleDeleteService = (serviceIndex) => {
    if (invoice.items[serviceIndex]) {
      onChange({
        ...invoice,
        items: invoice.items.filter((_item, index) => index !== serviceIndex),
      });
    }
  };

  useEffect(() => {
    const subTotal = calculateSum(invoice?.items, "amount")?.toFixed(2);
    const discount =
      invoice?.discountType === DISCOUNT_TYPE_PERCENTAGE
        ? subTotal * (invoice?.discountValue / 100)
        : invoice?.discountType === DISCOUNT_TYPE_FIXED_AMOUNT
        ? invoice?.discountValue
        : 0;

    const total = subTotal - discount;

    invoice?.total !== total &&
      onChange({
        ...invoice,
        total,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoice]);

  return (
    <>
      <Box mb={4}>
        <BillingDetails
          invoice={invoice}
          onChange={handleChangeDetails}
          withActions={withActions}
          errors={errors}
        />
      </Box>
      <Box>
        <AdditionalFields
          invoice={invoice}
          withActions={withActions}
          error={errors}
          onChange={handleChangeDetails}
        />
      </Box>
      <Box mb={4}>
        <BillingTable
          services={invoice}
          onChange={handleChangeItems}
          deleteService={handleDeleteService}
          deleteServices={handleDeleteServices}
          saveNewService={handleSaveNewService}
          withActions={withActions}
          error={errors?.items}
        />
      </Box>
    </>
  );
};

Invoice.defaultProps = {
  invoice: {},
  withActions: false,
  onChange: () => {},
  setInvoice: () => {},
  onChangeTotal: () => {},
  errors: {},
};

export default Invoice;
