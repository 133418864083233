import axios from "utils/axios";
import { SAVE_CHECK_SEARCH_ROUTE } from "./routes";

const getChecksRequest = (data) =>
  axios.post(`${SAVE_CHECK_SEARCH_ROUTE}/check-list`, data);

const getCheckRequest = (data) =>
  axios.post(`${SAVE_CHECK_SEARCH_ROUTE}/check-list`, data);

const createCheckRequest = (data) =>
  axios.post(`${SAVE_CHECK_SEARCH_ROUTE}/save-check`, data, {
    headers: { "Content-Type": "application/json" },
  });

export const updateCheckRequest = (data) =>
  axios.post(`${SAVE_CHECK_SEARCH_ROUTE}/update-check`, data);

export const deleteCheckRequest = (id) =>
  axios.delete(`${SAVE_CHECK_SEARCH_ROUTE}/delete-check/${id}`);

export const deletedCheckRequestf = (data) =>
  axios.get(`${SAVE_CHECK_SEARCH_ROUTE}/restore-check/${data.id}`, data);

const searchCheckRequest = (search) =>
  axios.get(SAVE_CHECK_SEARCH_ROUTE, { params: search });

export {
  getChecksRequest,
  getCheckRequest,
  createCheckRequest,
  searchCheckRequest,
};
