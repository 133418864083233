import React, { useEffect } from "react";
import { Button, Box, Chip, CircularProgress } from "@mui/material";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import useEstimates from "hooks/useEstimates";

const EstimatesData = () => {
  const navigate = useNavigate();
  const {
    estimatesReducer: { loading, estimates, query, meta },
    getEstimateData,
    changePage,
  } = useEstimates();

  useEffect(() => {
    getEstimateData();
    // eslint-disable-next-line
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getEstimateData({
      search: query,
      page: newPage + 1,
      per_page: meta?.per_page,
    });
  };

  const estimateResults =
    estimates?.data?.map((estimate) => ({
      id: estimate?.id,
      job_number: estimate?.job_id,
      contractor_name: estimate?.con_name ?? "Unassigned",
      contractor_company: estimate?.con_biz,
      estimate_amount: estimate?.total,
      submission_date: estimate?.created_at,
      status: estimate?.type,
    })) || [];

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "4px", width: "100%" }}>
      <Button
        variant="contained"
        color="primary"
        size="small"
        sx={{ mr: 2.0 }}
        onClick={() => navigate(`/app/estimates/${data.id}`)}
      >
        View
      </Button>
    </div>
  );

  // Status Chip
  const StatusChip = ({ status }) => {
    const color =
      status === "APPROVED"
        ? "green"
        : status === "PENDING"
        ? "orange"
        : status === "REJECTED"
        ? "red"
        : "gray"; // Default color if none match

    return (
      <Chip label={status} style={{ backgroundColor: color, color: "white" }} />
    );
  };

  const columns = (renderAction) => [
    { field: "id", headerName: "Estimate ID", width: 150 },
    { field: "job_number", headerName: "Job Number", width: 150 },
    { field: "contractor_name", headerName: "Contractor Name", width: 200 },
    { field: "contractor_company", headerName: "Company Name", width: 200 },
    { field: "estimate_amount", headerName: "Estimate Amount", width: 150 },
    {
      field: "submission_date",
      headerName: "Submission Date",
      width: 150,
      renderCell: (params) => dayjs(params.value).format("YYYY-MM-DD hh:mm a"),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (params) => <StatusChip status={params?.row?.status} />,
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 100,
      renderCell: renderAction,
    },
  ];

  return (
    <>
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns(renderAction)}
            rows={estimateResults}
            pageSize={Number(meta?.per_page)}
            onPageChange={handleChangePage}
            // onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
            // rowsPerPageOptions={[2, 10, 20, 50, 100]}
          />
        </Box>
      )}
    </>
  );
};

export default EstimatesData;
