import { useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import Files from "./Files";
import useFileManager from "hooks/useFileManager";
import UploadFiles from "./UploadFiles";
import FsBreadcrumbs from "./FsBreadcrumbs";

 const FileManager = ({ fileable_type, fileable_id }) => {
  const { getFiles } = useFileManager();
  const [state, _setState] = useState({
    loading: true,
    files: [],
    path: [],
    parent_id: null,
  });

  const setState = (newState) =>
    _setState((oldState) => ({ ...oldState, ...newState }));

  useEffect(() => {
    setState({ loading: true });
    getFiles({
      fileable_type,
      fileable_id,
      parent_id: state.parent_id,
    })
      .then((response) => {
        setState({
          loading: false,
          files: response.data.files,
          path: response.data.path,
        });
      })
      .catch((error) => {
        setState({ loading: false });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.parent_id]);

  const onUploadedHandler = (uploadedFile) => {
    _setState((oldState) => {
      const filesArray = [...oldState.files];
      const fileIndex = oldState.files.findIndex(
        (file) => file.label === uploadedFile.label
      );
      fileIndex !== -1
        ? (filesArray[fileIndex] = uploadedFile)
        : filesArray.push(uploadedFile); // Optionally add the new file if not found
      return { ...oldState, files: filesArray };
    });
  };

  const onUploading = (file) => {
    _setState((oldState) => ({
      ...oldState,
      files: [
        ...oldState.files,
        {
          label: file.name,
          content_type: file.type,
          created_at: new Date(),
          uuid: Math.random(),
          loading: true,
        },
      ],
    }));
  };

  if (state.loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box>
      <FsBreadcrumbs
        path={state.path}
        onChange={(uuid) => setState({ parent_id: uuid })}
      />
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <Files
            files={state.files}
            openDir={(uuid) => setState({ parent_id: uuid })}
            setState={setState}
            state={state}
            fileable_type={fileable_type}
            fileable_id={fileable_id}
          />
        </Grid>
        <Grid item xs={12} sm={4} sx={{ position: "relative", height: "auto" }}>
          <UploadFiles
            fileable_type={fileable_type}
            fileable_id={fileable_id}
            parent_id={state.parent_id}
            onUploaded={onUploadedHandler}
            onUploading={onUploading}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

FileManager.defaultProps = {
  fileable_type: "Membership",
  fileable_id: 1,
};
export default FileManager;