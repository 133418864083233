export const CHANGE_MODE = "app/CHANGE_MODE";
export const UPDATE_FORGOT_PASS_STATE = "app/UPDATE_FORGOT_PASS_STATE";
export const LOAD_PAGE = "app/LOAD_PAGE";
export const TOGGLE_DRAWER = "app/TOGGLE_DRAWER";
export const SET_ACTIVE_PAGE = "app/SET_ACTIVE_PAGE";
export const SET_BREADCRUMB_CUSTOM_PLACE = "app/SET_BREADCRUMB_CUSTOM_PLACE";
export const AUTH = "auth/AUTH";
export const MFA_OTP = "auth/MFA_OTP";
export const SET_UTILS = "app/SET_UTILS";
export const SET_UTILS_MULTIPLE = "app/SET_UTILS_MULTIPLE";
export const SET_ACTIVITIES = "app/SET_ACTIVITIES";
export const SET_ACTIVITIES_LOADING = "app/SET_ACTIVITIES_LOADING";
export const SET_ACTIVITIES_MORE_LOADING = "app/SET_ACTIVITIES_MORE_LOADING";
export const SET_ACTIVITIES_PER_PAGE = "app/SET_ACTIVITIES_PER_PAGE";
export const SET_ACTIVITIES_PAGE = "app/SET_ACTIVITIES_PAGE";
export const SET_MORE_ACTIVITIES = "app/SET_MORE_ACTIVITIES";
export const SEARCH_USERS_RESULTS = "app/SEARCH_USERS_RESULTS";
export const SEARCH_USERS = "app/SEARCH_USERS";
export const SET_USERS_LOADING = "app/SET_USERS_LOADING";
export const UPDATE_USER_DATA = "app/UPDATE_MEMBER_DATA";
export const UPDATE_USER_PERMISSIONS = "app/UPDATE_USER_PERMISSIONS";
export const SEARCH_VENDORS_LOADING = "app/SEARCH_VENDORS_LOADING";
export const SEARCH_VENDORS_RESULTS = "app/SEARCH_VENDORS_RESULTS";
export const SEARCH_VENDORS = "app/SEARCH_VENDORS";
export const BATCH_LOADING = "app/BATCH_LOADING";
export const BATCH_SET_PENDING = "app/BATCH_SET_PENDING";
export const UPDATE_MFA_STATE = "app/UPDATE_MFA_STATE";
