import React, { useEffect, useState } from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import ContentBlock from "components/ContentBlock";
import Voicemail from "components/Voicemail";
import Calllog from "components/Calllog";
import Contract from "components/Contract";
import useApplication from "hooks/useApplication";
import Blockedcontacts from "components/BlockedContacts";
import AllCalllog from "components/AllCalllog";

const Ringcentral = () => {
  const [state, _setState] = useState({
    activeTab: "0",
    loading: true,
  });

  const setState = (values) => _setState({ ...state, ...values });

  const handleChange = (_event, newValue) => {
    setState({ activeTab: newValue });
  };

  const { setPage } = useApplication();

  useEffect(() => {
    setPage({
      title: "Connect",
      path: "/app/Connect",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ContentBlock
        title="Connect"
        loading={""}
        icon="ion-ios-construct-outline"
      >
        <TabContext value={state.activeTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="CONTACTS" value="0" />
              <Tab label="CALL LOG" value="1" />
              <Tab label="ALL CALL LOG" value="2" />
              <Tab label="Voicemail" value="3" />
              <Tab label="BLOCKED NUMBERS" value="4" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Contract fileable_id={"1"} fileable_type={"aman"} />
          </TabPanel>
          <TabPanel value="1">
            <Calllog />
          </TabPanel>
          <TabPanel value="2">
            <AllCalllog />
          </TabPanel>
          <TabPanel value="3">
            <Voicemail fileable_id={"1"} fileable_type={"aman"} />
          </TabPanel>
          <TabPanel value="4">
            <Blockedcontacts />
          </TabPanel>
        </TabContext>
      </ContentBlock>
    </>
  );
};

export default Ringcentral;
