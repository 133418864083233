import React, { useState } from "react";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import brand from "theme/brand";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Hidden,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import useStyles from "../main-jss";
import { ArrowForward, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";

const Register = () => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = () => {};

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Hidden mdUp>
            <NavLink
              to="/"
              className={classNames(classes.brand, classes.outer)}
            >
              <img style={{ width: "200px" }} src={brand.logo} alt="logo" />
            </NavLink>
          </Hidden>
          <Paper className={classes.paperWrap}>
            <Hidden smDown>
              <div className={classes.topBar}>
                <NavLink to="/" className={classes.brand}>
                  <img src={brand.logo} alt={brand.name} />
                </NavLink>
              </div>
            </Hidden>
            <Typography variant="h4" className={classes.title} gutterBottom>
              Register
            </Typography>
            {/* TODO: socmedLogin */}
            <section className={classes.formWrap}>
              <form onSubmit={handleSubmit}>
                <Box pt={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="username"
                      placeholder="Username"
                      label="Username"
                      required
                    />
                  </FormControl>
                </Box>
                <Box pt={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="email"
                      placeholder="Your Email"
                      label="Your Email"
                      required
                    />
                  </FormControl>
                </Box>
                <Box pt={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="password"
                      placeholder="Your Password"
                      label="Your Password"
                      type={showPassword ? "text" : "password"}
                      required
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
                <Box pt={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      name="password_confirmation"
                      placeholder="Re-Type Password"
                      label="Re-Type Password"
                      type={"password"}
                      required
                    />
                  </FormControl>
                </Box>
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="checkbox"
                        required
                        className={classes.agree}
                      />
                    }
                    label="Agree with"
                  />
                  <a href="/" className={classes.link}>
                    Terms &amp; Condition
                  </a>
                </div>
                <div className={classes.btnArea}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Continue
                    <ArrowForward
                      className={classNames(
                        classes.rightIcon,
                        classes.iconSmall
                      )}
                      disabled={false}
                    />
                  </Button>
                </div>
              </form>
            </section>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default Register;
