import {
  Button,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { changeStatus } from "api/contractors";
import dayjs from "dayjs";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ContractorsInv = ({ invData }) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [, setApiResponse] = useState(null);
  const [statuses, setStatuses] = useState({});

  useEffect(() => {
    if (invData && invData.invoice) {
      const initialStatuses = {};
      invData.invoice.forEach((inv) => {
        initialStatuses[inv.id] = inv.status || "Received";
      });
      setStatuses(initialStatuses);
    }
  }, [invData]);

  const handleChange = (e, id) => {
    const newStatus = e.target.value;
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));
    changeStatus(id, newStatus)
      .then((response) => {
        setApiResponse(response?.data);
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          position: "top-right",
          timer: 4000,
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
        });
      });
  };

  return (
    <React.Fragment>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Con. Code</TableCell>
            <TableCell>Inv Number</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invData && invData?.invoice && invData?.invoice?.length > 0 ? (
            invData?.invoice?.map((inv) => (
              <TableRow key={inv.id}>
                <TableCell component="th" scope="row">
                  {inv.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {inv.mem_name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {inv.con}
                </TableCell>
                <TableCell component="th" scope="row">
                  {inv.hoc_inv_num}
                </TableCell>
                <TableCell component="th" scope="row">
                  {inv.total}
                </TableCell>
                <TableCell component="th" scope="row">
                  {`${dayjs(inv.created_at).format("MM/DD/YYYY hh:mm a")}`}
                </TableCell>
                <TableCell component="th" scope="row">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={statuses[inv?.id] || "RECEIVED"}
                    onChange={(e) => handleChange(e, inv?.id)}
                  >
                    <MenuItem value="RECEIVED">RECEIVED</MenuItem>
                    <MenuItem value="PENDING">PENDING</MenuItem>
                    <MenuItem value="HOLD">HOLD</MenuItem>
                    <MenuItem value="APPROVED">APPROVED</MenuItem>
                    <MenuItem value="PAID">PAID</MenuItem>
                  </Select>
                </TableCell>

                <TableCell component="th" scope="row">
                  <Button
                    onClick={() =>
                      navigate(`/app/viewInvContractorHoc/${inv?.id}/${id}`)
                    } //id?con_inv_id=5&contractor_id=23
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                No invoices found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};
export default ContractorsInv;
