import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const UpdateIndex = ({ dialogProps, children, handleClose }) => {
  return (
    <Dialog
      open={dialogProps.open}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography fontWeight="bold" variant="subtitle1">
            {dialogProps.title}
          </Typography>
          {dialogProps.subtitle && (
            <Typography
              component={"p"}
              sx={{ color: "gray" }}
              variant="caption"
            >
              {dialogProps.subtitle}
            </Typography>
          )}
        </Box>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default UpdateIndex;
