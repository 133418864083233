import { useDispatch, useSelector } from "react-redux";
import { getActivityTrackingRequest } from "api/dashboard";
import {
  SET_ACTIVITIES,
  SET_ACTIVITIES_LOADING,
  SET_MORE_ACTIVITIES,
  SET_ACTIVITIES_MORE_LOADING,
  SET_ACTIVITIES_PER_PAGE,
  SET_ACTIVITIES_PAGE,
} from "redux/actions";

const useActivityTracking = () => {
  const dispatch = useDispatch();
  const activityTrackingReducer = useSelector(
    (state) => state.activityTracking
  );
  const getActivityTracking = (params) => {
    dispatch({ type: SET_ACTIVITIES_LOADING, payload: true });
    getActivityTrackingRequest(params).then((resp) => {
      dispatch({
        type: SET_ACTIVITIES,
        payload: {
          result: resp.data.result,
          meta: resp.data.meta,
        },
      });
    });
  };

  const setActivityTrackingPerPage = (val) => {
    dispatch({ type: SET_ACTIVITIES_PER_PAGE, payload: val });
  };

  const setActivityTrackingPage = (val) => {
    dispatch({ type: SET_ACTIVITIES_PAGE, payload: val });
  };

  const getMoreActivityTracking = (params) => {
    dispatch({ type: SET_ACTIVITIES_MORE_LOADING, payload: true });
    getActivityTrackingRequest(params).then((resp) => {
      dispatch({
        type: SET_MORE_ACTIVITIES,
        payload: {
          result: resp.data.result,
          meta: resp.data.meta,
        },
      });
    });
  };

  return {
    activityTrackingReducer,
    setActivityTrackingPage,
    setActivityTrackingPerPage,
    getMoreActivityTracking,
    getActivityTracking,
  };
};

export default useActivityTracking;
