import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  TextField,
} from "@mui/material";
import useApplication from "hooks/useApplication";
import useInvoices from "hooks/useInvoices";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import Filter from "../components/Filter";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import InvoiceData from "./components/InvoiceData";
import OpenInvoiceData from "./components/OpenInvoiceColumnData";
import { useLocation } from "react-router-dom";
import "../../../../../src/components/Callincoming/index.css";
import {
  getDateRangeFilterCancelInvoiceRequest,
  getDateRangeFilterOpenInvoiceRequest,
  getDateRangeFilterRequest,
  getOpenInvoiceContractorRequest,
  getOpenInvoiceDataRequest,
} from "api/invoices";
import CancleContractorInvoiceColumnData from "pages/App/Contractors/Index/components/CancleContractorInvoiceColumnData";

const Invoices = () => {
  const [tabValue, setTabValue] = useState("0");
  const location = useLocation();
  const [dateRange, setDateRange] = useState("");
  const [invoices, setInvoices] = useState([]);
  const [invoicesOpen, setOpenInvoices] = useState([]);
  const [customStartDate, setCustomStartDate] = useState("");
  const [customDate, setCustomDate] = useState("");
  const [customEndDate, setCustomEndDate] = useState("");
  const [cancelFilter, setCancelInvoices] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (location.state?.tabValue) {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);

  const { setPage } = useApplication();
  const {
    invoicesReducer: { filter, search, meta },
    searchInvoices,
    getOpenInvoice,
    getCancleInvoiceContractors,
  } = useInvoices();

  useEffect(() => {
    setPage({
      title: "Invoices",
      path: "/app/invoices",
    });
    searchInvoices();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    searchInvoices({
      search: value,
      page: meta?.current_page,
      per_page: meta?.per_page,
    });
    getOpenInvoice({
      search: value,
    });
    getCancleInvoiceContractors({
      search: value,
    });
  };

  const handleFilter = (values) => {
    searchInvoices({
      filter: values,
      search,
      page: meta?.current_page,
      per_page: meta?.per_page,
    });
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    searchInvoices({
      search,
      page: meta?.current_page,
      per_page: meta?.per_page,
    });
  };

  //Date Range filter functionality
  const handleDateRangeChange = (event) => {
    const selectedRange = event.target.value;
    setDateRange(selectedRange);
    let payload = { interval: selectedRange };
    if (
      selectedRange === "Custom" &&
      customStartDate &&
      customEndDate &&
      customDate
    ) {
      payload.start_date = customStartDate;
      payload.end_date = customEndDate;
      payload.interval = customDate;
    }
    fetchInvoices(payload);
    fetchOpenInvoices(payload);
    fetchCancelInvoices(payload);
  };

  const fetchInvoices = (payload) => {
    getDateRangeFilterRequest(payload)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching filtered invoices:", error);
      });
  };

  const fetchOpenInvoices = (payload) => {
    getOpenInvoiceDataRequest(payload)
      .then((response) => {
        setOpenInvoices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching filtered invoices:", error);
      });
  };

  const fetchCancelInvoices = (payload) => {
    getOpenInvoiceContractorRequest(payload)
      .then((response) => {
        setCancelInvoices(response.data);
      })
      .catch((error) => {
        console.error("Error fetching filtered invoices:", error);
      });
  };

  const handleStartDateChange = (event) => {
    setCustomStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setCustomEndDate(event.target.value);
  };

  const fetchInvoicesData = async (dateRange) => {
    try {
      const response = await getDateRangeFilterRequest(dateRange);
      setInvoices(response.data);
      setCustomDate(response.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const fetchOpenInvoicesData = async (dateRange) => {
    try {
      const response = await getDateRangeFilterOpenInvoiceRequest(dateRange);
      setOpenInvoices(response.data);
      setCustomDate(response.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const fetchCancelInvoicesData = async (dateRange) => {
    try {
      const response = await getDateRangeFilterCancelInvoiceRequest(dateRange);
      setCancelInvoices(response.data);
      setCustomDate(response.data);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  const handleFilterClick = async () => {
    const payload = {
      interval: "Custom",
      start_date: customStartDate,
      end_date: customEndDate,
    };
    setLoading(true);
    try {
      await fetchInvoicesData(payload);
      await fetchOpenInvoicesData(payload);
      await fetchCancelInvoicesData(payload);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <PaperBlock
      title="Invoices"
      actions={
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            className="memberSearchBox"
          >
            <FormControl variant="outlined" sx={{ width: "200px" }}>
              <InputLabel id="date-range-label" sx={{ mt: -1 }}>
                Date Filter
              </InputLabel>
              <Select
                labelId="date-range-label"
                value={dateRange}
                onChange={handleDateRangeChange}
                label="Date Range"
                sx={{
                  mr: 2,
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                  height: "43px",
                }}
              >
                <MenuItem value="Last 7 Days">Last 7 Days</MenuItem>
                <MenuItem value="Last 30 Days">Last 30 Days</MenuItem>
                <MenuItem value="Last Year">Last Year</MenuItem>
                <MenuItem value="Custom">Custom </MenuItem>
              </Select>
            </FormControl>
            <Box display="flex" alignItems="center">
              <TextField
                label="Start Date"
                type="date"
                value={customStartDate}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                sx={{ mr: 1, height: "40px", marginTop: "0" }}
                disabled={dateRange !== "Custom"} // Disable if not "Custom"
              />
              <TextField
                label="End Date"
                type="date"
                value={customEndDate}
                onChange={handleEndDateChange}
                InputLabelProps={{ shrink: true }}
                className="dateStyle"
                sx={{ mr: 2, height: "40px", marginTop: "0" }}
                disabled={dateRange !== "Custom"} // Disable if not "Custom"
              />
              <Button
                variant="contained"
                sx={{ height: "40px", marginTop: "0" }}
                onClick={handleFilterClick}
              >
                {loading ? (
                  <CircularProgress color="success" size={24} />
                ) : (
                  "Filter"
                )}
              </Button>
            </Box>
            <Filter values={filter} onChange={handleFilter} />
            <SearchInput
              defaultValue={search}
              onChange={handleSearch}
              placeholder="By ID, First Name, Last Name, Phone or Email"
            />
          </Box>
        </>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange}>
              <Tab label="Invoices" value="0" />
              <Tab label="Open Invoices" value="1" />
              <Tab label="Canceled Invoices" value="2" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <InvoiceData invoices={invoices} />
          </TabPanel>
          <TabPanel value="1">
            <OpenInvoiceData invoicesOpen={invoicesOpen} />
          </TabPanel>
          <TabPanel value="2">
            <CancleContractorInvoiceColumnData cancelFilter={cancelFilter} />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
};
export default Invoices;
