import React from "react";

import {
  Button,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
} from "@mui/material";
import dayjs from "dayjs";

const RequestedEstimates = ({
  estimates,
  estimate: accepted,
  setView,
  hideOpenEstimate,
}) => {
  const renderEstimates = () =>
    estimates.map((estimate, index) => {
      const isAccepted = accepted && accepted.id === estimate.id;
      return (
        <TableRow
          sx={
            isAccepted
              ? { backgroundColor: "#a9f2a98a", width: { sm: "246px" } }
              : { width: { sm: "246px" } }
          }
          key={`estimate${estimate.id}`}
        >
          <TableCell>{estimate.contractor.name}</TableCell>
          <TableCell>
            {estimate.type === "REPLIED" ? (
              <b>{estimate.type}</b>
            ) : (
              estimate.type
            )}
          </TableCell>
          <TableCell>
            {dayjs(estimate.created_at).format("MM/DD/YYYY")}
          </TableCell>
          {!hideOpenEstimate && (
            <TableCell>
              <Button onClick={() => setView(index)} size="small">
                View
              </Button>
            </TableCell>
          )}
        </TableRow>
      );
    });
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Contractor Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
            {!hideOpenEstimate && <TableCell>Action</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>{renderEstimates()}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default RequestedEstimates;
