import axios from "utils/axios";

const CHANGE_PRIORITY_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/priority`;
const SET_AS_BATCH_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/change-to-batch`;
const LINK_ESTIMATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/change-to-estimate`;
const TASKS_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks`;
const NEW_TASK = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/open-tasks`;
const CLOSED_TASKS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/tasks/closed-tasks`;
const MY_TASKS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/dashboard/my-tasks`;

const getTasksRequest = async (params) => {
  return await axios.get(TASKS_ROUTE, {
    params,
  });
};

const changeTaskStatusRequest = (data) =>
  axios.post(`${TASKS_ROUTE}/change-status`, data);

const changeTaskUserRequest = (data) =>
  axios.post(`${TASKS_ROUTE}/change-user`, data);

const linkEstimateRoute = (data) => axios.post(LINK_ESTIMATE_ROUTE, data);

const processBatchRequest = (data) => axios.post(SET_AS_BATCH_ROUTE, data);

const changePriority = (taskId, value) =>
  axios.post(`${CHANGE_PRIORITY_ROUTE}/${taskId}`, { value });

const NewTaskRequest = (data) => axios.post(NEW_TASK, data);

const ClosedTaskRequest = (data) => axios.post(CLOSED_TASKS, data);

const MyTaskRequest = (data) => axios.post(MY_TASKS, data);
export {
  getTasksRequest,
  changeTaskStatusRequest,
  changeTaskUserRequest,
  linkEstimateRoute,
  changePriority,
  processBatchRequest,
  NewTaskRequest,
  ClosedTaskRequest,
  MyTaskRequest,
};
