import { useEffect, useState } from "react";
import { useRcState } from "../system/Provider";

const useSystem = () => {
  const { callService, setCall } = useRcState();
  const [, setCallType] = useState();

  useEffect(() => {
    if (callService?.callData?.session) {
      callService?.callData?.session?.on("accepted", (e) => {
        setCall({ state: "in-call" });
        setCallType(() => callService?.callData?.type);
        localStorage.setItem("terminatedValue", callService?.callData?.type);
      });
      callService?.callData?.session.on("rejected", () => {
        reset();
      });
      callService?.callData?.session?.on("progress", function () {
        console.log("Event: Progress");
      });
      callService?.callData?.session?.on("terminated", function () {
        if (localStorage.getItem("terminatedValue") !== "conference") {
          reset();
        } else if (callService?.callData?.type === "conference") {
          reset();
        }
      });
      callService?.callData?.session?.on("failed", function () {
        console.log("Event: Failed", arguments);
      });
      callService?.callData?.session?.on("muted", function () {
        console.log("Event: Muted");
      });
      callService?.callData?.session?.on("cancel", function () {
        console.log("Event: cancel");
      });
      callService?.callData?.session?.on("dtmf", function () {
        console.log("Event: dtmf");
      });
      callService?.callData?.session?.on("refer", function () {
        console.log("Event: refer");
      });
      callService?.callData?.session?.on("replaced", function () {
        console.log("Event: replaced");
      });
      callService?.callData?.session?.on("connecting", function () {
        console.log("Event: connecting");
      });
      callService?.callData?.session?.on("unmuted", function () {
        console.log("Event: Unmuted");
      });
      callService?.callData?.session?.on("bye", function () {
        console.log("Event: Bye");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callService?.callData?.session?.id]);

  const reset = async () => {
    await setCall({
      call_id: null,
      mode: "widget",
      state: null,
      type: null,
      phoneNo: "",
      contact: "",
      isUnknown: false,
      memberId: null,
      session: null,
      muted: false,
      onHold: false,
      recording: false,
      volume: 0.3,
      toVoicemail: false,
      toForward: false,
    });
  };

  const setCallData = async (data) => await setCall(data);
  const hasCall = () => callService?.callData?.type !== null;

  const answer = () => {
    if (hasCall()) {
      callService?.callData?.session
        ?.accept()
        .then(function () {
          setCallData({ state: "in-call" });
        })
        .catch(function (e) {
          console.error("Accept failed", e?.stack || e);
        });
    }
  };

  const reject = async () => {
    if (hasCall()) {
      await callService?.callData?.session.terminate();
    }
  };

  const endCall = async () => {
    if (hasCall()) {
      await callService?.callData?.session?.terminate();
    }
  };

  const call = (number, countryId, nameValue) => {
    let phoneNumber = number;
    let convertedNumber = phoneNumber.replace(/-/g, "");
    const session = callService?.webPhone?.userAgent?.invite(convertedNumber, {
      fromNumber: null,
      homeCountryId: "1",
    });

    setCallData({
      type: "Outbound",
      state: "in-call",
      phoneNo: convertedNumber, //friendlyName
      contact: nameValue,
      isUnknown: false,
      session,
      ConferanceCall: "",
    });
  };

  const callout = (number, countryId, nameValue) => {
    const session = callService?.webPhone?.userAgent?.invite(number, {
      fromNumber: null,
      homeCountryId: "1",
    });

    setCallData({
      type: "conferenceOutbound",
      state: "in-call",
      phoneNo: number, //friendlyName
      contact: nameValue,
      isUnknown: false,
      session,
      ConferanceCall: "ConferanceCall",
    });
  };

  const hold = () => {
    if (hasCall()) {
      callService?.callData?.session
        ?.hold()
        .then(() => {
          console.log("Hold");
          setCall({ onHold: true });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const unhold = () => {
    if (hasCall()) {
      callService?.callData?.session
        ?.unhold()
        .then(() => {
          console.log("Un hold");
          setCall({ onHold: false });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const mute = () => {
    if (hasCall()) {
      callService?.callData?.session?.mute();
      setCall({ muted: true });
    }
  };

  const unmute = () => {
    if (hasCall()) {
      callService?.callData?.session?.unmute();
      setCall({ muted: false });
    }
  };

  const transfer = () => {};
  const transferToVM = () => {};

  const setVolume = (value) => {
    callService.callData.session.ua.audioHelper.setVolume(value);
  };

  const startRecording = () => {
    if (hasCall()) {
      callService?.callData?.session
        ?.startRecord()
        .then(function () {
          console.log("Recording Started");
          setCall({ recording: true });
        })
        .catch(function (e) {
          console.error("Recording Start failed", e.stack || e);
        });
    }
  };

  const stopRecording = () => {
    if (hasCall()) {
      callService.callData.session
        .stopRecord()
        .then(function () {
          setCall({ recording: false });
        })
        .catch(function (e) {
          console.error("Recording Start failed", e.stack || e);
        });
    }
  };

  const ToVoiceMail = () => {
    console.log(callService, "callService.callData.session");
    if (hasCall()) {
      callService.callData.session
        .toVoicemail()
        .then(() => {
          setCall({ toVoicemail: true });
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  const Forward = (num) => {
    const strnum = num.toString();
    callService.callData.session
      .forward(strnum)
      .then(() => {
        setCall({ toForward: true });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return {
    data: callService.callData,
    setCallData,
    answer,
    reject,
    endCall,
    call,
    callout,
    mute,
    unmute,
    hold,
    unhold,
    startRecording,
    setVolume,
    transfer,
    transferToVM,
    ToVoiceMail,
    stopRecording,
    Forward,
  };
};

export default useSystem;
