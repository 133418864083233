import { useEffect, useState } from "react";
import { Tab } from "@mui/material";
import useQueue from "hooks/useQueue";
import useApplication from "hooks/useApplication";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import LinkBtn from "components/LinkBtn";
import { Button, Box } from "@mui/material";
import useBatch from "hooks/useBatch";
import TaskQueueData from "./Components/TaskQueueData";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import TaskQueueClosed from "./Components/TaskQueueClosed";
import TaskQueueMyTasks from "./Components/TaskQueueMyTasks";
import { useLocation } from "react-router-dom";

const TaskQueue = () => {
  const [tabValue, setTabValue] = useState("0");
  const location = useLocation();

  useEffect(() => {
    if (location.state?.tabValue) {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);
  const {
    queue: { loading, search, query },
    getTasks,
    getClosedTasks,
  } = useQueue();
  const { setPage } = useApplication();
  /**
   * TODO: Check if viewJob is in use.
   */
  // eslint-disable-next-line no-unused-vars
  const { getPendingBatch } = useBatch();

  const fetchTask = () => {
    getTasks(query);
  };

  useEffect(() => {
    setPage({
      title: "Task Queue",
      path: "/app/task-queue",
    });
    fetchTask();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search) => {
    getTasks({
      search,
    });
    getClosedTasks({
      search,
    });
  };

  useEffect(() => {
    getTasks(search);
    getPendingBatch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PaperBlock
        title="Task Queue"
        loading={loading}
        actions={
          <>
            <SearchInput
              defaultValue={search}
              onChange={handleSearch}
              placeholder="By ID, First Name, Last Name, Phone or Email"
            />
            <Button
              style={{ color: "white" }}
              component={LinkBtn}
              to={`/app/invoices/batches`}
              color="primary"
              size="small"
              variant="contained"
              sx={{ ml: 2, height: "40px", whiteSpace: "nowrap" }}
            >
              Pending Invoices
            </Button>
          </>
        }
      >
        <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
                <Tab label="All Tasks" value="0" />
                <Tab label="My Tasks" value="1" />
                <Tab label="Closed Tasks" value="2" />
              </TabList>
            </Box>
            <TabPanel value="0">
              <TaskQueueData />
            </TabPanel>
            <TabPanel value="1">
              <TaskQueueMyTasks />
            </TabPanel>
            <TabPanel value="2">
              <TaskQueueClosed fetchTask={fetchTask} />
            </TabPanel>
          </TabContext>
        </Box>
      </PaperBlock>
    </>
  );
};
export default TaskQueue;
