import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import ".././../utils/sweetalert.css";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { setupIntentRequest, payInvoice } from "api/payments";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import Toast from "components/Toast";
import FormField from "components/FormField";
import { searchZipDataRequest } from "api/utilities";
import StatePicker from "components/StatePicker";
import CityPicker from "components/CityPicker";

const Checkout = ({ data, handleChange, invoice, invoiceDetails }) => {
  const [loading, setLoading] = useState(false);
  const { loggedIn } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setLoading(true);
    const cardElement = elements.getElement(CardElement);
    const { data: intentData } = await setupIntentRequest(invoice.uuid, {
      pm: data,
    });

    const { client_secret } = intentData.intent;

    const stripeIntent = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: data.name,
          email: data.email,
          phone: data.phone,
        },
      },
    });
    if (stripeIntent.error) {
      setLoading(false);
      Toast.fire({
        icon: "error",
        title: stripeIntent.error.message,
      });
    } else {
      payInvoice(invoice.uuid, {
        pm: stripeIntent.setupIntent.payment_method,
      }).then(() => {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-right",
          text: loggedIn ? "Membership Activated" : "Payment Complete",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        if (loggedIn) navigate(`/app/members/${invoice.membership_id}`);
        else {
          navigate(`/`);
        }
        setLoading(false);
      });
    }
  };

  const handleZipChange = (e) => {
    if (e.target.value.length <= 4) {
      return false;
    }
    searchZipDataRequest(e.target.value)
      .then(({ data }) => {
        handleChange({
          address: data.address,
          city_id: data.city_id,
          city: {
            id: data.details.city.id,
            label: data.details.city.city,
          },
          state_code: data.details.state.code,
          zip: data.details.zip,
        });
      })
      .catch(() => {
        handleChange({
          address: "",
          city_id: null,
          city: {},
          state_code: "",
          zip: "",
        });
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid padding={"0px 10px"} container spacing={1.5}>
        <Grid item md={6}>
          <FormField
            label="Name"
            onChange={(e) => handleChange({ name: e.target.value })}
            value={data.name}
            required
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            label="Company Name"
            onChange={(e) => handleChange({ company_name: e.target.value })}
            value={data.company_name}
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            label="Email"
            onChange={(e) => handleChange({ email: e.target.value })}
            value={data.email}
            required
          />
        </Grid>
        <Grid item md={6}>
          <FormField
            label="Phone"
            onChange={(e) => handleChange({ email: e.target.value })}
            value={data.phone}
            required
          />
        </Grid>
        <Grid item md={12}>
          <FormField
            label="Address"
            value={data.address}
            onChange={(e) => handleChange({ address: e.target.value })}
          />
        </Grid>
        <Grid item md={6}>
          <CityPicker
            label="City"
            required
            InputLabelProps={{ shrink: true }}
            value={data.city}
            name="city"
            state_code={data.state_code}
            onChange={(val) => handleChange({ city: val, city_id: val?.id })}
          />
        </Grid>
        <Grid item md={3}>
          <StatePicker
            InputLabelProps={{ shrink: true }}
            name="state"
            label="State"
            value={data.state_code}
            onChange={(e) => handleChange({ state_code: e.target.value })}
          />
        </Grid>
        <Grid item md={3}>
          <FormField
            label="Zip Code"
            name="zip"
            value={data.zip}
            onChange={(e) => handleChange({ zip: e.target.value })}
            onBlur={handleZipChange}
          />
        </Grid>
        <Grid item md={12}>
          <Box pt={2}>
            <OutlinedInput
              required
              fullWidth
              inputComponent={CardElement}
              variant="outlined"
              size="small"
              color="primary"
              inputProps={{
                hidePostalCode: true,
              }}
            />
          </Box>
          {invoiceDetails && invoiceDetails()}
        </Grid>
      </Grid>
      <Box textAlign="center" pl={1.5} mt={4}>
        <Button
          role="submit"
          type="submit"
          disabled={loading}
          fullWidth
          variant="contained"
          color="primary"
        >
          {!loading
            ? `Pay $${(invoice.items[0].amount / 100).toFixed(2)}`
            : "Please wait..."}
        </Button>
        <br />
        {loading && <CircularProgress sx={{ mt: 2 }} />}
      </Box>
    </form>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutForm = ({ data, handleChange, invoice, invoiceDetails }) => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout
        invoiceDetails={invoiceDetails}
        invoice={invoice}
        data={data}
        handleChange={handleChange}
      />
    </Elements>
  );
};
export default CheckoutForm;
