import React, { useState, useEffect, useContext } from "react";
import useApplication from "hooks/useApplication";
import classNames from "classnames";
import useStyles from "../../styles-jss";
import Header from "./Header";
import { ThemeContext } from "theme/ThemeContext";
import Sidebar from "./Sidebar";
import ActionsHandler from "components/ActionsHandler";

const Layout = ({ children }) => {
  const [appHeight, setAppHeight] = useState(0);
  const classes = useStyles();
  const { loadTransition, app, toggleDrawerOpen } = useApplication();
  const changeMode = useContext(ThemeContext);

  useEffect(() => {
    setAppHeight(window.innerHeight);
    loadTransition(true);
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [app.activePage?.path]);

  return (
    <div
      style={{ minHeight: appHeight }}
      className={classNames(
        classes.appFrameInner,
        classes.sideNav,
        app.mode === "dark" ? "dark-mode" : "light-mode"
      )}
    >
      <Header
        changeMode={changeMode}
        toggleDrawerOpen={toggleDrawerOpen}
        app={app}
      />
      <Sidebar toggleDrawerOpen={toggleDrawerOpen} app={app} />
      <main
        className={classNames(
          classes.content,
          !app.sidebarOpen ? classes.contentPaddingLeft : ""
        )}
        id="mainContent"
      >
        {children}
        <ActionsHandler />
      </main>
    </div>
  );
};
export default Layout;
