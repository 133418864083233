import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./NotFound.css";
import useAuth from "hooks/useAuth";
import Member from "pages/App/Members/Index/components/Members";
import PaperBlock from "components/PaperBlock";
import { Box, Button, Tab } from "@mui/material";
import SearchInput from "components/SearchInput";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import DeletedMembers from "pages/App/Members/Index/components/DeletedMembers";
import useMembership from "hooks/useMembership";
import useApplication from "hooks/useApplication";
import useAccessControl from "hooks/useAccessControl";
import AddIcon from "@mui/icons-material/Add";

const NotFound = (props) => {
  const { user } = useAuth();
  const [tabValue, setTabValue] = useState("0");
  const { setPage } = useApplication();
  const { canCreateContractor } = useAccessControl();

  const {
    search,
    membershipReducer: { query },
  } = useMembership();

  const navigate = useNavigate();

  useEffect(() => {
    setPage({
      title: "Members Directory",
      path: "/app/members",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleSearch = (value) => {
    search(value);
  };

  useEffect(() => {
    if (user && user.data.role === "SYSTEM_ADMINISTRATOR") {
      return navigate("/app/users");
    } else if (user && user.data.role === "SYSTEM_FRIDAY_BILLING_JOB") {
      return navigate("/app/contractors");
    } else {
      // return navigate('/app/member')
    }
  });

  return (
    <PaperBlock
      title="Members"
      actions={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          className="memberSearchBox"
        >
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          {canCreateContractor() && (
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: "auto" }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/members/create")}
            >
              Add Member
            </Button>
          )}
        </Box>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab label="Members" value="0" />
              <Tab label="Canceled Members" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <Member />
          </TabPanel>
          <TabPanel value="1">
            <DeletedMembers />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
};
export default NotFound;
