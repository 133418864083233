import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import useApplication from "hooks/useApplication";
import { Grid, Box } from "@mui/material";
import CounterWidget from "components/Counter/CounterWidget";
import { getDashWidgets } from "api/dashboard";
import useStyles from "./style-jss";
import useAuth from "hooks/useAuth";

const Dashboardacc = () => {
  const { setPage } = useApplication();
  const classes = useStyles();
  const { user } = useAuth();
  const [filterDate] = useState([
    dayjs().subtract(user.data.default_date_range || 30, "days"),
    dayjs(),
  ]);
  const [widgets, setWidgets] = useState({
    memberships: 0,
    new_memberships: 0,
    tasks: 0,
    open_tasks: 0,
    jobs: 0,
    open_jobs: 0,
    invoices: 0,
    open_invoices: 0,
    loading: false,
  });

  useEffect(() => {
    setPage({
      title: "Dashboard",
      path: "/app",
    });
    setWidgets({ ...widgets, loading: true });
    getDashWidgets({ from: filterDate[0], to: filterDate[1] }).then((res) => {
      setWidgets({ ...res.data, loading: false });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate]);

  const navigate = useNavigate();
  const handleClick = (link) => {
    navigate(link);
  };

  return (
    <Box className={classes.rootCounter}>
      <Grid container spacing={2}>
        <Grid item md={4} xs={6}>
          <div onClick={() => handleClick("/app/members")}>
            <CounterWidget
              color={"#536dfe"}
              start={0}
              end={widgets.memberships}
              duration={1}
              title="Members"
              unitBefore=""
              unitAfter=""
            >
              <div className={classes.counterIcon}>
                <i className="ion-ios-people-outline" />
              </div>
            </CounterWidget>
          </div>
        </Grid>
        <Grid item md={4} xs={6}>
          <div onClick={() => handleClick("/app/members")}>
            <CounterWidget
              color={"rgb(103, 129, 255)"}
              start={0}
              end={widgets.new_memberships}
              duration={1}
              title="New Members"
              unitBefore=""
              unitAfter=""
            >
              <div className={classes.counterIcon}>
                <i className="ion-ios-person-add-outline" />
              </div>
            </CounterWidget>
          </div>
        </Grid>
        <Grid item md={4} xs={6}>
          <div onClick={() => handleClick("/app/jobs")}>
            <CounterWidget
              color={"rgb(124, 179, 66)"}
              start={0}
              end={widgets.jobs}
              duration={2}
              title="Jobs"
              unitBefore=""
              unitAfter=""
            >
              <div className={classes.counterIcon}>
                <i className="ion-ios-construct-outline" />
              </div>
            </CounterWidget>
          </div>
        </Grid>
        <Grid item md={4} xs={6}>
          <div onClick={() => handleClick("/app/jobs")}>
            <CounterWidget
              color={"rgb(144, 199, 86)"}
              start={0}
              end={widgets.open_jobs}
              duration={2}
              title="Open Jobs"
              unitBefore=""
              unitAfter=""
            >
              <div className={classes.counterIcon}>
                <i className="ion-ios-construct-outline" />
              </div>
            </CounterWidget>
          </div>
        </Grid>
        <Grid item md={4} xs={6}>
          <div onClick={() => handleClick("/app/invoices")}>
            <CounterWidget
              color={"rgb(251, 140, 0)"}
              start={0}
              end={widgets.invoices}
              duration={1}
              title="Invoices"
              unitBefore=""
              unitAfter=""
            >
              <div className={classes.counterIcon}>
                <i className="ion-ios-alert-outline" />
              </div>
            </CounterWidget>
          </div>
        </Grid>
        <Grid item md={4} xs={6}>
          <div onClick={() => handleClick("/app/invoices")}>
            <CounterWidget
              color={"rgb(255, 160, 20)"}
              start={0}
              duration={1}
              end={widgets.open_invoices}
              title="Open Invoices"
              unitBefore=""
              unitAfter=""
            >
              <div className={classes.counterIcon}>
                <i className="ion-ios-alert-outline" />
              </div>
            </CounterWidget>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Dashboardacc;
