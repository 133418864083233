import React from "react";
import {
  Box,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Button,
  Avatar,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LinkBtn from "components/LinkBtn";
import { useNavigate } from "react-router-dom";

const MemberInformation = ({ membership }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const primaryMember = membership;

  return (
    <>
      <Card elevation={0} variant="outlined">
        <CardActionArea
          onClick={() => navigate(`/app/members/${membership?.membership_id}`)}
          sx={{ backgroundColor: theme.palette.primary.main, py: 2 }}
        >
          <Box display="flex" alignItems="center" justifyContent="center">
            <Avatar sx={{ width: 100, height: 100 }} />
          </Box>
          <Typography
            backgroundColor={theme.palette.primary.main}
            color="white"
            variant="subtitle2"
            textAlign="center"
            sx={{ mt: 2 }}
          >
            Primary Member Details
          </Typography>
        </CardActionArea>
        <CardContent>
          <Typography
            variant="subtitle1"
            textAlign="center"
            color="textSecondary"
            fontWeight="bold"
          >
            {primaryMember?.first_name} {primaryMember?.last_name}
          </Typography>
          <Typography
            display="flex"
            justifyContent={"center"}
            color="textSecondary"
            variant="subtitle2"
            mt={2}
          >
            <CallIcon size="small" color="primary" sx={{ mr: 1 }} />
            {primaryMember?.cell_phone}
          </Typography>
          <Typography
            display="flex"
            justifyContent={"center"}
            color="textSecondary"
            variant="subtitle2"
            mt={1}
          >
            <EmailIcon size="small" color="primary" sx={{ mr: 1 }} />
            {primaryMember?.email}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            to={`/app/members/${membership?.membership_id ?? membership?.id}`}
            variant="contained"
            component={LinkBtn}
            size="small"
            fullWidth
            sx={{ "&:hover": { color: "white" } }}
          >
            View Member
          </Button>
        </CardActions>
      </Card>
    </>
  );
};
export default MemberInformation;
