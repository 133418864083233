import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from "@mui/material";
import useActivityTracking from "hooks/useActivityTracking";
import TableFooterWithPagination from "components/TableFooterWithPagination";

const ActivityTrackingNote = ({ user }) => {
  const {
    getActivityTracking,
    activityTrackingReducer: { data, meta },
    setActivityTrackingPerPage,
    setActivityTrackingPage,
  } = useActivityTracking();
  const navigate = useNavigate();
  const { current_page, per_page } = meta;

  useEffect(() => {
    getActivityTracking({ entity: "note", page: 1, per_page, userId: user.id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (page) => {
    setActivityTrackingPage(page);
    getActivityTracking({ entity: "note", page, per_page, userId: user.id });
  };
  const handleChangePerPage = (perPage) => {
    setActivityTrackingPerPage(parseInt(perPage));
    getActivityTracking({
      entity: "note",
      page: current_page,
      per_page: perPage,
      userId: user.id,
    });
  };

  const getNoteType = (item) => {
    const entityData = JSON.parse(item.entity.data);
    switch (entityData.notable_type) {
      case "App\\Models\\Membership":
        return (
          <>
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/members/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Membership
            </Link>
          </>
        );
      case "App\\Models\\Contractor":
        return (
          <>
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/contractors/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Contractor
            </Link>
          </>
        );
      case "App\\Models\\Invoice":
        return (
          <>
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/invoices/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Invoice
            </Link>
          </>
        );
      case "App\\Models\\Job":
        return (
          <>
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/jobs/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Job
            </Link>
          </>
        );
      case "App\\Models\\Vendor":
        return (
          <>
            <Link
              href="#"
              onClick={() =>
                navigate(`/app/vendors/${entityData.notable_id}`, {
                  state: { notes: true },
                })
              }
            >
              Vendor
            </Link>
          </>
        );
      default:
        return "";
    }
  };

  return (
    <Box sx={{ width: { sx: "100%", md: "100%" } }}>
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((activityTrackingItem, index) => {
            const entityData = JSON.parse(activityTrackingItem.entity.data);

            return (
              <TableRow key={`joblog-${index}`}>
                <TableCell component="th" scope="row">
                  {entityData.id}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getNoteType(activityTrackingItem)}
                </TableCell>
                <TableCell component="th" scope="row">
                  {activityTrackingItem?.entity?.action}
                </TableCell>
              </TableRow>
            );
          })}
          {!data.length && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {data.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={(_ev, newPage) => handleChangePage(newPage + 1)}
            onRowsPerPageChange={(e) =>
              handleChangePerPage(parseInt(e.target.value))
            }
          />
        )}
      </Table>
    </Box>
  );
};

export default ActivityTrackingNote;
