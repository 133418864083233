import React, { useEffect } from "react";
import useApplication from "hooks/useApplication";
import ContentBlock from "components/ContentBlock";
import InvoicesForm from "../components/Form";

const Create = () => {
  const { setPage } = useApplication();

  useEffect(() => {
    setPage({
      title: "Invoices",
      path: "/app/invoices",
    });
    // eslint-disable-next-line
  }, []);

  return (
    <ContentBlock title="Create invoice" icon="ion-ios-clipboard-outline">
      <InvoicesForm />
    </ContentBlock>
  );
};
export default Create;
