import {
  SEARCH_JOBS_LOADING,
  SEARCH_JOBS_RESULTS,
  SEARCH_JOBS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  CHANGE_JOB_STATUS,
  NEW_JOBS,
} from "redux/actions/jobsActions";

const initialState = {
  loading: false,
  query: "",
  results: [],
  openJobs: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

const jobs = (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH_JOBS_RESULTS: {
      return {
        ...state,
        results: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }

    case SEARCH_JOBS_LOADING:
      return { ...state, loading: action.loading };
    case SEARCH_JOBS: {
      return { ...state, query: action.query, loading: true };
    }
    case NEW_JOBS: {
      return {
        ...state,
        openJobs: action?.response?.result,
        meta: action?.response?.meta,
        loading: false,
      };
    }
    case CHANGE_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    }
    case CHANGE_JOB_STATUS: {
      const newState = { ...state };
      // eslint-disable-next-line eqeqeq
      const jobIndex = state.results.findIndex((t) => t.id == action.id);
      if (jobIndex !== -1) {
        newState.results[jobIndex].status = action.status;
      }
      return newState;
    }
    case CHANGE_PER_PAGE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    }
    default:
      return state;
  }
};
export default jobs;
