import React from "react";
import { Box, Grid, TextField, Typography, MenuItem } from "@mui/material";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import useStyles from "./styles";

export default function BillingDetailsComponent({
  invoice,
  onChange,
  withActions,
  errors,
}) {
  const classes = useStyles();
  const { terms } = useSelector((store) => store.utils);

  return (
    <Box className={classes.root}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Grid container spacing={2}>
          <Grid item xs={4}></Grid>
          <Grid item xs={2}></Grid>
          <Grid
            item
            xs={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <TextField
              size="small"
              label="HOC Invoice #"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.external_invoice_number}
              onChange={(e) =>
                onChange({ external_invoice_number: e.target.value })
              }
              sx={{ width: "50%" }}
              error={Boolean(errors.external_invoice_number)}
              helperText={errors.external_invoice_number}
              disabled={!withActions}
            />
          </Grid>
          <Grid
            item
            xs={3}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            flexDirection="column"
          >
            <Typography variant="body2">Amount Due</Typography>
            <Typography variant="h5">${invoice.total / 100}</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              select
              fullWidth
              label="Billing Address"
              value={invoice.address}
              onChange={(event) => onChange({ address: event.target.value })}
              disabled={!withActions}
            >
              <MenuItem key={1} value={1}>
                Address name
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2}>
            <TextField
              size="small"
              select
              fullWidth
              label="Terms"
              value={invoice.invoice_term_id}
              onChange={(event) =>
                onChange({ invoice_term_id: event.target.value })
              }
              disabled={!withActions}
            >
              {terms.map((term) => (
                <MenuItem key={term.days} value={term.id}>
                  {term.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <DesktopDatePicker
              label="Invoice date"
              inputFormat="MM/dd/yyyy"
              value={invoice.invoice_date}
              onChange={(date) =>
                onChange({
                  invoice_date: dayjs(date).format("YYYY-MM-DD hh:mm:ss"),
                })
              }
              renderInput={(params) => (
                <TextField fullWidth size="small" {...params} />
              )}
              disabled={!withActions}
            />
          </Grid>
          <Grid item xs={3}>
            <DesktopDatePicker
              label="Due date"
              inputFormat="MM/dd/yyyy"
              value={invoice.due_date}
              onChange={(date) =>
                onChange({
                  due_date: dayjs(date).format("YYYY-MM-DD hh:mm:ss"),
                })
              }
              renderInput={(params) => (
                <TextField fullWidth size="small" {...params} />
              )}
              disabled={!withActions}
            />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Box>
  );
}

BillingDetailsComponent.defaultProps = {
  withActions: false,
  onChange: () => {},
  errors: {},
};
