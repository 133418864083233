import {
  SEARCH_CHECK_LOADING,
  SEARCH_CHECK_RESULTS,
  SEARCH_CHECKS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/checkPaymentAction";

const initialState = {
  loading: true,
  search: "",
  query: "",
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

const checksPayment = (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH_CHECK_RESULTS: {
      return {
        ...state,
        results: action?.results?.result,
        meta: action?.results?.meta,
        loading: false,
      };
    }

    case SEARCH_CHECK_LOADING:
      return { ...state, loading: action.loading };

    case SEARCH_CHECKS:
      return { ...state, query: action.query, loading: true };

    case CHANGE_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };

    case CHANGE_PER_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };

    default:
      return state;
  }
};
export default checksPayment;
