import axios from "utils/axios";
import { DELETED_MEMBERT, DELETED_MEMBERTV } from "./routes";
const GET_MEMBERSHIP_CONTRACTORS_ROUTE_VOICE_MAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/voicemail`;
const MEMBERSHIP_SEARCH_ROUTE_VOICE_MAIL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/calls/voicemail`;
const GET_PHONE_NUMBER = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/get-phone-number`;
const BLOCK_CONTACT_NUMBER = `${process.env.REACT_APP_API_DOMAIN}`;

const searchRequest = (search) =>
  axios.get(MEMBERSHIP_SEARCH_ROUTE_VOICE_MAIL, { params: search });

const getVoiceMailContractorsRequest = (membership_id) =>
  axios.get(`${GET_MEMBERSHIP_CONTRACTORS_ROUTE_VOICE_MAIL}/${membership_id}`);

const DeletedMemberRequestv = (data) =>
  axios.delete(DELETED_MEMBERT, { params: data });
const DeletedMemberRequestf = (data) => axios.post(DELETED_MEMBERTV, data);

const getPhoneNumberRequest = (data) =>
  axios.post(GET_PHONE_NUMBER, { params: data });

const blockContactNumber = (id) => axios.post(BLOCK_CONTACT_NUMBER, id);

export {
  searchRequest,
  getVoiceMailContractorsRequest,
  DeletedMemberRequestv,
  DeletedMemberRequestf,
  getPhoneNumberRequest,
  blockContactNumber,
};
