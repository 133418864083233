import React from "react";
import { Box, CircularProgress } from "@mui/material";
import CreateNote from "./Create";
import NotesList from "./List";
import {
  getNotesRequest,
  deleteNoteRequest,
  updateNoteRequest,
  createNoteRequest,
} from "api/notes";
import Toast from "components/Toast";
import Swal from "sweetalert2";

 const Notes = ({ notableType, notableId }) => {
  const [notes, setNotes] = React.useState([]);
  const [selectedNote, setSelectedNote] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getNotes = async () => {
    try {
      const response = await getNotesRequest(notableType, notableId);
      if (response.status) {
        setNotes(response.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("error", error);
    }
  };

  const createNote = async (content) => {
    try {
      const response = await createNoteRequest(notableId, notableType, content);
      if (response.data.length > 0) {
        Toast.fire({
          position: "top-end",
          icon: "success",
          title: "Note Created Successfully!",
        });
        getNotes();
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const deleteNote = async (noteId) => {
    try {
      const resp = await deleteNoteRequest(noteId);
      if (resp.statusText === "OK") {
        getNotes();
        Swal.fire({
          toast: true,
          timer: 3000,
          position: "top-right",
          title: "Note Deleted Successfully!",
          showConfirmButton: false,
          icon: "success",
        });
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const updateNote = async (content) => {
    try {
      const response = await updateNoteRequest(selectedNote.id, content);
      if (response.data) {
        Swal.fire({
          toast: true,
          timer: 3000,
          position: "top-right",
          title: "Note Updated Successfully!",
          showConfirmButton: false,
          icon: "success",
        });
        getNotes();
        setSelectedNote(null);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const editNoteHandler = (noteId) => {
    const note = notes.find((note) => note.id === noteId);
    if (note) {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      setSelectedNote(note);
    }
  };

  if (loading) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress size={20} />
      </Box>
    );
  }

  return (
    <>
      <CreateNote
        onCreate={createNote}
        onUpdate={updateNote}
        selectedNote={selectedNote}
        cancelEdit={() => setSelectedNote(null)}
      />
      <Box mt={4}>
        <NotesList
          notes={notes}
          onDelete={deleteNote}
          onEdit={editNoteHandler}
        />
      </Box>
    </>
  );
}
export default Notes;