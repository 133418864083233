import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, FormControl, Select, MenuItem } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import SearchInput from "components/SearchInput";
import useUser from "hooks/useUser";
import useAccessControl from "hooks/useAccessControl";
import Swal from "sweetalert2";
import toast from "react-hot-toast";
import { DataGrid } from "@mui/x-data-grid";
import useStyles from "components/TaskPreview/styles-jss";
import columns from "../Components/UserColumnData";
import { locationRequest } from "api/users";
import useServiceLocations from "hooks/useServiceLocations";
import { useDispatch } from "react-redux";
import { CHANGE_PER_PAGE } from "redux/actions/usersActions";

const Users = () => {
  const classes = useStyles();
  const [all, setAll] = useState([]);
  const [officeId, setOfficeId] = useState("All");
  const [dropdownValue, setdropdownValue] = useState(false);
  const { canDeleteUser, canUpdateUser, canCreateUser } = useAccessControl();
  const navigate = useNavigate();
  const { deleteUser } = useUser();
  const { setPage } = useApplication();
  const dispatch = useDispatch();
  const {
    usersReducer: { results, query, loading, meta },
    search,
    getUsers,
    changePage,
    changePerPage,
  } = useUser();
  const {
    serviceLocationsReducer: { resultsData },
    getServiceLocations,
  } = useServiceLocations();

  useEffect(() => {
    setPage({
      title: "Users",
      path: "/app/users",
    });
    getServiceLocations();
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (e) => {
    setOfficeId(e.target.value);
    locationRequest(e.target.value)?.then((response) => {
      setAll(response?.data?.response);
      setdropdownValue(true);
    });
  };

  const handleSearch = (searchValue) => {
    search(searchValue);
  };

  const deleteUserData = async (params) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      customClass: {
        title: "swal-text",
        popup: "swal-text",
      },
      confirmButtonText: "Yes, Delete User",
    });
    if (result.isConfirmed) {
      try {
        await deleteUser(params);
        search(query);
        Swal.fire({
          toast: true,
          timer: 3000,
          position: "top-right",
          title: "User Deleted Successfully!",
          showConfirmButton: false,
          icon: "success",
        });
      } catch (err) {
        if (err.response) {
          toast.error("User Deleted Unsuccessful", {
            style: {
              fontFamily: ["Open Sans", "sans-serif"],
            },
          });
        }
      }
    }
  };

  const userResults = Array.isArray(results)
    ? results.map((userData) => ({
        id: userData?.id,
        name: userData?.name,
        email: userData?.email,
        role: userData?.role,
        extension: userData?.extension,
        uuid: userData?.uuid,
        bgColor: userData?.bgColor,
        txtColor: userData?.txtColor,
      }))
    : [];

  const settingLocationResults = resultsData.map((settingList) => ({
    id: settingList?.id,
    office: settingList?.office,
  }));

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "4px" }}>
      {canUpdateUser() && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 2 }}
          onClick={() => navigate(`/app/users/${data.row.uuid}`)}
        >
          Edit
        </Button>
      )}
      {canDeleteUser() && (
        <Button
          variant="contained"
          color="error"
          size="small"
          onClick={() => deleteUserData(data.id)}
        >
          Delete
        </Button>
      )}
    </div>
  );

  const locationResults = Array.isArray(all.result)
    ? all.result.map((userData) => ({
        id: userData?.id,
        name: userData?.name,
        email: userData?.email,
        role: userData?.role,
        extension: userData?.extension,
        uuid: userData?.uuid,
        bgColor: userData?.bgColor,
      }))
    : [];

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getUsers({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (newPageSize) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(newPageSize) });
    getUsers({
      search: query,
      page: meta.current_page,
      per_page: newPageSize,
    });
  };

  return (
    <PaperBlock
      title="Users"
      loading={loading}
      actions={
        <>
          <Box
            sx={{
              minWidth: 120,
              padding: "8.5px 14px !important",
              marginBottom: "22px",
            }}
          >
            <FormControl fullWidth>
              <label>Service location</label>
              <Select
                sx={{
                  mr: 2,
                  whiteSpace: "nowrap",
                  minWidth: "auto",
                  height: "38px",
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={officeId}
                onChange={(e) => handleChange(e)}
              >
                <MenuItem value="All">ALL</MenuItem>
                {settingLocationResults.map((officeList) => (
                  <MenuItem key={officeList.id} value={officeList.id}>
                    {officeList.office}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          {canCreateUser() && (
            <Button
              sx={{ ml: 2, whiteSpace: "nowrap", minWidth: "auto" }}
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => navigate("/app/users/create")}
            >
              Add user
            </Button>
          )}
        </>
      }
    >
      <Box style={{ overflow: "auto" }}>
        <DataGrid
          columns={columns(renderAction)}
          rows={dropdownValue === false ? userResults : locationResults}
          pageSize={Number(meta?.per_page)}
          onPageChange={handleChangePage}
          onPageSizeChange={handleChangeRowsPerPage}
          paginationMode="server"
          rowCount={meta?.total}
          page={meta?.current_page - 1}
          disableSelectionOnClick
          autoHeight
          rowsPerPageOptions={[2, 10, 20, 50, 100]}
          className={classes.dataGrid}
        />
      </Box>
    </PaperBlock>
  );
};
export default Users;
