import { Box, Typography, Stack, Chip } from "@mui/material";
import PropTypes from "prop-types";
import DoneIcon from "@mui/icons-material/Done";

const EmailPreview = ({ mail, file, onFileSelect, attachmentTitle }) => {
  return (
    <>
      <Typography variant="body2">
        <Typography variant="body2">
          <b>{mail.from_name}</b> -{" "}
          <a rel="noreferrer" href={`mailto:${mail.from}`} target="_blank">
            {mail.from}
          </a>
        </Typography>
      </Typography>
      <Box>
        <Typography pt={2} pb={2} fontWeight="bold" variant="body2">
          Subject: {mail.subject}
        </Typography>
        <Box
          border="solid 1px #ddd"
          maxHeight={300}
          p={2}
          style={{ overflow: "auto" }}
          pt={2}
          dangerouslySetInnerHTML={{
            __html: mail.content,
          }}
        />
        {mail.attachments.length > 0 && (
          <>
            <Typography fontWeight="bold" pt={2} variant="body2" component="p">
              {attachmentTitle}
            </Typography>
            <Box mt={1}>
              <Stack direction="row" spacing={1}>
                {mail.attachments.map((attachment) => (
                  <Chip
                    variant={
                      file?.uuid === attachment.uuid ? "contained" : "outlined"
                    }
                    color="secondary"
                    clickable
                    label={attachment.label}
                    key={attachment.attachment_id}
                    onClick={() => onFileSelect(attachment)}
                    icon={file?.uuid === attachment.uuid && <DoneIcon />}
                  />
                ))}
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

EmailPreview.propTypes = {
  onFileSelect: PropTypes.func,
  attachmentTitle: PropTypes.string,
};

EmailPreview.defaultProps = {
  onFileSelect: () => {},
  attachmentTitle: "",
};

export default EmailPreview;
