import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Map, { Marker } from "react-map-gl";
import RoomIcon from "@mui/icons-material/Room";

/* TODO: viewport is not used. Do we really need it? */
 const MapDialogComponent = ({ open, handleClose, location }) => {
  // eslint-disable-next-line no-unused-vars
  const [, setViewport] = useState({
    latitude: 40,
    longitude: -100,
    zoom: 10,
    bearing: 0,
    pitch: 0,
  });

  if (!open) return null;
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h6">
              {`${location.street_line} ${location.state_line}`}
            </Typography>
            <IconButton
              sx={{ ml: 2 }}
              aria-label="delete"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Map
            initialViewState={{
              longitude: parseInt(location.longitude),
              latitude: parseInt(location.latitude),
              zoom: 7,
            }}
            style={{ width: "100%", height: 600 }}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            onViewportChange={setViewport}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX}
          >
            <Marker
              longitude={location.longitude}
              latitude={-location.latitude}
              offsetTop={-20}
              offsetLeft={-10}
            >
              <RoomIcon />
            </Marker>
          </Map>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default MapDialogComponent;