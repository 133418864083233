import React, { useEffect, useState } from "react";
import { Button, Box, CircularProgress } from "@mui/material";
import Swal from "sweetalert2";
import useServiceLocations from "hooks/useServiceLocations";
import ServiceLocationManagementDialog from "../ServiceLocationManagementDialog";
import "../../../../../utils/sweetalert.css";
import Toast from "components/Toast";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./components/ServiceLocationColumnData";
import { useNavigate } from "react-router-dom";
import useAccessControl from "hooks/useAccessControl";
import { CHANGE_PER_PAGE } from "redux/actions/serviceLocations";
import { useDispatch } from "react-redux";

const ServiceLocationsIndex = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { canDeleteSetting, canUpdateSetting } = useAccessControl();
  const { deleteServiceLocation } = useServiceLocations();
  const [state, _setState] = useState({
    serviceLocations: [],
    serviceLocation: null,
    loading: false,
    activeTab: 0,
    activeRole: 0,
    open: false,
  });
  const setState = (newState) => _setState({ ...state, ...newState });

  const {
    serviceLocationsReducer: { resultsData, query, loading, meta },
    getServiceLocations,
    search,
    changePage,
  } = useServiceLocations();

  useEffect(() => {
    search(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getServiceLocations({
      search: query,
      page: newPage + 1,
      per_page: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (newPageSize) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(newPageSize) });
    getServiceLocations({
      search: query,
      page: meta.current_page,
      per_page: newPageSize,
    });
  };

  const onDeleteHandler = (venodrId) => {
    Swal.fire({
      title: "Delete service location?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      customClass: {
        title: "swal-text",
        popup: "swal-text",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteServiceLocation(venodrId)
          .then((res) => {
            Toast.fire({
              icon: "success",
              title: res.data.message,
              position: "top-right",
              timer: 3000,
            });
            search();
          })
          .catch((e) => {});
      }
    });
  };

  const onEdit = (data) => {
    setState({ open: true, serviceLocation: data });
  };

  const settingResults = resultsData.map((settingList) => ({
    id: settingList?.id,
    region_code: settingList?.region_code,
    name: settingList?.name,
    office: settingList?.office,
    city: {
      id: settingList?.city?.id,
      city: settingList?.city?.city,
      label: settingList?.city?.label,
    },
    assigned: settingList?.assigned,
    enabled: settingList?.enabled,
    state_code: settingList?.city?.state_code,
  }));

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "8px", width: "100%" }}>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2.0 }}
        size="small"
        onClick={() =>
          navigate(`/app/settings/${data.row.name.replace(/\s+/g, "-")}`)
        }
      >
        View
      </Button>
      {canUpdateSetting() && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => onEdit(data.row)}
        >
          Edit
        </Button>
      )}
      {canDeleteSetting() && (
        <Button
          sx={{ ml: 1 }}
          variant="contained"
          color="error"
          size="small"
          onClick={() => onDeleteHandler(data.id)}
        >
          Delete
        </Button>
      )}
    </div>
  );

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        {loading ? (
          <CircularProgress style={{ margin: "20px auto", display: "block" }} />
        ) : (
          <DataGrid
            columns={columns(renderAction)}
            rows={settingResults}
            pageSize={Number(meta?.per_page)}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            rowsPerPageOptions={[2, 10, 20, 50, 100]}
            autoHeight
          />
        )}
        <ServiceLocationManagementDialog
          serviceLocation={state.serviceLocation}
          open={state.open}
          onClose={() => setState({ open: false, serviceLocation: null })}
        />
      </Box>
    </>
  );
};
export default ServiceLocationsIndex;
