import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  MenuItem as Accordion,
  AccordionSummary,
  AccordionDetails,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PaperBlock from "components/PaperBlock";
import useApplication from "hooks/useApplication";
import { DataGrid } from "@mui/x-data-grid";
import useUtils from "hooks/useUtils";
import "../../../../../src/components/Callincoming/index.css";
import CreateJobModal from "components/CreateJobModal";
import useJobs from "hooks/useJobs";
import useEmail from "hooks/useEmail";
import * as Yup from "yup";
import {
  changeTaskUserRequest,
  getEmailMessage,
  loginMicrosoftRequest,
  sendEmailMessage,
  updateEmailStatus,
} from "api/Email";
import Swal from "sweetalert2";
import { NavLink } from "react-router-dom";
import FormField from "components/FormField";

// Mock data for emails
const mockEmails = [
  {
    id: 1,
    subject: "Meeting Reminder",
    email: "meeting@company.com",
    time: "2024-09-25 10:00 AM",
    assignedTo: ["John Doe"],
    status: "To Do",
    thread: [
      {
        sender: "meeting@company.com",
        cc: "cc@example.com",
        bcc: "",
        body: "Meeting reminder for project discussion on Friday. Please confirm your availability.",
      },
      {
        sender: "john.doe@company.com",
        cc: "",
        bcc: "bcc@example.com",
        body: "Please confirm your availability.",
      },
    ],
    read: false,
  },
  {
    id: 2,
    subject: "Project Update",
    email: "update@company.com",
    time: "2024-09-24 2:30 PM",
    assignedTo: ["Not Assigned"],
    status: "Acknowledged",
    thread: [
      {
        sender: "update@company.com",
        cc: "manager@company.com",
        bcc: "",
        body: "Here's the update on the project status. Please review and provide feedback.",
      },
      {
        sender: "jane.doe@company.com",
        cc: "",
        bcc: "",
        body: "Please review and provide feedback.",
      },
    ],
    read: true,
    hasAttachment: true,
    attachment: "project_update.pdf",
  },
  {
    id: 3,
    subject: "Invoice Approval",
    email: "invoice@company.com",
    time: "2024-09-23 4:15 PM",
    assignedTo: ["Alice Johnson"],
    status: "Needs Attention",
    thread: [
      {
        sender: "invoice@company.com",
        cc: "finance@company.com",
        bcc: "",
        body: "Invoice #12345 needs approval. Please review and approve it at your earliest convenience.",
      },
      {
        sender: "alice.johnson@company.com",
        cc: "",
        bcc: "",
        body: "Please review and approve it at your earliest convenience.",
      },
    ],
    read: false,
  },
  {
    id: 4,
    subject: "New User Registration",
    email: "newuser@company.com",
    time: "2024-09-22 9:00 AM",
    assignedTo: ["Not Assigned"],
    status: "Pending",
    thread: [
      {
        sender: "newuser@company.com",
        cc: "support@company.com",
        bcc: "",
        body: "A new user has registered on the platform.",
      },
    ],
    read: true,
  },
];

const statusColors = {
  "To Do": "blue",
  PENDING: "orange",
  "NEEDS ATTENTION": "red",
  ACKNOWLEDGED: "green",
};

const ContractorValidationSchema = Yup.object().shape({
  message: Yup.string().required("This field is required"),
});

const EmailFeature = () => {
  const { utils } = useUtils();
  const { setPage } = useApplication();
  const [open, setOpen] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(""); // For dialog box
  const [openDialog, setOpenDialog] = useState(false); // Dialog open/close state
  const [, setEmailData] = useState(mockEmails);
  const [selectedUserId, setSelectedUserId] = useState({});
  const [statuses, setStatuses] = useState({});
  const [emailMessage, setEmailMessage] = useState([]);
  const [loadingEmailMessages, setLoadingEmailMessages] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [loginMicrosoftdata, setLoginMicrosoftdata] = useState();
  const [error, setError] = useState("");
  const {
    emailReducer: { results, query, meta },
    getAllEmail,
    changePage,
    changePerPage,
  } = useEmail();
  const { jobsReducer, getJobsData } = useJobs();

  const fetchJons = () => {
    getJobsData(jobsReducer.query);
  };

  useEffect(() => {
    setPage({
      title: "Email",
      path: "/app/email",
    });
    getAllEmail();
    const persistedUserIds =
      JSON.parse(localStorage.getItem("selectedUserId")) || {};
    setSelectedUserId(persistedUserIds);
    const persistedEmailData =
      JSON.parse(localStorage.getItem("results")) || mockEmails;
    setEmailData(persistedEmailData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //setPage

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getAllEmail({
      search: query,
      page: newPage + 1,
      per_page: meta ? meta.per_page : 10,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    changePerPage(event);
    getAllEmail({
      search: query,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  // Function to handle email row click
  const handleEmailClick = (email) => {
    setLoadingEmailMessages(true);
    getEmailMessage(email.id).then((resp) => {
      setEmailMessage(resp.data);
      setLoadingEmailMessages(false);
    });
    setSelectedEmail(email);
    setOpenDialog(true);
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedEmail(null);
    setError("");
  };

  const transformedResults = results?.map((emailData) => ({
    id: emailData?.id,
    subject: emailData?.subject,
    from_email: emailData?.from_email,
    time: emailData?.time,
    status: emailData?.status,
    assigned_to_user: emailData?.assigned_to_user,
  }));

  //Assigned to Change CSA email page
  useEffect(() => {
    if (results && results.length > 0) {
      const initialStatuses = {};
      results.forEach((inv) => {
        initialStatuses[inv.id] = inv.status;
      });
      setStatuses(initialStatuses);
    }
  }, [results]);

  // Example of how to call handleUserIdChange for each selected user ID
  const handleChange = (e, id) => {
    const newStatus = e.target.value;
    setStatuses((prevStatuses) => ({ ...prevStatuses, [id]: newStatus }));
    const payload = {
      id: id,
      status: newStatus,
    };
    updateEmailStatus(id, payload)
      .then((response) => {
        Swal.fire({
          toast: true,
          timer: 4000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
          timerProgressBar: true,
        });
      })
      .catch((error) => {
        Swal.fire({
          position: "top-right",
          timer: 4000,
          icon: "error",
          title: error?.response?.data?.message,
          showConfirmButton: false,
        });
      });
  };

  const handleAssignedChange = async (emailId, newValue) => {
    if (newValue[newValue.length - 1] === "Not Assigned") {
      newValue = ["Not Assigned"];
    } else {
      newValue = newValue.filter((item) => item !== "Not Assigned");
    }
    const data = {
      assigned_to_user: newValue,
    };
    try {
      const response = await changeTaskUserRequest(emailId, data);
      setEmailData((prevEmailData) =>
        prevEmailData.map((email) =>
          email.id === emailId ? { ...email, assignedTo: newValue } : email
        )
      );
      await getAllEmail();
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: response.data.message,
        showConfirmButton: false,
        icon: "success",
        timerProgressBar: true,
      });
    } catch (error) {
      console.error("Error updating assigned users:", error);
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: "Error updating assigned users",
        showConfirmButton: false,
        icon: "error",
        timerProgressBar: true,
      });
    }
  };

  const CustomSelectCell = ({
    emailId,
    handleAssignedChange,
    users,
    emailData,
  }) => {
    let strToArr = Array.isArray(emailData) ? emailData : [];
    if (emailData && !Array.isArray(emailData)) {
      try {
        strToArr = JSON.parse(emailData);
      } catch (error) {
        console.error("Failed to parse emailData:", error);
        strToArr = [];
      }
    }

    return (
      <Select
        multiple
        value={JSON.parse(emailData)}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          const newValue = e.target.value;
          handleAssignedChange(emailId, newValue);
        }}
        renderValue={(selected) =>
          Array.isArray(selected) ? selected.join(", ") : selected
        }
        sx={{ minWidth: 275 }}
      >
        {/* "Not Assigned" option */}
        <MenuItem value="Not Assigned">
          <Checkbox checked={strToArr.includes("Not Assigned")} />
          <ListItemText primary="Not Assigned" />
        </MenuItem>

        {/* Other user options */}
        {users?.map((option) => (
          <MenuItem key={option?.id} value={option?.name}>
            <Checkbox checked={strToArr.includes(option?.name)} />
            <ListItemText primary={option?.name} />
          </MenuItem>
        ))}
      </Select>
    );
  };

  const columns = (renderAction) => [
    {
      field: "subject",
      headerName: "Subject",
      type: "string",
      width: 450,
    },
    {
      field: "from_email",
      headerName: "Email",
      type: "string",
      width: 300,
    },
    {
      field: "time",
      headerName: "Time",
      type: "string",
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    {
      field: "assigned_to_user",
      headerName: "Assign To",
      renderCell: (params) => (
        <CustomSelectCell
          emailId={params.row.id}
          emailData={params.row.assigned_to_user}
          selectedUserId={selectedUserId}
          handleAssignedChange={handleAssignedChange}
          users={utils?.users}
        />
      ),
      width: 300,
    },
    {
      field: "status",
      headerName: "Assigned Status",
      type: "string",
      width: 200,
      renderCell: (params) => {
        const currentStatus = statuses[params?.row?.id] || params?.row?.status;
        const currentColor = statusColors[currentStatus] || "black";
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={currentStatus}
            onChange={(e) => handleChange(e, params?.row?.id)}
            sx={{ width: "100%", color: currentColor }}
          >
            <MenuItem value="To Do" style={{ color: statusColors["To Do"] }}>
              To Do
            </MenuItem>
            <MenuItem
              value="PENDING"
              style={{ color: statusColors["PENDING"] }}
            >
              PENDING
            </MenuItem>
            <MenuItem
              value="NEEDS ATTENTION"
              style={{ color: statusColors["NEEDS ATTENTION"] }}
            >
              NEEDS ATTENTION
            </MenuItem>
            <MenuItem
              value="ACKNOWLEDGED"
              style={{ color: statusColors["ACKNOWLEDGED"] }}
            >
              ACKNOWLEDGED
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      type: "string",
      width: 100,
      sortable: false,
      renderCell: renderAction,
    },
  ];

  const renderAction = (data) => (
    <div>
      <Button size="small" style={{ color: "#000" }}>
        <MoreVertIcon />
      </Button>
    </div>
  );

  //email reply functionality
  const handleClick = (id) => {
    setLoading(true);
    const dataToValidate = { message: message };
    ContractorValidationSchema.validate(dataToValidate)
      .then(() => {
        // Validation passed, proceed with sending the email
        const payload = {
          message: message,
          emailId: id,
        };
        sendEmailMessage(id, payload)
          .then((resp) => {
            Swal.fire({
              toast: true,
              timer: 4000,
              position: "top-right",
              title: resp.data.message,
              showConfirmButton: false,
              icon: "success",
              timerProgressBar: true,
            });
            setLoading(false);
            setMessage("");
            setError("");
          })
          .catch((error) => {
            console.error(error);
            Swal.fire({
              position: "top-right",
              timer: 4000,
              icon: "error",
              title: error?.response?.data?.message,
              showConfirmButton: false,
            });
            setLoading(false);
          });
      })
      .catch((validationError) => {
        setError(validationError.message);
        setLoading(false);
      });
  };

  const handleChangeMessage = (e) => {
    const { value } = e.target;
    setMessage(value);
    if (error) {
      setError("");
    }
  };

  const handleLoginClick = () => {
    // window.location.href = "http://localhost:8000/api/v1/auth/redirect";
    // window.location.href = "https://archernational-back-qa.chetu.com/api/v1/auth/redirect";
    window.location.href =
      "https://api.hocportal.online/api/v1/auth/redirect";
  };

  const loginMicrosoft = () => {
    loginMicrosoftRequest().then((res) => {
      setLoginMicrosoftdata(res.data);
    });
  };

  useEffect(() => {
    loginMicrosoft();
  }, []);

  return (
    <>
      <PaperBlock title="Email">
        {loginMicrosoftdata === false ? (
          <NavLink
            className="microsoft"
            to=""
            onClick={handleLoginClick}
            style={{ fontFamily: "fantacy", fontWeight: "600px" }}
          >
            <img
              style={{ width: "25px", paddingBottom: "4px" }}
              src="/images/microsoft.png"
              alt="img"
            />
            &nbsp; Login to Microsoft
          </NavLink>
        ) : (
          <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
            <DataGrid
              rows={transformedResults}
              columns={columns(renderAction)}
              autoHeight
              onCellClick={(params) => {
                if (params.field !== "status") {
                  handleEmailClick(params.row);
                }
              }}
              pageSize={Number(meta?.per_page)}
              rowsPerPageOptions={[Number(meta?.per_page)]}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              paginationMode="server"
              rowCount={meta?.total}
              page={meta?.current_page - 1}
            />
          </Box>
        )}
      </PaperBlock>

      {/* Dialog for showing detailed email information */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="md"
        fullWidth
      >
        {selectedEmail && (
          <>
            <DialogTitle>
              {selectedEmail.subject} - {selectedEmail.from_email}
              <Button
                variant="contained"
                color="primary"
                sx={{ ml: 2 }}
                onClick={() => handleClick(selectedEmail.id)}
              >
                Reply
                {loading && (
                  <Box pl={2}>
                    <CircularProgress color="success" size={16} />{" "}
                  </Box>
                )}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                sx={{ ml: 2 }}
                onClick={() => setOpen(true)}
              >
                Create Job
              </Button>
              {open && (
                <CreateJobModal
                  open={open}
                  handleClose={() => setOpen(false)}
                  fetchJons={fetchJons}
                />
              )}
              <FormField
                label="Message"
                name="message"
                value={message}
                onChange={handleChangeMessage} // This will update the message state
                sx={{ mt: 2 }}
              />
              {error && (
                <span style={{ color: "red", fontSize: "15px" }}>{error}</span>
              )}
            </DialogTitle>
            <DialogContent dividers>
              <Typography paragraph>
                <strong>Attachment:</strong> {"N/A"}
              </Typography>
              {loadingEmailMessages ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                emailMessage?.map((message, index) => (
                  <Accordion key={index} defaultExpanded={index === 0}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>{message?.content}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mt: 2,
                        }}
                      >
                        <Typography variant="caption">
                          From: {message?.from}, CC: {message?.cc}, BCC:{" "}
                          {message?.bcc}
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))
              )}
            </DialogContent>
            <DialogActions>
              <Button aria-modal="true" onClick={handleCloseDialog}>
                Close
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};

export default EmailFeature;
