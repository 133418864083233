import { useEffect } from "react";
import { useFormik } from "formik";

const ShippingFields = ({ shippingData }) => {
  const formik = useFormik({
    initialValues: {
      city_id: shippingData?.city_id || null,
      ship_date: shippingData?.ship_date || null,
      ship_to_line_1: shippingData?.ship_to_line_1 || "",
      ship_to_line_2: shippingData?.ship_to_line_2 || "",
      ship_to_line_3: shippingData?.ship_to_line_3 || "",
      ship_to_line_4: shippingData?.ship_to_line_4 || "",
      ship_to_city: shippingData?.ship_to_city || {},
      ship_to_state: shippingData?.ship_to_state || "",
      ship_to_postal_code: shippingData?.ship_to_postal_code || "",
    },
  });

  useEffect(() => {}, [formik.values]);

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      ship_date: shippingData?.ship_date || null,
      ship_to_line_1: shippingData?.ship_to_line_1 || "",
      ship_to_line_2: shippingData?.ship_to_line_2 || "",
      ship_to_line_3: shippingData?.ship_to_line_3 || "",
      ship_to_line_4: shippingData?.ship_to_line_4 || "",
      ship_to_city: shippingData?.ship_to_city || null,
      ship_to_state: shippingData?.ship_to_state || "",
      ship_to_postal_code: shippingData?.ship_to_postal_code || "",
      ship_to_country: shippingData?.ship_to_country || "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default ShippingFields;
