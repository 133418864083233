const columns = (renderAction) => [
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 400,
  },
  {
    field: "BlockedDate",
    headerName: "Blocked Date",
    width: 400,
  },
  {
    field: "Notes",
    headerName: "Notes",
    width: 400,
  },
  {
    field: "UnBlock",
    headerName: "Action",
    width: 300,
    renderCell: renderAction,
  },
];
export default columns;
