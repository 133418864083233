import React, { useEffect, useState } from "react";
import FormField from "components/FormField";
import { MenuItem } from "@mui/material";
import useUtils from "hooks/useUtils";

const defaultColors = {
  bg: "lightgray",
  color: "black",
};

const AssignedToJob = ({ onChange, value }) => {
  const { utils } = useUtils();
  const [colors, setColors] = useState({});

  useEffect(() => {
    const newColors = { ...defaultColors };
    if (parseInt(value) !== 0) {
      const userIndex = utils.users.findIndex((u) => u.id === parseInt(value));
      if (userIndex !== -1) {
        const user = utils.users[userIndex];
        newColors.bg = user.bgColor ? user.bgColor : defaultColors.bg;
        newColors.color = user.txtColor ? user.txtColor : defaultColors.color;
      }
    }
    setColors(newColors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const utilsValue = utils?.users?.some((user) => user?.id === value)
    ? value
    : "0";

  return (
    <>
      <FormField
        sx={{
          background: colors.bg,
          "& div": { color: `${colors.color}!important` },
        }}
        value={utilsValue}
        onChange={onChange}
        size="small"
        select
      >
        <MenuItem value="0">Unassigned</MenuItem>
        {utils?.users?.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item?.name}
          </MenuItem>
        ))}
      </FormField>
    </>
  );
};

export default AssignedToJob;
