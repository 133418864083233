import React, { useEffect, useState } from "react";
import { Box, CircularProgress, MenuItem, TextField, Typography } from "@mui/material";
import useUtils from "hooks/useUtils";
import { viewMembershipRequest } from "api/membership";

const Members = ({ contact, property, setState, setMembershipId }) => {
  const { utils, getDefaults } = useUtils();
  const [selectedMemberId, setSelectedMemberId] = useState("");
  const [selectedProperty, setSelectedProperty] = useState("");
  const [loading, setLoading] = useState(false); 
  const handleChange = (event) => {
    setSelectedProperty(event.target.value);
  };

  const handleAddJobClick = (id) => {
    setLoading(true); // Step 2: Set loading to true
    viewMembershipRequest(id)?.then((res) => {
      setState({
        contact: res?.data?.members[0],
        contact_id: res?.data?.members[0]?.id,
        property: res?.data?.properties,
        property_id: res?.data?.properties[0]?.id,
      });
      setMembershipId(id);
      setLoading(false); // Step 2: Set loading to false after data is fetched
    }).catch(() => {
      setLoading(false); // Ensure loading is false even if there's an error
    });
  };

  useEffect(() => {
    getDefaults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box pt={2}>
      <TextField
          required
          label="Member Contact"
          select
          fullWidth
          value={selectedMemberId}
          onChange={(e) => setSelectedMemberId(e.target.value)}
        >
          {loading ? (
            <MenuItem disabled>
              <CircularProgress size={24} />
            </MenuItem>
          ) : utils.members && utils.members.length > 0 ? (
            utils.members.map((item) => (
              <MenuItem
                value={item.id || ""}
                key={item.id}
                onClick={() => handleAddJobClick(item.id)}
              >
                <strong>{item.name}</strong>
                {"(" + item.email + ")"}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="">
              <em>No members available</em>
            </MenuItem>
          )}
        </TextField>
      </Box>
      {contact && (
        <Box pl={1} pb={2}>
          {contact.first_name && (
            <Typography
              gutterBottom
              variant="body2"
            >{`${contact.first_name} ${contact.last_name} / (No. ${contact.id})`}</Typography>
          )}
          {contact.email && (
            <Typography
              gutterBottom
              variant="body2"
            >{`${contact.email}`}</Typography>
          )}
          {contact.cell_phone && (
            <Typography
              gutterBottom
              variant="body2"
            >{`${contact.cell_phone}`}</Typography>
          )}
        </Box>
      )}
      <Box pb={2}>
        <TextField
          required
          label="Property Address"
          select
          fullWidth
          value={selectedProperty}
          onChange={handleChange}
        >
          {Array.isArray(property) && property.length > 0 ? (
            property.map((item) => (
              <MenuItem key={`property-${item.id}`} value={item.id}>
                {item.address}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No properties available</MenuItem>
          )}
        </TextField>
      </Box>
    </>
  );
};

export default Members;
