import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import JobPickerTable from "components/JobPickerTable";
import EmailPreview from "components/EmailPreview";
import TaskStatus from "../TaskStatus";

const Invoice = ({ data, handleChange }) => {
  const navigate = useNavigate();
  const { taskable: mail } = data;
  const [state, _setState] = useState({
    file: null,
    withFeedback: false,
    id: data.id,
    loading: false,
    bindReady: false,
    jobId: null,
    errors: [],
  });

  const setState = (newValues) => _setState({ ...state, ...newValues });

  const handleSubmit = () => {
    navigate(`/app/invoices/create`, { state: { data, jobId: state.jobId } });
  };

  useEffect(() => {
    if (mail.attachments.length > 0) {
      setState({ file: mail.attachments[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mail.attachments.length]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={8} md={9}>
        <EmailPreview
          mail={mail}
          file={state.file}
          onFileSelect={(attachment) => setState({ file: attachment })}
          attachmentTitle="Please select the attachment containing the invoice batch:"
        />
        <Box>
          <JobPickerTable
            value={state.jobId}
            handleChange={(jobId) => setState({ jobId })}
          />
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
      <Grid item sm={12} md={12}>
        <Box textAlign="center" mt={3} mb={2}>
          {state.errors.length > 0 && (
            <Typography mb={2} color="error" variant="body2">
              {state.errors.map((error) => (
                <span key={error}>{error}</span>
              ))}
            </Typography>
          )}
          {!state.loading && (
            <LoadingButton
              onClick={() => handleSubmit()}
              variant="contained"
              loading={state.loading}
            >
              GO TO CREATE INVOICE
            </LoadingButton>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default Invoice;
