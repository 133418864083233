import debounce from "lodash.debounce";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserRequest,
  createUserRequest,
  updateUserRequest,
  searchUsersRequest,
  deleteUserRequest,
  changeMfaRequest,
  createRcAccountRequest,
} from "api/users";
import {
  SEARCH_USERS_RESULTS,
  SET_USERS_LOADING,
  SEARCH_USERS,
  UPDATE_USER_DATA,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
} from "redux/actions/usersActions";

const useUser = () => {
  const dispatch = useDispatch();
  const usersReducer = useSelector((state) => state.users);

  const getUsers = (params) => {
    dispatch({ type: SET_USERS_LOADING, loading: true });
    return searchUsersRequest(params)
      .then((res) => {
        dispatch({ type: SEARCH_USERS_RESULTS, results: res.data.response });
      })
      .finally(() => {
        if (usersReducer.loading) {
          dispatch({ type: SET_USERS_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_USERS, query });
    searchUsersRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_USERS_RESULTS, results: res.data.response });
    });
  }, 600);

  const getUser = (uuid) => getUserRequest(uuid);
  const createUser = (params) => createUserRequest(params);
  const updateUser = async (params, uuid = null, callback) => {
    try {
      const res = await updateUserRequest(params, uuid);
      dispatch({ type: UPDATE_USER_DATA, user: res?.data?.user });
      if (typeof callback === "function") {
        return callback(res);
      }
    } catch (error) {
      if (typeof callback === "function") {
        return callback(error);
      }
    }
  };
  const deleteUser = (params) => deleteUserRequest(params);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const changeMfa = (value, user_id) =>
    changeMfaRequest({ mfa_enabled: value, user_id });
  const createRcAccount = (extension, user_id) =>
    createRcAccountRequest({ extension, user_id });

  return {
    usersReducer,
    search,
    getUsers,
    getUser,
    createUser,
    updateUser,
    deleteUser,
    changePage,
    changePerPage,
    changeMfa,
    createRcAccount,
  };
};

export default useUser;
