import {
  SET_ESTIMATES_VALUES,
  CHANGE_ESTIMATES_VALUES,
  ESTIMATES_RESET,
} from "redux/actions/taskActions";

const initialState = {
  job_id: null,
  contractor_id: null,
  contractors: [],
  loadingContractors: false,
  contractorsLoaded: false,
};

const Auth = (state = { ...initialState }, action = {}) => {
  switch (action.type) {
    case SET_ESTIMATES_VALUES:
      return state;
    case CHANGE_ESTIMATES_VALUES:
      return { ...state, [action.field]: action.value };
    case ESTIMATES_RESET:
    default:
      return { ...initialState };
  }
};
export default Auth;
