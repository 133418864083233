import { Grid } from "@mui/material";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import InputMask from "react-input-mask";
import React from "react";

const OtherMember = ({ form, setForm, loggedIn }) => {
  const handleChange = async (e) => {
    const _otherMember = { ...form.member_contact_phone };
    _otherMember[e.target.name] = e.target.value;
    setForm({ member_contact_phone: _otherMember });
  };

  return (
    <>
      {
        <React.Fragment>
          <Grid item md={12} sm={12} xs={12}>
            <HeadingLine title="Other Request Serves" />
          </Grid>

          <Grid item md={3} sm={12} xs={12}>
            <FormField
              label="First Name"
              name="fname"
              value={form.member_contact_phone.fname || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <FormField
              label="Last Name"
              name="lname"
              value={form.member_contact_phone.lname || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid item md={3} sm={12} xs={12}>
            <InputMask
              value={form.member_contact_phone.phone || ""}
              onChange={handleChange}
              mask="(+1) 999-999-9999"
            >
              {() => <FormField label="Phone Number" name="phone" />}
            </InputMask>
          </Grid>
        </React.Fragment>
      }
    </>
  );
};
export default OtherMember;
