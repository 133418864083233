import {
  Box,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { updateInvoicedata } from "api/invoices";
import FormField from "components/FormField";
import UpdateIndex from "components/QModal/UpdateIndex";
import React, { useEffect, useState } from "react";

const UpdateInvoice = ({ form, handleClose, onSubmitHandler }) => {
  const [values, setValues] = useState(form);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (event) => {
    try {
      const { name, value } = event.target;
      if (name.includes(".")) {
        const [parent, child] = name.split(".");
        setValues((prevValues) => ({
          ...prevValues,
          [parent]: { ...prevValues[parent], [child]: value },
        }));
      } else {
        setValues((prevValues) => ({ ...prevValues, [name]: value }));
        if (name === "amount") {
          const totalSale = values.inv_total_sale;
          const factorPercent = (value / totalSale) * 100;
          const conBill = values.total - value;

          setValues((prevValues) => ({
            ...prevValues,
            factor_percent: factorPercent,
            con_bill: conBill,
            updated_amount: value,
          }));
        }
      }
    } catch (error) {
      console.error("Error in handleChange:", error);
    }
  };

  useEffect(() => {
    updateInvoicedata(form.id, values);
    // eslint-disable-next-line
  }, [values]);

  useEffect(() => {
    const calculateTotals = () => {
      const materialTotal = Object.keys(values?.material || {}).reduce(
        (acc, key) => {
          if (key.startsWith("material_amt_")) {
            return acc + (parseFloat(values?.material[key]) || 0);
          }
          return acc;
        },
        0
      );

      const laborTotal = Object.keys(values?.labor || {}).reduce((acc, key) => {
        if (key.startsWith("labor_amt_")) {
          return acc + (parseFloat(values?.labor[key]) || 0);
        }
        return acc;
      }, 0);

      const netSale = materialTotal + laborTotal;
      const taxAmount = netSale * (values.tax / 100);
      const totalSale = netSale + taxAmount;
      const amount = totalSale * (values.factor_percent / 100);
      const conBill = totalSale - amount;
      const factorPercent = (amount / totalSale) * 100;

      setValues((prevValues) => ({
        ...prevValues,
        inv_total_material: materialTotal,
        inv_total_labor: laborTotal,
        inv_net_sale: netSale,
        inv_tax_amount: taxAmount,
        inv_total_sale: totalSale,
        total: totalSale,
        amount: amount,
        con_bill: conBill,
        factor_percent: factorPercent,
      }));
    };

    calculateTotals();
  }, [
    values.material,
    values.job_number,
    values.tax,
    values.labor,
    values.factor_percent,
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await onSubmitHandler(values);
  };
  return (
    <>
      <UpdateIndex
        dialogProps={{
          open: true,
          title: `Update Invoices`.toUpperCase(),
          subtitle: "",
        }}
        handleClose={handleClose}
      >
        <form onSubmit={handleSubmit}>
          <Box>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="ID"
                  name="id"
                  disabled={values?.id !== undefined}
                  value={values?.id || ""}
                />
                <FormField
                  label="Invoice Number"
                  name="hoc_inv_num"
                  disabled={values?.id !== undefined}
                  value={values?.hoc_inv_num || ""}
                />
                <FormField
                  label="Contractor Code"
                  name="con"
                  disabled={values?.id !== undefined}
                  value={values?.con || ""}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Job Number"
                  name="job_number"
                  type="number"
                  onChange={handleChange}
                  value={values?.job_number || ""}
                />
                <FormField
                  label="Member Name"
                  name="mem_name"
                  disabled={values?.id !== undefined}
                  value={values?.mem_name || ""}
                />
                <FormField
                  label="Invoice Date"
                  name="created_at"
                  disabled={values?.id !== undefined}
                  value={
                    values?.created_at
                      ? new Date(values?.created_at).toLocaleDateString()
                      : ""
                  }
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Member Address"
                  name="mem_street"
                  onChange={handleChange}
                  value={values?.mem_street || ""}
                />
                <FormField
                  label="Remarks"
                  name="remarks"
                  onChange={handleChange}
                  value={values?.remarks || ""}
                />
                <FormField
                  label="Contractor Business"
                  name="con_biz"
                  disabled={values?.id !== undefined}
                  value={values?.con_biz || ""}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Total Invoice Labor"
                  name="inv_total_labor"
                  disabled={values?.id !== undefined}
                  value={values?.inv_total_labor || ""}
                />
                <FormField
                  label="Total Invoice Material"
                  name="inv_total_material"
                  disabled={values?.id !== undefined}
                  value={values?.inv_total_material || ""}
                />
                <FormField
                  label="Invoice Net Sale"
                  name="inv_net_sale"
                  disabled={values?.id !== undefined}
                  value={values?.inv_net_sale || ""}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Tax %"
                  name="tax"
                  disabled={values?.id !== undefined}
                  type="number"
                  onChange={handleChange}
                  value={values?.tax || ""}
                />
                <FormField
                  label="Total Amount"
                  name="total"
                  type="number"
                  disabled={values?.id !== undefined}
                  onChange={handleChange}
                  value={values?.total?.toFixed(2) || ""}
                />
              </div>
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Factoring Amount/Rate %"
                  name="factor_percent"
                  type="number"
                  disabled={values?.status === "APPROVED"}
                  onChange={handleChange}
                  value={Number(values?.factor_percent)?.toFixed(2) || ""}
                />
                <FormField
                  label="Amount"
                  name="amount"
                  disabled={values?.id !== undefined}
                  type="number"
                  onChange={handleChange}
                  value={Number(values?.amount)?.toFixed(2) || ""}
                />
                <FormField
                  label="Con. Bill"
                  name="con_bill"
                  type="number"
                  disabled={values?.id !== undefined}
                  onChange={handleChange}
                  value={values?.con_bill?.toFixed(2) || ""}
                />
              </div>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* Header Section */}
              <Grid item xs={12}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    textAlign: "left",
                    borderBottom: "2px solid black",
                    paddingBottom: "8px",
                    mb: 2,
                  }}
                >
                  {values?.status === "APPROVED" ? "Refunds" : "Adjustments"}
                </Typography>
              </Grid>

              {/* Refund / Adjustment Buttons */}
              <Grid item xs={12}>
                {values?.status === "APPROVED" ? (
                  <Box
                    sx={{ display: "flex", justifyContent: "center", gap: 2 }}
                  >
                    {/* Issue Refund Button */}
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => console.log("HANDLE REFUND AMOUNT")}
                    >
                      Issue Full Refund
                    </Button>

                    {/* Partial Refund Button */}
                    <Button
                      variant="contained"
                      onClick={() => setIsExpanded(!isExpanded)}
                    >
                      Partial Refund
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    sx={{ display: "block", margin: "0 auto", mb: 2 }}
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    Add Adjustment
                  </Button>
                )}
              </Grid>

              {isExpanded && (
                <>
                  {/* Contractor Dollar Amount */}
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label={
                        values?.status === "APPROVED"
                          ? "Contractor Refund Amount"
                          : "Contractor Adjustment Amount"
                      }
                      name="con_adj"
                      type="number"
                      onChange={(e) => {
                        const contractorAmount =
                          parseFloat(e.target.value) || 0;
                        const hocAmount = parseFloat(values?.hoc_adj || 0);
                        setValues({
                          ...values,
                          con_adj: contractorAmount,
                          total_adj_amount: contractorAmount + hocAmount,
                        });
                      }}
                      value={values?.con_adj || ""}
                    />
                  </Grid>

                  {/* HOC Dollar Amount */}
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label={
                        values?.status === "APPROVED"
                          ? "HOC Refund Amount"
                          : "HOC Adjustment Amount"
                      }
                      name="hoc_adj"
                      type="number"
                      onChange={(e) => {
                        const hocAmount = parseFloat(e.target.value) || 0;
                        const contractorAmount = parseFloat(
                          values?.con_adj || 0
                        );
                        setValues({
                          ...values,
                          hoc_adj: hocAmount,
                          total_adj_amount: contractorAmount + hocAmount,
                        });
                      }}
                      value={values?.hoc_adj || ""}
                    />
                  </Grid>

                  {/* Total Adjustment/Refund Amount */}
                  <Grid item xs={12}>
                    <FormField
                      label={
                        values?.status === "APPROVED"
                          ? "Total Refund Amount"
                          : "Total Adjustment Amount"
                      }
                      name="total_adj_amount"
                      type="number"
                      disabled
                      value={values?.total_adj_amount || ""}
                    />
                  </Grid>

                  {/* Adjustment/Refund Description */}
                  <Grid item xs={12}>
                    <FormField
                      label={
                        values?.status === "APPROVED"
                          ? "Refund Description"
                          : "Adjustment Description"
                      }
                      name="adj_description"
                      type="text"
                      onChange={(e) =>
                        setValues({
                          ...values,
                          adj_description: e.target.value,
                        })
                      }
                      value={values?.adj_description || ""}
                    />
                  </Grid>

                  {/* New Invoice Total */}
                  <Grid item xs={12}>
                    <FormField
                      label="New Invoice Total"
                      name="new_invoice_total"
                      type="number"
                      disabled
                      helperText="The new invoice total after applying the total adjustment or refund amount."
                      value={
                        values?.total_adj_amount
                          ? (
                              parseFloat(values?.total?.toFixed(2) || 0) -
                              parseFloat(values?.total_adj_amount || 0)
                            ).toFixed(2)
                          : values?.new_invoice_total || ""
                      }
                    />
                  </Grid>

                  {/* Factoring Checkbox and Percentage */}
                  <Grid item xs={12} sm={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values?.adjust_factoring || false}
                          onChange={(e) =>
                            setValues({
                              ...values,
                              adjust_factoring: e.target.checked,
                              factoring_percent: e.target.checked
                                ? values?.factoring_percent || 0
                                : "",
                            })
                          }
                        />
                      }
                      label="Adjust Factoring"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="Factoring Percentage"
                      name="factoring_percent"
                      type="number"
                      disabled={!values?.adjust_factoring}
                      onChange={(e) => {
                        const factoringPercent =
                          parseFloat(e.target.value) || 0;
                        setValues({
                          ...values,
                          factoring_percent: factoringPercent,
                        });
                      }}
                      value={values?.factoring_percent || ""}
                    />
                  </Grid>

                  {/* Apply and Cancel Buttons */}
                  <Grid item xs={12}>
                    <Box sx={{ textAlign: "end", mt: 2 }}>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button
                        type="submit"
                        onClick={() => {
                          const operationType =
                            values?.status === "APPROVED"
                              ? "partial_refund"
                              : "adjustment";
                          setValues({
                            ...values,
                            operation_type: operationType,
                          });
                        }}
                      >
                        {values?.status === "APPROVED"
                          ? "Apply Refund"
                          : "Apply Adjustment"}
                      </Button>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
            ;
            <Grid sx={{ mt: 5 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  borderBottom: "1px solid black",
                  paddingBottom: "5px",
                }}
              >
                {"Labors".toUpperCase()}
              </Typography>
              {values?.labor?.labor_1 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 1"
                    name="labor.labor_1"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_1 || ""}
                  />
                  <FormField
                    label="Labor Amount 1"
                    name="labor.labor_amt_1"
                    disabled={values?.id !== undefined}
                    type="number"
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_1 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_2 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 2"
                    name="labor.labor_2"
                    disabled={values?.id !== undefined}
                    type="number"
                    onChange={handleChange}
                    value={values?.labor?.labor_2 || ""}
                  />
                  <FormField
                    label="Labor Amount 2"
                    name="labor.labor_amt_2"
                    disabled={values?.id !== undefined}
                    type="number"
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_2 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_3 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 3"
                    name="labor.labor_3"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_3 || ""}
                  />
                  <FormField
                    label="Labor Amount 3"
                    name="labor.labor_amt_3"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_3 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_4 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 4"
                    name="labor.labor_4"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_4 || ""}
                  />
                  <FormField
                    label="Labor 4 Amount"
                    name="labor.labor_amt_4"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_4 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_5 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 5"
                    name="labor.labor_5"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_5 || ""}
                  />
                  <FormField
                    label="Labor 5 Amount"
                    name="labor.labor_amt_5"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_5 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_6 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 6"
                    name="labor.labor_6"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_6 || ""}
                  />
                  <FormField
                    label="Labor 6 Amount"
                    name="labor.labor_amt_6"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_6 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_7 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 7"
                    name="labor.labor_7"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_7 || ""}
                  />
                  <FormField
                    label="Labor 7 Amount"
                    name="labor.labor_amt_7"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_7 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_8 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 8"
                    name="labor.labor_8"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_8 || ""}
                  />
                  <FormField
                    label="Labor 8 Amount"
                    name="labor.labor_amt_8"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_8 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_9 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 1"
                    name="labor.labor_1"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_9 || ""}
                  />
                  <FormField
                    label="Labor 9 Amount"
                    name="labor.labor_amt_9"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_9 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_10 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 10"
                    name="labor.labor_10"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_10 || ""}
                  />
                  <FormField
                    label="Labor 10 Amount"
                    name="labor.labor_amt_10"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_10 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_11 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 11"
                    name="labor.labor_11"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_11 || ""}
                  />
                  <FormField
                    label="Labor 11 Amount"
                    name="labor.labor_amt_11"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_11 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_12 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 12"
                    name="labor.labor_12"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_12 || ""}
                  />
                  <FormField
                    label="Labor 12 Amount"
                    name="labor.labor_amt_12"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_12 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_13 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 13"
                    name="labor.labor_13"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_13 || ""}
                  />
                  <FormField
                    label="Labor 13 Amount"
                    name="labor.labor_amt_13"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_13 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_14 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 14"
                    name="labor.labor_14"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_14 || ""}
                  />
                  <FormField
                    label="Labor 14 Amount"
                    name="labor.labor_amt_14"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_14 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_15 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 15"
                    name="labor.labor_15"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_15 || ""}
                  />
                  <FormField
                    label="Labor 15 Amount"
                    name="labor.labor_amt_15"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_15 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_16 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 16"
                    name="labor.labor_16"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_16 || ""}
                  />
                  <FormField
                    label="Labor 16 Amount"
                    name="labor.labor_amt_16"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_16 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_17 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 17"
                    name="labor.labor_17"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_17 || ""}
                  />
                  <FormField
                    label="Labor 17 Amount"
                    name="labor.labor_amt_17"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_17 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_18 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 18"
                    name="labor.labor_18"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_18 || ""}
                  />
                  <FormField
                    label="Labor 18 Amount"
                    name="labor.labor_amt_18"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_18 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_19 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 19"
                    name="labor.labor_19"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_19 || ""}
                  />
                  <FormField
                    label="Labor 19 Amount"
                    name="labor.labor_amt_19"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_19 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_20 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 20"
                    name="labor.labor_20"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_20 || ""}
                  />
                  <FormField
                    label="Labor 1 Amount"
                    name="labor.labor_amt_20"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_20 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_21 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 21"
                    name="labor.labor_21"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_21 || ""}
                  />
                  <FormField
                    label="Labor 21 Amount"
                    name="labor.labor_amt_21"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_21 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_22 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 22"
                    name="labor.labor_22"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_22 || ""}
                  />
                  <FormField
                    label="Labor 22 Amount"
                    name="labor.labor_amt_22"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor.labor_amt_22 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_23 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 23"
                    name="labor.labor_23"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_23 || ""}
                  />
                  <FormField
                    label="Labor 23 Amount"
                    name="labor.labor_amt_23"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_23 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_24 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 24"
                    name="labor.labor_24"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_24 || ""}
                  />
                  <FormField
                    label="Labor 24 Amount"
                    name="labor.labor_amt_24"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_24 || ""}
                  />
                </div>
              )}
              {values?.labor?.labor_25 !== undefined && (
                <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                  <FormField
                    label="Labor 25"
                    name="labor.labor_25"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_25 || ""}
                  />
                  <FormField
                    label="Labor 25 Amount"
                    name="labor.labor_amt_25"
                    disabled={values?.id !== undefined}
                    onChange={handleChange}
                    value={values?.labor?.labor_amt_25 || ""}
                  />
                </div>
              )}
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <div style={{ display: "flex", gap: 16, marginTop: "20px" }}>
                <FormField
                  label="Comments"
                  name="comment"
                  type="text"
                  onChange={handleChange}
                  value={values?.comment || ""}
                />
              </div>
            </Grid>
          </Box>
          <Box sx={{ textAlign: "end", mt: 1.5 }}>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Apply</Button>
          </Box>
        </form>
      </UpdateIndex>
    </>
  );
};

UpdateInvoice.defaultProps = {
  withHeadingLine: true,
  form: {
    id: "",
    hoc_inv_num: "",
    con: "",
    job_number: "",
    mem_name: "",
    created_at: "",
    mem_street: "",
    remarks: "",
    amount: "",
    updated_amount: "",
    inv_total_labor: "",
    inv_total_material: "",
    inv_net_sale: "",
    rate: "",
    tax: "",
    comment: "",
    factor_percent: "",
    hoc_adj: "",
    con_adj: "",
    HOC_contractor_split: "",
    new_invoice_total: "",
    total_adj_amount: "",
    material_1: "",
    material_amt_1: "",
    material_2: "",
    material_amt_2: "",
    material_3: "",
    material_amt_3: "",
    material_4: "",
    material_amt_4: "",
    material_5: "",
    material_amt_5: "",
    material_6: "",
    material_amt_6: "",
    material_7: "",
    material_amt_7: "",
    material_8: "",
    material_amt_8: "",
    material_9: "",
    material_amt_9: "",
    material_10: "",
    material_amt_10: "",
    material_11: "",
    material_amt_11: "",
    material_12: "",
    material_amt_12: "",
    material_13: "",
    material_amt_13: "",
    material_14: "",
    material_amt_14: "",
    material_15: "",
    material_amt_15: "",
    material_16: "",
    material_amt_16: "",
    material_17: "",
    material_amt_17: "",
    material_18: "",
    material_amt_18: "",
    material_19: "",
    material_amt_19: "",
    material_20: "",
    material_amt_20: "",
    material_21: "",
    material_amt_21: "",
    material_22: "",
    material_amt_22: "",
    material_23: "",
    material_amt_23: "",
    material_24: "",
    material_amt_24: "",
    material_25: "",
    material_amt_25: "",
    labor_1: "",
    labor_amt_1: "",
    labor_2: "",
    labor_amt_2: "",
    labor_3: "",
    labor_amt_3: "",
    labor_4: "",
    labor_amt_4: "",
    labor_5: "",
    labor_amt_5: "",
    labor_6: "",
    labor_amt_6: "",
    labor_7: "",
    labor_amt_7: "",
    labor_8: "",
    labor_amt_8: "",
    labor_9: "",
    labor_amt_9: "",
    labor_10: "",
    labor_amt_10: "",
    labor_11: "",
    labor_amt_11: "",
    labor_12: "",
    labor_amt_12: "",
    labor_13: "",
    labor_amt_13: "",
    labor_14: "",
    labor_amt_14: "",
    labor_15: "",
    labor_amt_15: "",
    labor_16: "",
    labor_amt_16: "",
    labor_17: "",
    labor_amt_17: "",
    labor_18: "",
    labor_amt_18: "",
    labor_19: "",
    labor_amt_19: "",
    labor_20: "",
    labor_amt_20: "",
    labor_21: "",
    labor_amt_21: "",
    labor_22: "",
    labor_amt_22: "",
    labor_23: "",
    labor_amt_23: "",
    labor_24: "",
    labor_amt_24: "",
    labor_25: "",
    labor_amt_25: "",
  },
};

export default UpdateInvoice;
