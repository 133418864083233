import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import useContractors from "hooks/useContractors";
import useAccessControl from "hooks/useAccessControl";
import useSystem from "WebR/hooks/useSystem";
import { parsePhoneNo } from "utils/functions";
import Swal from "sweetalert2";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./ContractorColumnData";
import useStyles from "components/TaskPreview/styles-jss";
import {
  getFavoriteContractor,
  getRemoveFavoriteContractor,
} from "api/contractors";
import "../../../../../../src/components/Callincoming/index.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CreateJobModal from "components/CreateJobModal";
import useJobs from "hooks/useJobs";
import { useDispatch } from "react-redux";
import { CHANGE_PER_PAGE } from "redux/actions/contractorsActions";

const ContractorData = ({ status }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const { canUpdateContractor } = useAccessControl();
  const [favoriteContractors, setFavoriteContractors] = useState(new Set());
  const { jobsReducer, getJobsData } = useJobs();
  const {
    contractorsReducer: {
      query, // Regular contractors meta
      activeMeta, // Active contractors meta
      inactiveMeta, // Inactive contractors meta
      activeResults, // Active contractors results
      inactiveResults, // Inactive contractors results
      favoriteResults,
      favoriteMeta,
      unavailableResults,
      unavailableMeta,
    },
    getContractors,
    getActiveContractors,
    getInactiveContractors,
    getFavoriteContractorsData,
    getUnavailableContractorsData,
    changePage,
    // changePerPage,
  } = useContractors();
  const [open, setOpen] = useState(false);
  const [selectedContractorId, setSelectedContractorId] = useState(null);

  const handleAssignClick = (contractorId) => {
    setSelectedContractorId(contractorId);
    setOpen(true);
  };

  useEffect(() => {
    const savedFavorites =
      JSON.parse(localStorage.getItem("favoriteContractors")) || [];
    setFavoriteContractors(new Set(savedFavorites));
    getActiveContractors();
    getInactiveContractors();
    getFavoriteContractorsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const selectedMeta =
    status === "active"
      ? activeMeta
      : status === "inactive"
      ? inactiveMeta
      : status === "unavailable"
      ? unavailableMeta
      : favoriteMeta;
  const statusResults =
    status === "active"
      ? activeResults
      : status === "inactive"
      ? inactiveResults
      : status === "unavailable"
      ? unavailableResults
      : favoriteResults;

  const transformedResults = statusResults?.map((contractor) => {
    return {
      id: contractor?.id,
      alpha_ref: contractor?.alpha_ref,
      name: contractor?.name,
      phone: contractor?.phone,
      email: contractor?.email,
      status: contractor?.status,
    };
  });

  const handleChangePage = (newPage) => {
    changePage(newPage);
    if (status === "active") {
      getActiveContractors({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    } else if (status === "inactive") {
      getInactiveContractors({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    } else if (status === "unavailable") {
      getUnavailableContractorsData({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    } else if (status === "favorite") {
      getFavoriteContractorsData({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    } else {
      getContractors({
        search: query,
        page: newPage + 1,
        per_page: selectedMeta?.per_page,
      });
    }
  };

  const handleChangeRowsPerPage = (newPageSize) => {
      dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(newPageSize) });
      if (status === "active") {
        getActiveContractors({
          search: query,
          page: selectedMeta?.current_page,
          per_page: newPageSize,
        });
      } else if (status === "inactive") {
        getInactiveContractors({
          search: query,
          page: selectedMeta?.current_page,
          per_page: newPageSize,
        });
      } else if (status === "unavailable") {
        getUnavailableContractorsData({
          search: query,
          page: selectedMeta?.current_page,
          per_page: newPageSize,
        });
      } else if (status === "favorite") {
        getFavoriteContractorsData({
          search: query,
          page: selectedMeta?.current_page,
          per_page: newPageSize,
        });
      } else {
        getContractors({
          search: query,
          page: selectedMeta?.current_page,
          per_page: newPageSize,
        });
      }
    };

  // const handleChangeRowsPerPage = (event) => {
  //   changePerPage(event.target.value);
  //   if (status === "active") {
  //     getActiveContractors({
  //       search: query,
  //       page: selectedMeta?.current_page,
  //       per_page: parseInt(event.target.value),
  //     });
  //   } else if (status === "inactive") {
  //     getInactiveContractors({
  //       search: query,
  //       page: selectedMeta?.current_page,
  //       per_page: parseInt(event.target.value),
  //     });
  //   } else if (status === "unavailable") {
  //     getUnavailableContractorsData({
  //       search: query,
  //       page: selectedMeta?.current_page,
  //       per_page: parseInt(event.target.value),
  //     });
  //   } else if (status === "favorite") {
  //     getFavoriteContractorsData({
  //       search: query,
  //       page: selectedMeta?.current_page,
  //       per_page: parseInt(event.target.value),
  //     });
  //   } else {
  //     getContractors({
  //       search: query,
  //       page: selectedMeta?.current_page,
  //       per_page: parseInt(event.target.value),
  //     });
  //   }
  // };

  const handleChangeFavorites = async (id) => {
    const updatedFavorites = new Set(favoriteContractors);
    try {
      let res;
      if (updatedFavorites.has(id)) {
        updatedFavorites.delete(id); // Remove from favorites
        res = await getRemoveFavoriteContractor(id);
      } else {
        updatedFavorites.add(id); // Add to favorites
        res = await getFavoriteContractor(id);
      }
      setFavoriteContractors(updatedFavorites);
      localStorage.setItem(
        "favoriteContractors",
        JSON.stringify(Array.from(updatedFavorites))
      );
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: res.data.message,
        showConfirmButton: false,
        icon: "success",
        timerProgressBar: true,
      });
    } catch (error) {
      Swal.fire({
        toast: true,
        timer: 4000,
        position: "top-right",
        title: "Error updating favorite contractor.",
        showConfirmButton: false,
        icon: "error",
        timerProgressBar: true,
      });
    }
  };

  const handleClick = (data) => {
    const mailtoUrl = `mailto:${data.row.email}`;
    data.row.email
      ? window.open(mailtoUrl, "_blank")
      : Swal.fire({
          toast: true,
          icon: "error",
          position: "top-right",
          text: "Email Not Exist in This Contractor",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
  };

  const { call } = useSystem();
  const callContact = (number) => {
    if (localStorage.getItem("status") === "offline") {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-right",
        text: "You Are Not Online!",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    } else if (localStorage.getItem("status") === "online") {
      let phone = parsePhoneNo(number);
      call("+" + phone);
    }
  };

  const renderAction = (data) => (
    <div style={{ display: "flex", gap: "8px", width: "100%" }}>
      {canUpdateContractor() && (
        <Button
          variant="contained"
          color="primary"
          sx={{ mr: 2.0 }}
          size="small"
          onClick={() => navigate(`/app/contractors/${data?.id}`)}
        >
          View
        </Button>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2.0 }}
        size="small"
        onClick={() => callContact(null, data?.row?.phone)}
      >
        Call
      </Button>
      <Button
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => handleClick(data)}
      >
        Email
      </Button>
      <Button
        size="small"
        color="primary"
        onClick={() => handleChangeFavorites(data.row.id)}
      >
        {favoriteContractors.has(data.row.id) ? (
          <FavoriteIcon sx={{ color: "red" }} />
        ) : (
          <FavoriteBorderIcon />
        )}
      </Button>
      <Button
        size="small"
        color="primary"
        style={{ backgroundColor: "#116089", color: "#fff" }}
        onClick={() => handleAssignClick(data?.row)}
      >
        Assign
      </Button>
    </div>
  );

  const fetchJons = () => {
    getJobsData(jobsReducer.query);
  };

  return (
    <>
      <DataGrid
        columns={columns(renderAction)}
        rows={transformedResults}
        pageSize={Number(selectedMeta?.per_page)}
        onPageChange={handleChangePage}
        onPageSizeChange={handleChangeRowsPerPage}
        paginationMode="server"
        rowCount={selectedMeta?.total}
        page={selectedMeta?.current_page - 1}
        disableSelectionOnClick
        autoHeight
        className={classes.dataGrid}
        rowsPerPageOptions={[2, 10, 20, 50, 100]}
      />
      {open && (
        <CreateJobModal
          open={open}
          handleClose={() => setOpen(false)}
          fetchJons={fetchJons}
          contractorId={selectedContractorId}
        />
      )}
    </>
  );
};
export default ContractorData;
