import axios from "utils/axios";
import {
  GET_CALL_LOG,
  GET_ALLCALL_LOG,
  Create_Ext,
  Delete_Ext,
  SEND_MSG_REQ,
  presence,
  Bringpresence,
  GET_VOICEMAIL,
} from "./routes";

const calllogRequest = (data) => axios.post(GET_CALL_LOG, data);
const VoicemailRequest = (data) => axios.get(GET_VOICEMAIL);
const sendMsgRequest = (data) => {
  return axios.post(SEND_MSG_REQ, data);
};
const AllcalllogRequest = (search) =>
  axios.post(GET_ALLCALL_LOG, {}, { params: search });
const CreateRcExt = (data) => axios.post(Create_Ext, data);
const DeleteRcExt = (data) => axios.post(Delete_Ext, data);
const presenceExt = (data) => axios.get(presence);
const bringExt = (data) => axios.post(Bringpresence, data);

export {
  calllogRequest,
  AllcalllogRequest,
  CreateRcExt,
  DeleteRcExt,
  sendMsgRequest,
  presenceExt,
  bringExt,
  VoicemailRequest,
};