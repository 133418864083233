import { useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Typography,
  TableRow,
  TableCell,
  Tooltip,
  IconButton,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const FileItem = ({
  index,
  file,
  deleteFile,
  selectFile,
  previewFile,
  isSelected,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e) => {
    if (e.detail === 2) {
      previewFile(index);
    }
  };

  const downloadfileData = file?.path;

  const downloadFileImage = (downloadfileData) => {
    fetch(file?.path)
      .then((response) => response.blob())
      .then((blob) => {
        const fileName = downloadfileData;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  return (
    <TableRow
      key={file.uuid}
      sx={{
        "&:last-child td, &:last-child th": { border: 0 },
        cursor: "pointer",
        "& td": { opacity: !file?.loading ? 1 : 0.5 },
      }}
      hover
      selected={isSelected}
    >
      <TableCell padding="checkbox">
        {!file?.loading && (
          <Checkbox
            color="primary"
            checked={isSelected}
            onChange={() => selectFile(file?.uuid)}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        )}
      </TableCell>
      <TableCell align="left" onClick={(event) => handleClick(event)}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InsertDriveFileIcon />
          <Typography sx={{ ml: 2 }}>{file?.file_name}</Typography>
        </Box>
      </TableCell>
      <TableCell align="right" onClick={(event) => handleClick(event)}>
        {dayjs(file?.created_at).format("MM/DD/YYYY hh:mma")}
      </TableCell>
      <TableCell align="right">
        <Tooltip title="Delete">
          <IconButton
            aria-label="delete"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disabled={file?.loading}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Preview">
          <IconButton
            aria-label="preview"
            disabled={file?.loading}
            onClick={() => previewFile(index)}
          >
            <VisibilityIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              downloadFileImage(downloadfileData);
              handleClose();
            }}
          >
            Download
          </MenuItem>
          <MenuItem
            onClick={() => {
              deleteFile(file.uuid);
              handleClose();
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default FileItem;
