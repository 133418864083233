import axios from "utils/axios";

const GET_PENDING_BATCH = `${process.env.REACT_APP_API_DOMAIN}/api/v1/batch/pending`;
const GET_BATCH_DATA = `${process.env.REACT_APP_API_DOMAIN}/api/v1/batch`;
const GET_INVOICES_BATCHES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/batch`;

const getPendingBatchRequest = () => axios.get(GET_PENDING_BATCH);

const processBatchRequest = (batch_id) =>
  axios.get(`${GET_BATCH_DATA}/${batch_id}/process`);

const approveBatchRequest = (batch_id, params) =>
  axios.post(`${GET_BATCH_DATA}/${batch_id}/approve`, params);

const rejectBatchRequest = (batch_id) =>
  axios.post(`${GET_BATCH_DATA}/${batch_id}/reject`);

const getInvoicesBatchesRequest = (params) =>
  axios.get(GET_INVOICES_BATCHES_ROUTE, {
    params,
  });
export {
  getInvoicesBatchesRequest,
  getPendingBatchRequest,
  processBatchRequest,
  approveBatchRequest,
  rejectBatchRequest,
};
