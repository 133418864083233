import { Box, Button, CircularProgress } from "@mui/material";
import { csvUploadContractor } from "api/contractors";
import ContractorBlocks from "components/ContractorBlocks";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

const InportContractor = () => {
  const navigate = useNavigate();
  const [formLoading, setFormLoading] = useState(false);
  const [csvFile, setCsvFile] = useState(null);

  const handleChange = (e) => {
    const file = e.target.files[0];
    setCsvFile(file);
  };

  const handleSaveClick = async () => {
    try {
      const formData = new FormData();
      formData.append("file", csvFile);
      const response = await csvUploadContractor(formData);
      if (response.data) {
        setFormLoading(true);
        Swal.fire({
          toast: true,
          timer: 3000,
          position: "top-right",
          title: response?.data?.message,
          showConfirmButton: false,
          icon: "success",
        }).then((result) => {
          navigate("/app/contractors");
        });
      }
    } catch (error) {
      setFormLoading(false);
      Swal.fire({
        toast: true,
        timer: 3000,
        position: "top-right",
        title: error?.response?.data?.message,
        showConfirmButton: false,
        icon: "error",
      });
    }
  };
  return (
    <>
      <ContractorBlocks title="Import Contractor Inv">
        <form
          className="uploadFile"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            border: "2px dashed #1475cf",
            height: "250px",
            width: "400px",
            cursor: "pointer",
            borderRadius: "5px",
          }}
        >
          {csvFile ? (
            <img src={csvFile} alt={csvFile ? "CSV file" : ""} />
          ) : (
            <CloudUploadIcon sx={{ fontSize: 50, color: "#116089" }} />
          )}
          <input type="file" className="input_filed" onChange={handleChange} />
          <p>Drag & drop files here, or click to select files</p>
        </form>
        {csvFile && (
          <p style={{ fontWeight: "bold", marginTop: "10px" }}>
            <InsertDriveFileIcon /> {csvFile.name}
          </p>
        )}
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            sx={{
              background: "#116089",
              color: "white",
              "&:hover": { backgroundColor: "#116089" },
            }}
            onClick={handleSaveClick}
          >
            Save Contractor Inv
            {formLoading && (
              <Box pl={2}>
                <CircularProgress size={16} color="secondary" />
              </Box>
            )}
          </Button>
        </Box>
      </ContractorBlocks>
    </>
  );
};
export default InportContractor;
