import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import FormField from "components/FormField";
import CallableField from "components/CallableField";
import { useFormik } from "formik";
import InputAdornment from "@mui/material/InputAdornment";

const MemberUpdate = ({ member, onChange, validationErrors, index }) => {
  /**
   * Initializes the formik instance for managing the member details form.
   *
   * This formik instance handles the state and validation of the member details
   * form, including fields such as ID, first name, last name, email, and phone
   * numbers. It sets the initial values based on the provided member object.
   *
   * @type {FormikProps} - The formik instance containing form state and methods.
   */
  const formik = useFormik({
    initialValues: {
      id: member.id || undefined,
      first_name: member.first_name,
      last_name: member.last_name,
      email: member.email,
      home_phone: member.home_phone,
      cell_phone: member.cell_phone,
      work_phone: member.work_phone,
      work_phone_extension: member.work_phone_extension,
      occupation: member.occupation,
      company: member.company,
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      id: member.id || undefined,
      first_name: member.first_name,
      last_name: member.last_name,
      email: member.email,
      home_phone: member.home_phone,
      cell_phone: member.cell_phone,
      work_phone: member.work_phone,
      work_phone_extension: member.work_phone_extension,
      occupation: member.occupation,
      company: member.company,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  /**
   * Handles changes to form fields and validates the input values.
   *
   * This function updates the formik values based on the input field that
   * triggered the change event. It performs validation for the email field
   * and other fields, updating the validation errors accordingly.
   *
   * @param {Event} e - The event object representing the change event from the input field.
   * @returns {void} - This function does not return a value.
   */
  const handleOnChange = async (e) => {
    const newValues = { ...formik.values, [e.target.name]: e.target.value };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (e.target.name === "email") {
      if (!e.target.value) {
        validationErrors[`members[${index}].${e.target.name}`] =
          "This field is required.";
      } else if (!emailRegex.test(e.target.value)) {
        validationErrors[`members[${index}].${e.target.name}`] =
          "Please enter a valid email.";
      } else {
        delete validationErrors[`members[${index}].${e.target.name}`];
      }
    } else {
      if (e.target.value) {
        delete validationErrors[`members[${index}].${e.target.name}`];
      } else {
        validationErrors[`members[${index}].${e.target.name}`] =
          "This field is required.";
      }
    }
    onChange(newValues);
  };

  /**
   * Handles the click event to open the default email client with a pre-filled email address.
   *
   * This function constructs a mailto URL using the email address of the specified member
   * and opens it in a new browser tab, allowing the user to send an email to that member.
   *
   * @param {Object} member - The member object containing the email address.
   * @param {string} member.email - The email address of the member to send an email to.
   * @returns {void} - This function does not return a value.
   */
  const handleClick = (member) => {
    const mailtoUrl = `mailto:${member?.email}`;
    window.open(mailtoUrl, "_blank");
  };

  return (
    <>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="First Name"
          value={formik.values.first_name || ""}
          onChange={handleOnChange}
          name="first_name"
          required
          error={
            validationErrors && validationErrors[`members[${index}].first_name`]
          }
          helperText={
            validationErrors &&
            validationErrors[`members[${index}].first_name`]?.replace(
              `members[${index}].first_name`,
              "First name"
            )
          }
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Last Name"
          value={formik.values.last_name || ""}
          onChange={handleOnChange}
          name="last_name"
          required
          error={
            validationErrors && validationErrors[`members[${index}].last_name`]
          }
          helperText={
            validationErrors &&
            validationErrors[`members[${index}].last_name`]?.replace(
              `members[${index}].last_name`,
              "First name"
            )
          }
        />
      </Grid>
      <Grid item md={6} sm={12} xs={12}>
        <FormField
          label="Email"
          value={formik.values.email || ""}
          onChange={handleOnChange}
          name="email"
          type="email"
          required
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <React.Fragment>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleClick(member)}
                  >
                    Email
                  </Button>
                </React.Fragment>
              </InputAdornment>
            ),
          }}
          error={
            validationErrors && validationErrors[`members[${index}].email`]
          }
          helperText={
            validationErrors &&
            validationErrors[`members[${index}].email`]?.replace(
              `members[${index}].email`,
              "First name"
            )
          }
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <CallableField
          value={formik.values.cell_phone || ""}
          onChange={handleOnChange}
          label="Primary Phone"
          name="cell_phone"
          required
          error={
            validationErrors && validationErrors[`members[${index}].cell_phone`]
          }
          helperText={
            validationErrors &&
            validationErrors[`members[${index}].cell_phone`]?.replace(
              `members[${index}].cell_phone`,
              "First name"
            )
          }
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <CallableField
          value={formik.values.work_phone || ""}
          label="Secondary Phone"
          name="work_phone"
          onChange={handleOnChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Extension"
          name="work_phone_extension"
          onChange={handleOnChange}
          value={formik.values.work_phone_extension || ""}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Occupation"
          name="occupation"
          value={formik.values.occupation || ""}
          onChange={handleOnChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <FormField
          label="Company"
          name="company"
          value={formik.values.company || ""}
          onChange={handleOnChange}
        />
      </Grid>
    </>
  );
};

export default MemberUpdate;
