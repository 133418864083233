import React from "react";
import { useNavigate } from "react-router-dom";
import { TableRow, TableCell, Button, Box } from "@mui/material";
import dayjs from "dayjs";

const InvoiceBatchRow = ({ data }) => {
  const navigate = useNavigate();

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {data.id}
      </TableCell>
      <TableCell component="th" scope="row">
        Batch {data.id}
      </TableCell>
      <TableCell component="th" scope="row" sx={{ fontWeight: "bold" }}>
        {data.total}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.approved}
      </TableCell>
      <TableCell component="th" scope="row">
        {data.rejected}
      </TableCell>
      <TableCell component="th" scope="row">
        {dayjs(data.created_at).format("YYYY-MM-DD")}
      </TableCell>
      <TableCell component="th" scope="row" align="right">
        {data.approved + data.rejected < data.total ? (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => navigate(`/app/invoices/batch/${data.id}/process`)}
          >
            Check
          </Button>
        ) : (
          <Box p={0.5} sx={{ color: "#66bb6a" }}>
            Solved
          </Box>
        )}
      </TableCell>
    </TableRow>
  );
};

export default InvoiceBatchRow;
