import { makeStyles } from "@mui/styles";

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
    boxShadow: theme.shade.light,
    color: theme.palette.text.primary,
    "&$noMargin": {
      margin: 0,
    },
  },
  descBlock: {
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
  },
  titleText: {
    flex: 1,
  },
  title: {
    position: "relative",
    textTransform: "capitalize",
    fontSize: 24,
    fontWeight: "bold",
    color:
      theme.palette.type === "dark"
        ? theme.palette.primary.main
        : theme.palette.primary.dark,
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      fontWeight: 600,
      marginBottom: theme.spacing(1),
    },
  },
  description: {
    maxWidth: 960,
    paddingTop: theme.spacing(0.5),
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
  content: {
    marginTop: theme.spacing(2),
    paddingTop: 0,
    borderRadius: theme.rounded.medium,
    backgroundColor: theme.palette.background.secondary,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2),
    },
  },
  whiteBg: {
    backgroundColor: "transparent",
    margin: 0,
    padding: 0,
  },
  noMargin: {},
  colorMode: {
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.primary.dark
        : theme.palette.primary.main,
    "& $title": {
      color: theme.palette.grey[100],
      "&:after": {
        borderBottom: `5px solid ${theme.palette.primary.light}`,
      },
    },
    "& $description": {
      color: theme.palette.grey[100],
    },
  },
  overflowX: {
    width: "100%",
    overflowX: "auto",
  },
  iconTitle: {
    borderRadius: theme.rounded.small,
    boxShadow: `0 2px 15px -5px #000000`,
    width: 45,
    height: 45,
    textAlign: "center",
    lineHeight: "44px",
    verticalAlign: "middle",
    marginRight: theme.spacing(3),
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
    "& i": {
      fontSize: 28,
      verticalAlign: "baseline",
      color:
        theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.primary.main,
    },
  },
}));

export default styles;
