import React from "react";
import classNames from "classnames";
import { useStyles } from "./style-jss";

const Layout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.appFrameAuth, classes.gradientBg)}>
      <main className={classes.outerContent} id="mainContent">
        {children}
      </main>
    </div>
  );
};
export default Layout;
