import React from "react";
import HeadingLine from "components/HeadingLine";
import FormField from "components/FormField";
import "../../../css/responsive.css";
import {
  Grid,
  Checkbox,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@mui/material";

const Signature = ({
  setForm,
  form,
  loggedIn,
  validationErrors,
  validateField,
}) => {
  const handleChange = async (e) => {
    const _membership = { ...form.membership };
    _membership[e.target.name] =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setForm({ membership: _membership });
    if (typeof validateField === "function") {
      // Check if validateField is a function
      await validateField(`membership.${e.target.name}`, e.target.value);
    } else {
    }
  };

  const handleImageChange = (event) => {
    const _membership = { ...form.membership };
    _membership.file = event.target.files[0];
    _membership.fileURL = URL.createObjectURL(event.target.files[0]);
    setForm({ membership: _membership });
  };

  const { membership } = form;

  return (
    <>
      <Grid item md={12} sm={12} xs={12}>
        <HeadingLine title="Communications &amp; Disclosures" />
      </Grid>
      <Grid item md={12} sm={12} xs={12}>
        <Grid container mt={1}>
          <Grid item xs={12} md={3}>
            <FormControl>
              <FormLabel id="radio-buttons-membership-card">
                Membership card
              </FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-membership-card"
                name="membership_card"
                value={form.membership.membership_card}
                onChange={(e) => {
                  const membership = { ...form.membership };
                  membership.membership_card = e.target.value;
                  setForm({ membership });
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="hard" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="digital"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl>
              <FormLabel id="radio-buttons-newsletter">Newsletter</FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-newsletter"
                name="newsletter"
                value={form.membership.newsletter}
                onChange={(e) => {
                  const membership = { ...form.membership };
                  membership.newsletter = e.target.value;
                  setForm({ membership });
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="hard" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="digital"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl>
              <FormLabel id="radio-buttons-communications">
                Communications
              </FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-communications"
                name="communications"
                value={form.membership.communications}
                onChange={(e) => {
                  const membership = { ...form.membership };
                  membership.communications = e.target.value;
                  setForm({ membership });
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="hard" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="digital"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControl>
              <FormLabel id="radio-buttons-invoice-preference">
                Invoice preference
              </FormLabel>
              <RadioGroup
                aria-labelledby="radio-buttons-invoice-preference"
                name="invoice_preference"
                value={form.membership.invoice_preference}
                onChange={(e) => {
                  const membership = { ...form.membership };
                  membership.invoice_preference = e.target.value;
                  setForm({ membership });
                }}
              >
                <FormControlLabel value="1" control={<Radio />} label="hard" />
                <FormControlLabel
                  value="2"
                  control={<Radio />}
                  label="digital"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="paperless_communication"
              onChange={handleChange}
              checked={membership.paperless_communication}
            />
          }
          sx={{ marginTop: "16px", marginBottom: "16px" }}
          label={"Opt into paperless communications"}
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="pay_by_cc"
              onChange={handleChange}
              checked={membership.pay_by_cc}
            />
          }
          label={"I would like to pay by credit card, please call me"}
        />
        <br />
        {!loggedIn && (
          <FormControlLabel
            control={
              <Checkbox
                required
                color="primary"
                name="terms_acceptance"
                onChange={handleChange}
                checked={membership.terms_acceptance}
              />
            }
            label={
              "I have read and accept the Club's Terms, Conditions, Disclosures & Processing Fees."
            }
            error={
              validationErrors &&
              validationErrors[`membership[${membership.id}].terms_acceptance`]
            } // Adjusted to use membership ID
            helperText={
              validationErrors &&
              validationErrors[
                `membership[${membership.id}].terms_acceptance`
              ]?.replace(
                `membership[${membership.id}].terms_acceptance`,
                "Terms Acceptance"
              )
            }
          />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <input
          type="file"
          name="file"
          accept="image/png, image/jpg, image/jpeg"
          className="form-control-file"
          onChange={handleImageChange}
        />
      </Grid>
      <Grid item md={4} sm={12} xs={12} sx={{ ml: 0 }}>
        <FormField
          label="Date"
          type="date"
          name="date"
          onChange={handleChange}
          value={membership.date}
          disabled
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </>
  );
};

export default Signature;
