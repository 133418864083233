import React from "react";
import InputMask from "react-input-mask";
import FormField from "components/FormField";
import InputAdornment from "@mui/material/InputAdornment";
import { Button } from "@mui/material";
import useSystem from "WebR/hooks/useSystem";
import { parsePhoneNo } from "utils/functions";
import Swal from "sweetalert2";

const CallableField = ({ value, onChange, ...others }) => {
  const { data, call } = useSystem();

  const callContact = (member, number) => {
    if (localStorage.getItem("status") === "offline") {
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-right",
        text: "You Are Not Online!",
        showConfirmButton: false,
        timer: 4000,
        timerProgressBar: true,
      });
    } else if (localStorage.getItem("status") === "online") {
      let phone = parsePhoneNo(number);
      call("+" + phone);
    }
  };

  return (
    <InputMask value={value} onChange={onChange} mask="(+1) 999-999-9999">
      {() => (
        <FormField
          {...others}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {!data.state && (
                  <Button
                    onClick={() => callContact(null, value)}
                    variant="contained"
                    color="primary"
                    size="small"
                  >
                    Call
                  </Button>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    </InputMask>
  );
};

export default CallableField;
