import React, { Fragment } from "react";
import { Grid } from "@mui/material";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import AddNew from "components/AddNew";
import HeadingLine from "components/HeadingLine";
import MemberUpdate from "../MemberUpdate";
import { contact } from "components/MembershipForm/formConfig";

const MembersListUpdate = ({ members, onChange, validationErrors }) => {
  const special = ["1st", "2nd", "3rd", "4th", "5th"];

  const handleRemoveMember = (index) => {
    const newMembers = members;
    newMembers.splice(index, 1);
    onChange(newMembers);
  };

  const handleAddMember = () => {
    const newMembers = members;
    newMembers.push(contact);
    onChange(newMembers);
  };

  const handleOnChangeMember = (index, values) => {
    const newMembers = members;
    newMembers[index] = values;
    onChange(newMembers);
  };

  return (
    <>
      <Grid container item spacing={2} mb={2}>
        {members.map((member, index) => (
          <Fragment key={`member-${index}`}>
            <Grid item md={12} sm={12} xs={12}>
              <HeadingLine
                title={
                  index === 0
                    ? "Primary Member Details"
                    : `${special[index]} Member Details`
                }
                actionButton={
                  index !== 0 && (
                    <IconButton
                      onClick={() => handleRemoveMember(index)}
                      size="small"
                    >
                      <Close color="secondary" />
                    </IconButton>
                  )
                }
              />
            </Grid>
            <MemberUpdate
              key={`member-${index}`}
              member={member}
              onChange={(values) => handleOnChangeMember(index, values)}
              validationErrors={validationErrors}
              index={index}
            />
          </Fragment>
        ))}
      </Grid>
      {members.length < 3 && (
        <Grid item md={12} sm={12} xs={12}>
          <AddNew
            onClick={handleAddMember}
            label={`Add ${special[members.length]} Member`}
            nomargin
          />
        </Grid>
      )}
    </>
  );
};

export default MembersListUpdate;
