import axios from "utils/axios";
import { GET_USERS_ROUTE, USER_ROUTES } from "./routes";

const getUsersRequest = () => axios.get(GET_USERS_ROUTE);
const getUserRequest = (uuid) => axios.get(`${USER_ROUTES}/${uuid}`);
const createUserRequest = (params) =>
  axios.post(`${USER_ROUTES}/create`, params);
const updateUserRequest = (params, uuid = null) =>{
  axios.post(`${USER_ROUTES}/${uuid ? "update/" + uuid : "update"}`, params)};

const deleteUserRequest = (params, uuid = null) =>
  axios.delete(`${USER_ROUTES}/delete/id?id=${params}`);

const locationRequest = (office_id, data, page, perPage) =>
  axios.post(`${USER_ROUTES}/service_location?office_id=${office_id}`, data, {
    params: {
      page,
      per_page: perPage,
    },
  });

const searchUsersRequest = (search) => {
  return axios.get(GET_USERS_ROUTE, { params: { ...search } });
};

const changeMfaRequest = (params) => axios.post(`${USER_ROUTES}/mfa`, params);

const createRcAccountRequest = (params) =>
  axios.post(`${USER_ROUTES}/rc`, params);

export {
  getUsersRequest,
  getUserRequest,
  createUserRequest,
  searchUsersRequest,
  deleteUserRequest,
  updateUserRequest,
  changeMfaRequest,
  createRcAccountRequest,
  locationRequest,
};
