import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Popover, Card, CardContent } from "@mui/material";
import useStyles from "./styles";

const Popup = forwardRef(({ children }, ref) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  useImperativeHandle(ref, () => ({
    displayPopup: (event) => setAnchorEl(event.currentTarget),
    hidePopup: () => setAnchorEl(null),
  }));

  return (
    <Popover
      elevation={3}
      id="accountPopup"
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={() => setAnchorEl(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Card>
        <CardContent className={classes.cardContent}>{children}</CardContent>
      </Card>
    </Popover>
  );
});

export default Popup;
