import { useRcState } from "../system/Provider";
import WebPhone from "ringcentral-web-phone";
import useSystem from "./useSystem";
import outgoingAudio from "../audio/outgoing.ogg";
import incomingAudio from "../audio/incoming.ogg";
import SDK from "@ringcentral/sdk";
import { useEffect } from "react";

const useRingCentral = () => {
  const { callService, setCallService, reset } = useRcState();
  const { setCallData } = useSystem();

  const register = (data) => {
    localStorage.setItem("dataRegis", JSON.stringify(data));
    const remoteVideoElement = document.getElementById("remoteVideo");
    const localVideoElement = document.getElementById("localVideo");
    const webPhone = new WebPhone(data, {
      // appKey: "YaxWzlRNt5sdO1ZCkPnjeb",
      appKey: "VBQDgr5Ow2XcNqG3jqzKZJ",
      logLevel: 0,
      appName: "HOC",
      appVersion: "0.0.1",
      audioHelper: {
        enabled: true,
        incoming: incomingAudio,
        outgoing: outgoingAudio,
      },
      media: {
        remote: remoteVideoElement,
        local: localVideoElement,
      },
      enableQos: true,
      enableMediaReportLogging: true,
    });
    webPhone.userAgent.audioHelper.loadAudio({
      incoming: incomingAudio,
      outgoing: outgoingAudio,
    });
    webPhone.userAgent.audioHelper.setVolume(1);
    webPhone.userAgent.on("invite", async function (session) {
      await setCallData({
        call_id: "",
        type: "Inbound",
        state: "ringing",
        phoneNo: session.request.from.uri.user,
        contact: session.request.from.displayName,
        memberId: null,
        isUnknown: false,
        session,
      });

      setCallService({
        psession: {
          type: "Inbound",
          state: "in-call",
          phoneNo: session.request.from.uri.user, //friendlyName
          contact: session.request.from.displayName,
          memberId: null,
          isUnknown: false,
          session,
        },
      });
    });

    webPhone.userAgent.on("connecting", function () {
      console.log("UA connecting");
    });
    webPhone.userAgent.on("connected", function () {
      console.log("UA Connected");
    });
    webPhone.userAgent.on("disconnected", function () {
      console.log("UA Disconnected");
    });
    webPhone.userAgent.on("registered", function () {
      console.log("UA Registered");
    });
    webPhone.userAgent.on("unregistered", function () {
      console.log("UA Unregistered");
    });
    webPhone.userAgent.on("registrationFailed", function () {
      console.log("UA RegistrationFailed", arguments);
    });
    webPhone.userAgent.on("message", function () {
      console.log("UA Message", arguments);
    });
    webPhone.userAgent.transport.on("switchBackProxy", function () {
      console.log("switching back to primary outbound proxy");
      webPhone.userAgent.transport.reconnect(true);
    });
    webPhone.userAgent.transport.on("closed", function () {
      console.log("WebSocket closed.");
    });
    webPhone.userAgent.transport.on("transportError", function () {
      console.log("WebSocket transportError occured");
    });
    webPhone.userAgent.transport.on("wsConnectionError", function () {
      console.log("WebSocket wsConnectionError occured");
    });
    setCallService({ webPhone });
    return webPhone;
  };

  const unregister = () => {
    callService?.webPhone?.userAgent?.stop();
    reset();
  };

  useEffect(() => {
    const data = localStorage.getItem("dataRegis");
    if (data) {
      register(JSON.parse(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const postLogin = (platform) => {
    platform
      .get("/restapi/v1.0/account/~/extension/~")
      .then(function (res) {
        const ext = res.json();
        setCallService({ extension: ext });
        return platform.post("/restapi/v1.0/client-info/sip-provision", {
          sipInfo: [
            {
              transport: "WSS",
            },
          ],
        });
      })
      .then(function (res) {
        return res.json();
      })
      .then(register);
  };

  const initialize = (user, cb) => {
    const status = localStorage.getItem("status"); // Assuming status is stored in localStorage
    if (status !== "online") {
      setCallService({ loading: true });

      const sdk = new SDK({
        appName: "HOC",
        // server: "https://platform.devtest.ringcentral.com",
        server: "https://platform.ringcentral.com",
        // redirectUri: "http://localhost:3000/app/callback",
        // redirectUri: "https://archernational-front-uat.chetu.com/app/callback",
        redirectUri: "https://www.hocportal.online/app/callback",
        // redirectUri: "https://www.hocportal.com/app/callback",
        clientId: "VBQDgr5Ow2XcNqG3jqzKZJ",
        clientSecret: "6oIgCS3CCkQfAKpzGT6Kvw6ussCfYnmDBdkEcouCy2IT",
      });
      const platform = sdk.platform();
      const loginUrl = platform.loginUrl();

      // const JWT_TOKEN = 'eyJraWQiOiI4NzYyZjU5OGQwNTk0NGRiODZiZjVjYTk3ODA0NzYwOCIsInR5cCI6IkpXVCIsImFsZyI6IlJTMjU2In0.eyJhdWQiOiJodHRwczovL3BsYXRmb3JtLmRldnRlc3QucmluZ2NlbnRyYWwuY29tL3Jlc3RhcGkvb2F1dGgvdG9rZW4iLCJzdWIiOiIzODk3ODQwMDQiLCJpc3MiOiJodHRwczovL3BsYXRmb3JtLmRldnRlc3QucmluZ2NlbnRyYWwuY29tIiwiZXhwIjozODQwMTE1NzM3LCJpYXQiOjE2OTI2MzIwOTAsImp0aSI6IlBER1Jablp4U3gtMklxbmdWaWtRa0EifQ.OikgjPsXB5ZicKJrCLVEV2dG0IRdElXCGZYVtWA8H54Ud0H5Z3CLWJ27-PbqRJAy_7OvbRI7DH_LERkqPGDn8fEa1K3RhfeDyedzGKCV0R_3CoWeev-ynf9zWs-gPEyoiFTcBYRYXmBSF0p5m_eNfCb5wKHHwFUC8YiMMbwtvTPW9UCZnK52WmhiyxDOxrP5Oan0aouL2XB7wkacm2_jCb_4zurW8HxkP7FI3x2q0dDpq9ZV4lC6KFr7uuPC9Ou361HJJmM4INhDfLBdHLnmBpzYtFVtEvNhgYkdiAYb7QbsL2DDNA0lAxBB9t8CnjolmdLPmzLoczojlZK8DeeQrA'
      // platform.login( jwt=JWT_TOKEN )

      platform
        .loginWindow({ url: loginUrl })
        .then(platform.login.bind(platform))
        .then(() => {
          setCallService({ states: "online", user: user, platform });
          postLogin(platform);
          localStorage.setItem("status", "online");
          cb && cb();
        })
        .catch((error) => {
          setCallService({ error: error });
        });
    }
  };

  return {
    initialize,
    unregister,
    callService,
  };
};

export default useRingCentral;
