import React from "react";
import CountUp from "react-countup";
import { Box, Paper, Typography } from "@mui/material";
import useStyles from "./styles-jss";

const CounterWidget = (props) => {
  const classes = useStyles();
  const {
    color,
    start,
    end,
    total,
    active,
    duration,
    title,
    children,
    unitBefore,
    unitAfter,
  } = props;
  return (
    <Paper className={classes.root} style={{ backgroundColor: color }}>
      <div>
        <Typography className={classes.counter}>
          {unitBefore}
          {title !== "Members" &&
            title !== "Tasks" &&
            title !== "Jobs" &&
            title !== "Invoices" && (
              <CountUp start={start} end={end} duration={duration} useEasing />
            )}
          {unitAfter}
        </Typography>
        {title === "Members" && (
          <Box sx={{ display: "flex", justifyContent: "between" }}>
            <>
              <Typography className={classes.title} variant="subtitle1">
                Total: {total}
              </Typography>
              <Typography
                sx={{ marginLeft: 3 }}
                className={classes.title}
                variant="subtitle1"
              >
                Active: {active}
              </Typography>
            </>
          </Box>
        )}
        {title === "Tasks" && (
          <Box sx={{ display: "flex", justifyContent: "between" }}>
            <>
              <Typography className={classes.title} variant="subtitle1">
                Total: {total}
              </Typography>
              <Typography
                sx={{ marginLeft: 3 }}
                className={classes.title}
                variant="subtitle1"
              >
                Your Tasks: {active}
              </Typography>
            </>
          </Box>
        )}
        {title === "Jobs" && (
          <Box sx={{ display: "flex", justifyContent: "between" }}>
            <>
              <Typography className={classes.title} variant="subtitle1">
                Total: {total}
              </Typography>
              <Typography
                sx={{ marginLeft: 3 }}
                className={classes.title}
                variant="subtitle1"
              >
                Completed: {active}
              </Typography>
            </>
          </Box>
        )}
        {title === "Invoices" && (
          <Box sx={{ display: "flex", justifyContent: "between" }}>
            <>
              <Typography className={classes.title} variant="subtitle1">
                Total: {total}
              </Typography>
              <Typography
                sx={{ marginLeft: 3 }}
                className={classes.title}
                variant="subtitle1"
              >
                Paid: {active}
              </Typography>
            </>
          </Box>
        )}
        <Typography className={classes.title} variant="subtitle1">
          {title}
        </Typography>
      </div>
      <div className={classes.customContent}>{children}</div>
    </Paper>
  );
}

CounterWidget.defaultProps = {
  unitBefore: "",
  unitAfter: "",
};

export default CounterWidget;
