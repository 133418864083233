import axios from "utils/axios";
import { DELETED_MEMBERT, DELETED_MEMBERTV } from "./routes";
const GET_MEMBERSHIP_CONTRACTORS_ROUTE_CALL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/user-call-record`;
const MEMBERSHIP_SEARCH_ROUTE_CALL = `${process.env.REACT_APP_API_DOMAIN}/api/v1/ring-central/user-call-record`;

const searchRequest = (search) =>
  axios.post(MEMBERSHIP_SEARCH_ROUTE_CALL, {}, { params: search });

const getMembershipContractorsRequest = (membership_id) =>
  axios.get(`${GET_MEMBERSHIP_CONTRACTORS_ROUTE_CALL}/${membership_id}`);

const DeletedMemberRequestv = (data) =>
  axios.delete(DELETED_MEMBERT, { params: data });
const DeletedMemberRequestf = (data) => axios.post(DELETED_MEMBERTV, data);

export {
  searchRequest,
  getMembershipContractorsRequest,
  DeletedMemberRequestv,
  DeletedMemberRequestf,
};
