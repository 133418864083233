import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Chip,
  TextField,
  Button,
  Grid,
  Box,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useFormik } from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import InputMask from "react-input-mask";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import CityPicker from "components/CityPicker";
import StatePicker from "components/StatePicker";
import useUtils from "hooks/useUtils";
import { searchZipDataRequest } from "api/utilities";
import useAccessControl from "hooks/useAccessControl";
import ServiceLocationPicker from "components/ServiceLocationPicker";
import React, { useEffect, useState } from "react";

const ContractorValidationSchema = Yup.object().shape({
  alpha_ref: Yup.string()
    .required("This field is required")
    .length(3, "Must enter 3 characters"),
  name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),
  service_location_id: Yup.number().nullable(),
  phone: Yup.string().required("This field is required"),
  zip: Yup.string()
    .required("The zip field is required.")
    .matches(/^\d{5}(-\d{4})?$/, "The selected zip is invalid."),
  communication_pref_id: Yup.string().required("This field is required").nullable(),
});

const ContractorsForm = ({ form, onSubmitHandler, withHeadingLine }) => {
  const { canManageServiceLocationUser } = useAccessControl();
  const navigate = useNavigate();
  const { utils } = useUtils();

  const formik = useFormik({
    initialValues: {
      service_location_id: "",
      service_location: null,
      job_category_ids: [],
      city_name:
        form.city_name && typeof form.city_name === "string"
          ? { id: form.city_id, label: form.city_name }
          : form.city_name,
      ...form,
    },
    validationSchema: ContractorValidationSchema,
    onSubmit: async (values) => {
      const submissionData = {
        ...values,
        job_category_ids: values?.job_category_ids?.map((option) => option?.id),
      };
      await onSubmitHandler(submissionData);
    },
  });

  const [draftData, setDraftData] = useState({});

  useEffect(() => {
    if (form) {
      formik.setValues({
        ...formik.values,
        ...form,
        job_category_ids: form.job_category_ids
          ?.map((id) => {
            const category = utils.categories.find((cat) => cat.id === id);
            return category ? { id: category.id, name: category.name } : null;
          })
          .filter(Boolean),
        city_name:
          form.city_name && typeof form.city_name === "string"
            ? { id: form.city_id, label: form.city_name }
            : form.city_name,
      });
    }
    // eslint-disable-next-line
  }, [form, utils.categories]);

  useEffect(() => {
    const savedDraft = JSON.parse(localStorage.getItem("myFormDraft"));
    if (savedDraft) {
      setDraftData(savedDraft);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("myFormDraft", JSON.stringify(draftData));
  }, [draftData]);

  const handleZipChange = (e) => {
    if (e.target.value.length <= 4) {
      return false;
    }
    searchZipDataRequest(e.target.value).then(({ data }) => {
      if (!formik.values.state_id !== data.details.state.code) {
        formik.setFieldValue("state_id", data.details.state.code);
        formik.setFieldValue("city_name", {
          id: data.details.city.id,
          label: data.details.city.city,
        });
      }
    });
  };

  const handleClick = (form) => {
    const mailtoUrl = `mailto:${form?.email}`;
    window.open(mailtoUrl, "_blank");
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          {withHeadingLine && <HeadingLine title="Contractor Information" />}
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={3}>
              <FormField
                label="Alpha Ref"
                disabled={!!formik.values.id}
                value={formik.values.alpha_ref || ""}
                onChange={formik.handleChange}
                name="alpha_ref"
                required
                error={
                  formik.touched.alpha_ref && Boolean(formik.errors.alpha_ref)
                }
                helperText={formik.touched.alpha_ref && formik.errors.alpha_ref}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Company name"
                value={formik.values.name || ""}
                onChange={formik.handleChange}
                name="name"
                required
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormField
                label="Vendor type"
                value={formik.values.vendor_type_id || ""}
                onChange={formik.handleChange}
                name="vendor_type_id"
                disabled
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormField
                label="Factoring %"
                value={formik.values.factor_percent || ""}
                onChange={formik.handleChange}
                name="factor_percent"
                required
                error={
                  formik.touched.factor_percent &&
                  Boolean(formik.errors.factor_percent)
                }
                helperText={
                  formik.touched.factor_percent && formik.errors.factor_percent
                }
              />
            </Grid>

            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={utils.categories}
                getOptionLabel={(option) => option.name}
                value={formik.values.job_category_ids || []}
                onChange={(event, newValue) => {
                  const uniqueValues = [
                    ...new Map(
                      newValue.map((item) => [item.id, item])
                    ).values(),
                  ];
                  formik.setFieldValue("job_category_ids", uniqueValues);
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      key={option.id}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    placeholder="Select categories"
                    fullWidth
                    error={
                      formik.touched.job_category_ids &&
                      Boolean(formik.errors.job_category_ids)
                    }
                    helperText={
                      formik.touched.job_category_ids &&
                      formik.errors.job_category_ids
                    }
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <HeadingLine title="Address Details" />
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={3}>
              <FormField
                label="Street Address"
                value={formik.values.address_line || ""}
                onChange={formik.handleChange}
                name="address_line"
                error={
                  formik.touched.address_line &&
                  Boolean(formik.errors.address_line)
                }
                helperText={
                  formik.touched.address_line && formik.errors.address_line
                }
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Zip code"
                value={formik.values.zip || ""}
                onChange={formik.handleChange}
                onBlur={(e) => handleZipChange(e, formik)}
                name="zip"
                type="number"
                required
                error={formik.touched.zip && Boolean(formik.errors.zip)}
                helperText={formik.touched.zip && formik.errors.zip}
              />
              <span></span>
            </Grid>
            <Grid item xs={12} md={3}>
              <StatePicker
                label="State"
                name="state_id"
                type="text"
                value={formik.values.state_id || ""}
                onChange={(e) =>
                  formik.setFieldValue("state_id", e.target.value)
                }
                error={
                  formik.touched.state_id && Boolean(formik.errors.state_id)
                }
                helperText={formik.touched.state_id && formik.errors.state_id}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <CityPicker
                label="City"
                name="city_name"
                type="text"
                value={formik.values.city_name || ""}
                state_code={formik.values.state_id}
                onChange={(val) => formik.setFieldValue("city_name", val)}
                error={
                  formik.touched.city_name && Boolean(formik.errors.city_name)
                }
                helperText={formik.touched.city_name && formik.errors.city_name}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4}>
          <HeadingLine title="Contact Details" />
          <Grid container spacing={2} mt={1}>
            <Grid item xs={12} md={3}>
              <FormField
                label="Contact"
                value={formik.values.contact || ""}
                onChange={formik.handleChange}
                name="contact"
                error={formik.touched.contact && Boolean(formik.errors.contact)}
                helperText={formik.touched.contact && formik.errors.contact}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              {form.alpha_ref ? null : (
                <FormField
                  label="Email"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  name="email"
                  type="email"
                  required
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              )}
              {form.alpha_ref && (
                <FormField
                  label="Email *"
                  value={formik.values.email || ""}
                  onChange={formik.handleChange}
                  name="email"
                  type="email"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <React.Fragment>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleClick(form)}
                          >
                            Email
                          </Button>
                        </React.Fragment>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={3}>
              <InputMask
                value={formik.values.phone || ""}
                onChange={formik.handleChange}
                mask="(+1) 999-999-9999"
              >
                {() => (
                  <FormField
                    required
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    label="Phone"
                    name="phone"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputMask
                value={formik.values.home_phone || ""}
                onChange={formik.handleChange}
                mask="(+1) 999-999-9999"
              >
                {() => (
                  <FormField
                    error={
                      formik.touched.home_phone &&
                      Boolean(formik.errors.home_phone)
                    }
                    helperText={
                      formik.touched.home_phone && formik.errors.home_phone
                    }
                    label="Home phone"
                    name="home_phone"
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Fax"
                value={formik.values.fax || ""}
                onChange={formik.handleChange}
                name="fax"
                error={formik.touched.fax && Boolean(formik.errors.fax)}
                helperText={formik.touched.fax && formik.errors.fax}
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <FormField
                label="Pager"
                value={formik.values.pager || ""}
                onChange={formik.handleChange}
                name="pager"
                error={formik.touched.pager && Boolean(formik.errors.pager)}
                helperText={formik.touched.pager && formik.errors.pager}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Web"
                value={formik.values.website || ""}
                onChange={formik.handleChange}
                name="website"
                error={formik.touched.website && Boolean(formik.errors.website)}
                helperText={formik.touched.website && formik.errors.website}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <FormField
                label="Communication Pref *"
                value={formik.values.communication_pref_id || ""}
                onChange={formik.handleChange}
                name="communication_pref_id"
                select
                error={
                  formik.touched.communication_pref_id &&
                  Boolean(formik.errors.communication_pref_id)
                }
                helperText={
                  formik.touched.communication_pref_id &&
                  formik.errors.communication_pref_id
                }
              >
                {utils.communication_prefs.map((val) => (
                  <MenuItem key={val?.id} value={val?.id}>
                    {val.label}
                  </MenuItem>
                ))}
              </FormField>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2}>
          <FormField
            label="Comment"
            value={formik.values.comment || ""}
            onChange={formik.handleChange}
            name="comment"
            multiline
            minRows={3}
            error={formik.touched.comment && Boolean(formik.errors.comment)}
            helperText={formik.touched.comment && formik.errors.comment}
          />
        </Box>
        {canManageServiceLocationUser() && (
          <Box mt={4}>
            <HeadingLine title="Service Location" />
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} md={3}>
                <ServiceLocationPicker
                  label="Service Location"
                  InputLabelProps={{ shrink: true }}
                  value="Seattle Service Location"
                  name="service_location_id"
                  onChange={(val) => {
                    formik.setFieldValue("service_location", val);
                    formik.setFieldValue("service_location_id", val?.id);
                  }}
                />
              </Grid>
            </Grid>
          </Box>
        )}
        <Box mt={2} display="flex" justifyContent="flex-end">
          <Button
            variant="text"
            sx={{ mr: 1 }}
            onClick={() => navigate("/app/contractors")}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={formik.isSubmitting}
            loadingposition="center"
            variant="contained"
            onClick={formik.submitForm}
            disabled={formik.isInvalid}
          >
            {form.alpha_ref ? "Update contractor" : "Create contractor"}
          </LoadingButton>
        </Box>
      </form>
    </>
  );
};

ContractorsForm.defaultProps = {
  withHeadingLine: true,
  form: {
    alpha_ref: "",
    name: "",
    address_line: "",
    address_line_2: "",
    city_name: "",
    state_id: "",
    zip: "",
    contact: "",
    vendor_type_id: "Contractor",
    job_category_id: "",
    communication_pref_id: "",
    email: "",
    email_2: "",
    phone: "",
    fax: "",
    cell_phone: "",
    pager: "",
    home_phone: "",
    website: "",
    expire: "",
    comment: "",
  },
};

export default ContractorsForm;
