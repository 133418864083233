import React, { useEffect, useState } from "react";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import FormField from "components/FormField";
import HeadingLine from "components/HeadingLine";
import { Close } from "@mui/icons-material";
import { searchZipDataRequest } from "api/utilities";
import StatePicker from "components/StatePicker";
import CityPicker from "components/CityPicker";

const Property = ({
  index,
  property,
  form,
  setForm,
  defaultLocation,
  validationErrors,
  validateField,
}) => {
  const [errors, setErrors] = useState({});
  const handleChange = async (e) => {
    const properties = [...form.properties];
    properties[index][e.target.name] = e.target.value;
    setForm({ properties });
    if (typeof validateField === "function") {
      // Check if validateField is a function
      await validateField(
        `properties[${index}].${e.target.name}`,
        e.target.value
      );
    } else {
    }
  };

  const handleChangeCity = (val) => {
    const properties = [...form.properties];
    properties[index].city = val;
    setForm({ properties });
  };

  const setDefaults = () => {
    const properties = [...form.properties];
    properties[index].city = {
      id: defaultLocation?.city?.id,
      label: defaultLocation?.city?.city,
    };
    properties[index].state = defaultLocation?.state?.code;
    properties[index].zip = defaultLocation.zip;
    setForm({ properties });
  };

  const removeProperty = () => {
    const properties = [...form.properties];
    properties.splice(index, 1);
    setForm({ properties });
  };

  const handleZipChange = (e) => {
    searchZipDataRequest(e.target.value)
      .then(({ data }) => {
        const properties = [...form.properties];
        properties[index].city = {
          id: data.details.city.id,
          label: data.details.city.city,
        };
        properties[index].state = data.details.state.code;
        properties[index].zip = data.details.zip;
        setForm({ properties });
      })
      .catch((error) => {
        const properties = [...form.properties];
        properties[index].city = null;
        properties[index].state = "";
        properties[index].zip = "";
        setForm({ properties });
        let newErrors = {};
        if (error?.response?.data?.errors) {
          Object.keys(error.response.data.errors).forEach((field) => {
            if (error.response.data.errors[field][0] !== undefined) {
              newErrors = {
                ...newErrors,
                [field]: error.response.data.errors[field][0],
              };
            }
          });
        }
        setErrors(newErrors);
      });
  };

  useEffect(() => {
    if (!property.id) setDefaults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {index === 0 && (
        <Grid item md={12} sm={12} xs={12}>
          <HeadingLine title="Property Details" />
        </Grid>
      )}
      <Grid item md={5} sm={12} xs={12}>
        <FormField
          label={`Property ${index + 1} Address*`}
          value={property.address || ""}
          name="address"
          onChange={handleChange}
          error={
            validationErrors && validationErrors[`properties[${index}].address`]
          }
          helperText={
            validationErrors &&
            validationErrors[`properties[${index}].address`]?.replace(
              `properties[${index}].address`,
              "Address"
            )
          }
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <FormField
          label="Zip Code"
          value={property.zip || ""}
          style={{
            "& input": {
              backgroundColor: "red",
            },
          }}
          name="zip"
          onChange={handleChange}
          onBlur={handleZipChange}
          required
          error={Boolean(errors?.zip)}
          helperText={errors?.zip}
          InputProps={
            index !== 0
              ? {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => removeProperty()} size="small">
                        <Close color="secondary" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }
              : {}
          }
        />
      </Grid>
      <Grid item md={2} sm={12} xs={12}>
        <StatePicker
          name="state"
          label="State"
          value={property.state || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item md={3} sm={12} xs={12}>
        <CityPicker
          label="City"
          InputLabelProps={{ shrink: true }}
          value={property.city || ""}
          name="city"
          state_code={property.state}
          onChange={handleChangeCity}
        />
      </Grid>
    </>
  );
};

export default Property;
