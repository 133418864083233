import { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Box,
  TextField,
  Divider,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import useUtils from "hooks/useUtils";
import useRoles from "hooks/useRoles";
import useAuth from "hooks/useAuth";
import { EXECUTIVE_ROLE } from "utils/constants";
import Swal from "sweetalert2";

const RolesManagementDialog = ({ open, role, onClose, setRoles, roles }) => {
  const { utils } = useUtils();
  const { updateRole, createRole } = useRoles();
  const { user, updateUserPermissions } = useAuth();
  const [, setLoading] = useState(false);

  const RolesManagementValidationSchema = Yup.object().shape({
    id: Yup.string(),
    name: Yup.string().max(255).required("This field is required"),
    permissions: Yup.array(),
  });

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      permissions: [],
    },
    validationSchema: RolesManagementValidationSchema,
    onSubmit: async (values) => {
      if (role?.id) {
        await updateRole({
          id: role.id,
          name: values.name,
          permissions: values.permissions,
        }).then((resp) => {
          setLoading(true);
          if (user.data.role === role.id) {
            const roleIndex = resp.data.findIndex(
              (respRole) => respRole.id === role.id
            );
            if (roleIndex > -1) {
              const role = resp.data[roleIndex];

              const newPermissions = utils.permissions.filter((permission) =>
                role.permissions.includes(permission.id)
              );
              updateUserPermissions(newPermissions);
            }
          }
          if (setRoles) {
            setRoles(resp?.data);
          }
          Swal.fire({
            toast: true,
            timer: 4000,
            position: "top-right",
            title: "Permissions Updated Successfully!",
            showConfirmButton: false,
            icon: "success",
            timerProgressBar: true,
          });
          handleClose();
        });
      } else {
        setLoading(false);
        await createRole({
          name: values.name,
          permissions: values.permissions,
        })
          .then((resp) => {
            Swal.fire({
              toast: true,
              timer: 4000,
              position: "top-right",
              title: "Role Created Successfully!",
              showConfirmButton: false,
              icon: "success",
              timerProgressBar: true,
            });
            if (resp) {
              setRoles(resp.data);
            }
            handleClose();
          })
          .catch((err) => {
            handleClose();
            Swal.fire({
              toast: true,
              timer: 4000,
              position: "top-right",
              title: err?.response?.data?.message || "Something went wronge!",
              showConfirmButton: false,
              icon: "error",
              timerProgressBar: true,
            });
          });
      }
    },
  });

  useEffect(() => {
    if (role) {
      formik.setValues({
        ...formik.values,
        id: role.id,
        name: role.name,
        permissions: role.permissions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const selectPermission = (permissionid) => {
    let permissionsArray = [...formik.values.permissions];

    if (permissionsArray.indexOf(permissionid) === -1) {
      formik.setFieldValue("permissions", [
        ...formik.values.permissions,
        permissionid,
      ]);
    } else {
      formik.setFieldValue(
        "permissions",
        permissionsArray.filter((permission) => permission !== permissionid)
      );
    }
  };
  const setDialogTitle = () => {
    if (role) return `Manage Role - ${role.name}`;
    return "Create role";
  };

  const setPermissionGroupTitle = (permission, index) => {
    if (index === 0) {
      return (
        <Box>
          <FormLabel
            sx={{ fontWeight: "bold" }}
            id="demo-radio-buttons-group-label"
          >
            {permission.name.split(">")[0]}
          </FormLabel>
          <Divider />
        </Box>
      );
    }

    if (
      utils.permissions[index].name.split(">")[0] !==
      utils.permissions[index - 1].name.split(">")[0]
    ) {
      return (
        <Box mt={2}>
          <FormLabel
            sx={{ fontWeight: "bold", mt: 2 }}
            id="demo-radio-buttons-group-label"
          >
            {permission.name.split(">")[0]}
          </FormLabel>
          <Divider />
        </Box>
      );
    }
  };

  if (!open) return null;

  const rolesOptions = roles?.original?.result?.map((role) => {
    return {
      value: role.id,
      label: role.name,
    };
  });

  const handleCopyRolePermissions = (value) => {
    if (value) {
      const selectedRole = roles?.original?.result?.find(
        (role) => role.id === value.value
      );

      if (selectedRole) {
        formik.setFieldValue("permissions", selectedRole.permissions);
      }
    } else {
      formik.setFieldValue("permissions", formik.initialValues.permissions);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{setDialogTitle()}</DialogTitle>
      <DialogContent>
        <TextField
          label="Role name"
          onChange={(e) => formik.setFieldValue("name", e.target.value)}
          variant="outlined"
          fullWidth
          size="small"
          sx={{ mb: 4, mt: 2 }}
          value={formik.values.name}
          helperText={formik.touched.name && formik.errors.name}
          error={formik.touched.name && Boolean(formik.errors.name)}
        ></TextField>
        {roles?.original?.result?.length > 0 && (
          <Box mb={4}>
            <Autocomplete
              size="small"
              disablePortal
              options={rolesOptions}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              onChange={(_ev, newValue) => handleCopyRolePermissions(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Copy Role Permissions"
                  variant="outlined"
                />
              )}
            />
          </Box>
        )}
        <FormControl sx={{ width: "100%" }}>
          {utils?.permissions?.map((permission, index) => (
            <Box key={permission?.id}>
              {setPermissionGroupTitle(permission, index)}
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.permissions.includes(
                        permission.id
                      )}
                    />
                  }
                  disabled={
                    role?.id === EXECUTIVE_ROLE ||
                    permission.name === "Dashboard > Read"
                  }
                  onChange={() => selectPermission(permission.id)}
                  label={permission.name.split(">")[1]}
                />
              </FormGroup>
            </Box>
          ))}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <LoadingButton
          loading={formik.isSubmitting}
          loadingPosition="center"
          variant="contained"
          onClick={formik.submitForm}
        >
          {role?.id ? "Save" : "Create"}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

RolesManagementDialog.defaultProps = {
  roles: [],
};

export default RolesManagementDialog;
