import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import useApplication from "hooks/useApplication";
import { Grid, Box } from "@mui/material";
import CounterWidget from "components/Counter/CounterWidget";
import TimelineWidget from "components/TimelineWidget";
import ContactWidget from "components/Widgets/Contacts";
import TaskWidget from "components/Widgets/TaskWidget";
import { getDashWidgets } from "api/dashboard";
import useStyles from "./style-jss";
import useAuth from "hooks/useAuth";
import useAccessControl from "hooks/useAccessControl";

const Dashboard = () => {
  const { setPage } = useApplication();
  const classes = useStyles();
  const {
    canReadMember,
    canReadJob,
    canReadInvoice,
    canReadTask,
    canReadConnect,
  } = useAccessControl();

  const { user } = useAuth();
  const [filterDate] = useState([
    dayjs().subtract(user.data.default_date_range || 30, "days"),
    dayjs(),
  ]);
  const [widgets, setWidgets] = useState({
    active_memberships: 0,
    memberships: 0,
    new_memberships: 0,
    tasks: 0,
    open_tasks: 0,
    jobs: 0,
    open_jobs: 0,
    invoices: 0,
    open_invoices: 0,
    loading: false,
  });

  useEffect(() => {
    if (user) {
      switch (user.data.role) {
        case "SYSTEM_ADMINISTRATOR":
          return navigate("/app/users");
        case "SYSTEM_FRIDAY_BILLING_JOB":
          return navigate("/app/contractors");
        default:
          setPage({
            title: "Dashboard",
            path: "/app",
          });
          setWidgets({ ...widgets, loading: true });
          getDashWidgets({ from: filterDate[0], to: filterDate[1] }).then(
            (res) => {
              setWidgets({ ...res.data, loading: false });
            }
          );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterDate]);

  const navigate = useNavigate();
  const handleClick = (link, title) => {
    const state =
      title === "New Members" ||
      title === "Open Tasks" ||
      title === "Open Jobs" ||
      title === "Open Invoices"
        ? { state: { tabValue: "1" } }
        : {};
    navigate(link, state);
  };

  return (
    <Box className={classes.rootCounter}>
      <Grid container spacing={2}>
        {canReadMember() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app/members")}
            >
              <CounterWidget
                color={"#536dfe"}
                total={widgets.memberships}
                active={widgets.active_memberships}
                duration={1}
                title="Members"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-people-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadJob() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app/jobs")}
            >
              <CounterWidget
                color={"rgb(124, 179, 66)"}
                total={widgets.jobs}
                active={widgets.completed_jobs}
                duration={2}
                title="Jobs"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-construct-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadInvoice() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app/invoices")}
            >
              <CounterWidget
                color={"rgb(251, 140, 0)"}
                start={0}
                total={widgets.invoices}
                active={widgets.paid_invoices}
                duration={1}
                title="Invoices"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-alert-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadInvoice() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app")}
            >
              <CounterWidget
                color={"rgb(20, 170, 156)"}
                start={0}
                duration={1}
                title="Estimates"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-clipboard-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadMember() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app/members", "New Members")}
            >
              <CounterWidget
                color={"rgb(103, 129, 255)"}
                start={0}
                end={widgets.new_memberships}
                duration={2}
                title="New Members"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-person-add-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadJob() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app/jobs", "Open Jobs")}
            >
              <CounterWidget
                color={"rgb(144, 199, 86)"}
                start={0}
                end={widgets.open_jobs}
                duration={2}
                title="Open Jobs"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-construct-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadInvoice() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app/invoices", "Open Invoices")}
            >
              <CounterWidget
                color={"rgb(255, 160, 20)"}
                start={0}
                duration={1}
                end={widgets.open_invoices}
                title="Open Invoices"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-alert-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
        {canReadTask() && (
          <Grid item md={3} xs={6}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleClick("/app")}
            >
              <CounterWidget
                color={"rgb(0, 150, 136)"}
                total={widgets.tasks}
                duration={1}
                title="New Estimates"
                unitBefore=""
                unitAfter=""
              >
                <div className={classes.counterIcon}>
                  <i className="ion-ios-checkbox-outline" />
                </div>
              </CounterWidget>
            </div>
          </Grid>
        )}
      </Grid>
      <Box mt={3}>
        <Grid container spacing={2}>
          <Grid item lg={4} md={6} sm={10} xs={12}>
            <TaskWidget filterDate={filterDate} />
          </Grid>
          {canReadConnect() && (
            <Grid item lg={4} md={6} sm={12} xs={12}>
              <ContactWidget filterDate={filterDate} />
            </Grid>
          )}
          <Grid item lg={4} md={6} sm={12} xs={12}>
            <TimelineWidget filterDate={filterDate} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default Dashboard;
