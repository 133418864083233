export const MENU_DASH = "permissions.MENU_DASH";
export const MENU_TASKS = "permissions.MENU_TASKS";
export const MENU_MEMBERS = "permissions.MENU_MEMBERS";
export const MENU_CONTRACTORS = "permissions.MENU_CONTRACTORS";
export const MENU_VENDORS = "permissions.MENU_VENDORS";
export const MENU_JOBS = "permissions.MENU_JOBS";
export const MENU_INVOICES = "permissions.MENU_INVOICES";
export const MENU_PAYMENTS = "permissions.MENU_PAYMENTS";
export const MENU_USERS = "permissions.MENU_USERS";
export const MENU_REPORTS = "permissions.MENU_REPORTS";
export const MENU_ROLES_AND_PERMISSIONS =
  "permissions.MENU_ROLES_AND_PERMISSIONS";
export const MENU_SETTINGS = "permissions.MENU_SETTINGS";
export const MENU_CONNECT = "permissions.MENU_CONNECT";

export const MEMBERSHIP_CREATE = "membership.create";
export const MEMBERSHIP_READ = "membership.read";
export const MEMBERSHIP_UPDATE = "membership.update";
export const MEMBERSHIP_CREATE_ESTIMATE = "membership.create";
export const MEMBERSHIP_DELETE = "membership.delete";
export const MEMBERSHIP_CALL_PAYMENT = "membership.call_payment";

export const INVOICE_CREATE = "invoice.create";
export const INVOICE_READ = "invoice.read";
export const INVOICE_UPDATE = "invoice.update";
export const INVOICE_DELETE = "invoice.delete";
export const INVOICE_CALL_PAYMENT = "invoice.call_payment";
export const INVOICE_VIEW = "invoice.view";
export const INVOICE_PRINT = "invoice.print";
export const INVOICE_EMAIL = "invoice.email";
export const INVOICE_DOWNLOAD = "invoice.download";
export const INVOICE_DOWNLOAD_PDF = "invoice.download_pdf";

export const JOB_CREATE = "job.create";
export const JOB_READ = "job.read";
export const JOB_UPDATE = "job.update";
export const JOB_DELETE = "job.delete";
export const JOB_CREATE_ESTIMATE = "job.create_estimate";
export const JOB_READ_ESTIMATE = "job.read_estimate";
export const JOB_UPDATE_ESTIMATE = "job.update_estimate";

export const USER_CREATE = "user.create";
export const USER_UPDATE = "user.update";
export const USER_READ = "user.read";
export const USER_DELETE = "user.delete";
export const USER_MANAGE_SERVICE_LOCATION = "user.manage_service_location";

export const CONTRACTOR_CREATE = "contractor.create";
export const CONTRACTOR_UPDATE = "contractor.update";
export const CONTRACTOR_READ = "contractor.read";
export const CONTRACTOR_DELETE = "contractor.delete";

export const VENDOR_CREATE = "vendor.create";
export const VENDOR_UPDATE = "vendor.update";
export const VENDOR_READ = "vendor.read";
export const VENDOR_DELETE = "vendor.delete";

export const CHECK_CREATE = "check.create";
export const CHECK_READ = "check.read";
export const CHECK_DELETE = "check.delete";
export const CHECK_UPDATE = "check.update";

export const ROLE_CREATE = "role.create";
export const ROLE_UPDATE = "role.update";
export const ROLE_DELETE = "role.delete";
export const ROLE_READ = "role.read";

export const DASHBORAD_READ = "dashboard.read";
export const TASK_READ = "task.read";
export const PAYMENT_READ = "payment.read";
export const CONNECT_READ = "connect.read";

export const SETTING_READ = "setting.read";
export const SETTING_CREATE = "setting.create";
export const SETTING_UPDATE = "setting.update";
export const SETTING_DELETE = "setting.delete";
