import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Button,
  CircularProgress,
  FormControl,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import useStyles from "../main-jss";
import PasswordTextField from "components/PasswordTextField";
import useAuth from "hooks/useAuth";
import LinkBtn from "components/LinkBtn";
import { useNavigate } from "react-router-dom";
import Toast from "components/Toast";
import { images } from "utils/Images";

const Login = () => {
  const classes = useStyles();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [error] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const loginValidationSchema = Yup.object().shape({
    email: Yup.string()
      .required("Email is required!")
      .email("Please enter valid email"),
    password: Yup.string().required("Password is required!"),
  });
  // useEffect(() => {
  //   if (!localStorage.getItem("userData")) {
  //     navigate("/");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigate]);

  const formik = useFormik({
    initialValues: {
      email: "", //executive@hoc.dev
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      setSubmitting(true);
      login(values?.email, values.password, (success, error) => {
        if (!error && success?.data?.token) {
          Toast.fire({
            icon: "success",
            position: "top-right",
            timer: 4000,
            title: success?.data?.message,
          });
          return navigate("/app");
        } else {
          setSubmitting(false);
          Toast.fire({
            icon: "error",
            position: "top-right",
            timer: 4000,
            title: error?.response?.data?.message,
          });
        }
      });
    },
  });

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.paperWrap}>
            <div className={classes.topBar}>
              <NavLink to="/" className={classes.brand}>
                <img src={images.loginLogo.url} alt="logo" />
              </NavLink>
            </div>
            <Typography variant="h5" className={classes.title} gutterBottom>
              Login
            </Typography>
            {/* TODO: socmedLogin */}
            <section className={classes.formWrap}>
              <form onSubmit={formik.handleSubmit}>
                <Box pt={2}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      fullWidth
                      size="medium"
                      name="email"
                      label={t("auth:email_label")}
                      variant="outlined"
                      placeholder={t("auth:email_placeholder")}
                      color="secondary"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        (formik.touched.email &&
                          Boolean(formik.errors.email)) ||
                        !!error
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PersonIcon
                              color="secondary"
                              className={classes.inputIcon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
                <Box pt={2} pb={2}>
                  <PasswordTextField
                    fullWidth={true}
                    withPreview
                    size="medium"
                    color="secondary"
                    name="password"
                    variant="outlined"
                    placeholder={t("auth:password_placeholder")}
                    label={t("auth:password_label")}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={!!error}
                    helperText={error}
                  />
                  {formik.touched.password && formik.errors.password ? (
                    <div
                      style={{
                        color: "red",
                        textAlign: "left",
                        fontSize: "13px",
                      }}
                    >
                      {formik.errors.password}
                    </div>
                  ) : null}
                </Box>
                <div className={classes.btnArea}>
                  <Button
                    disabled={submitting}
                    variant="contained"
                    fullWidth
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Login
                    {submitting && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <CircularProgress size={16} />
                      </Box>
                    )}
                  </Button>
                </div>
              </form>
              <Box
                justifyContent="center"
                display="flex"
                pt={2}
                pb={2}
                fontSize={18}
              >
                <LinkBtn
                  style={{
                    color: "blue",
                    textDecoration: "none",
                    fontFamily: ["Open Sans", "sans-serif"],
                  }}
                  to="/forgotPassword"
                >
                  Forgot Password?
                </LinkBtn>
              </Box>
            </section>
          </Paper>
        </div>
      </div>
    </div>
  );
};
export default Login;
