import React, { useState, useEffect, useRef, useMemo } from "react";
import useQueue from "hooks/useQueue";
import TaskQueueTab from "./TaskQueueTab";

const TaskQueueMyTasks = () => {
  const [loading, setLoading] = useState(false);
  const {
    queue: { myTask, meta, query: queueQuery },
    getAllMyTasks,
    changeTask,
    changeTaskUser,
  } = useQueue();

  const query = useMemo(() => queueQuery, [queueQuery]);
  const hasFetched = useRef(false);

  useEffect(() => {
    if (!hasFetched.current) {
      const fetchTasks = async () => {
        setLoading(true);
        try {
          await getAllMyTasks(query);
        } finally {
          setLoading(false);
          hasFetched.current = true;
        }
      };
      fetchTasks();
    }
    // eslint-disable-next-line
  }, []);

  const memoizedProps = useMemo(
    () => ({
      fetchTasks: getAllMyTasks,
      taskData: myTask,
      meta,
      query,
      changeTask,
      changeTaskUser,
      loading,
    }),
    [getAllMyTasks, myTask, meta, query, changeTask, changeTaskUser, loading]
  );

  return <TaskQueueTab {...memoizedProps} />;
};

export default TaskQueueMyTasks;
