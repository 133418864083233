import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableRow,
  TableHead,
  TableCell,
} from "@mui/material";
import useApplication from "hooks/useApplication";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import TableFooterWithPagination from "components/TableFooterWithPagination";
import useBatch from "hooks/useBatch";
import InvoiceBatchRow from "./components/InvoiceBatchRow";

const InvoiceBatches = () => {
  const { setPage } = useApplication();
  const {
    invoicesBatchesReducer: { search, results, loading, meta },
    searchInvoicesBatches,
    getInvoicesBatches,
  } = useBatch();

  useEffect(() => {
    setPage({
      title: "Batches",
      path: "/app/invoices",
    });
    searchInvoicesBatches({
      search,
      page: meta.current_page,
      per_page: meta.per_page,
    });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    searchInvoicesBatches({
      search: value,
      page: meta.current_page,
      per_page: meta.per_page,
    });
  };

  const handleChangePage = (_event, newPage) => {
    getInvoicesBatches({
      search,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    getInvoicesBatches({
      search,
      page: meta.current_page,
      per_page: parseInt(event.target.value),
    });
  };

  return (
    <PaperBlock
      title="Batches"
      loading={loading}
      actions={
        <>
          <SearchInput
            defaultValue={search}
            onChange={handleSearch}
            placeholder="By ID"
          />
        </>
      }
    >
      <Table size="small" aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#ID</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Total</TableCell>
            <TableCell>Approved</TableCell>
            <TableCell>Rejected</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.map((invoiceBatch) => (
            <InvoiceBatchRow data={invoiceBatch} key={invoiceBatch.id} />
          ))}
          {!results.length && (
            <TableRow>
              <TableCell colSpan={7} align="center">
                No data
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        {results.length > 0 && (
          <TableFooterWithPagination
            count={meta.total}
            rowsPerPage={meta.per_page}
            page={meta.current_page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Table>
    </PaperBlock>
  );
};
export default InvoiceBatches;
