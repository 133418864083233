import { Box, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./components/BlockedContactsColumn";

const Blockedcontacts = () => {
  const data = [
    {
      id: "1",
      phoneNumber: "9632587410",
      BlockedDate: "02/04/2025",
      Notes: "Spam",
    },
    {
      id: "2",
      phoneNumber: "9632587410",
      BlockedDate: "02/04/2025",
      Notes: "Spam",
    },
    {
      id: "3",
      phoneNumber: "9632587410",
      BlockedDate: "02/04/2025",
      Notes: "Spam",
    },
  ];

  const rows = data.map((blockData, index) => ({
    id: blockData?.id,
    phoneNumber: blockData?.phoneNumber,
    BlockedDate: blockData?.BlockedDate,
    Notes: blockData?.Notes,
  }));

  const renderAction = (data) => (
    <Button
      sx={{ ml: 2 }}
      size="small"
      color="primary"
      style={{ backgroundColor: "red", color: "#fff" }}
      onClick={() => console.log(data)}
    >
      UnBlock
    </Button>
  );

  return (
    <div>
      <Box sx={{ overflow: "auto" }}>
        <DataGrid
          rows={rows}
          columns={columns(renderAction)}
          rowCount={data?.length}
          autoHeight
          disableSelectionOnClick
          paginationMode="server"
          autoPageSize
        />
      </Box>
    </div>
  );
};
export default Blockedcontacts;
