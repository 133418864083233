import axios from "utils/axios";

const useFileManager = () => {
  // Files
  const getFiles = (data) =>
    axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/files`, data);

  const getFilesView = (uuid) =>
    axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/file-preview`, uuid);

  const deleteFile = (uuid) => {
    //
  };

  const deleteFiles = (uuids) => {
    //
  };

  const vieFile = (uuids) => {};

  const downloadFilesImg = (fileUuid) => {
    axios.get(
      `${process.env.REACT_APP_API_DOMAIN}/api/v1/file-download/${fileUuid}`
    );
  };

  const downloadFiles = (uuids) => {};

  const uploadFile = (data) =>
    axios.post(`${process.env.REACT_APP_API_DOMAIN}/api/v1/files/upload`, data);

  return {
    getFiles,
    getFilesView,
    deleteFile,
    deleteFiles,
    vieFile,
    downloadFilesImg,
    downloadFiles,
    uploadFile,
  };
};

export default useFileManager;
