import { CircularProgress, Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import useVoiceCallMail from "hooks/useVoiseMail";
import { DataGrid } from "@mui/x-data-grid";
import BlockedReason from "./components/BlockedReason";
import { CHANGE_PER_PAGE_CALL_LOG } from "redux/actions/membersCallLogActions";
import { useDispatch } from "react-redux";

const Voicemail = () => {
  const dispatch = useDispatch();
  const {
    getVoiceCallMail,
    voiceCallMailReducer: { results, query, meta, loading, accessToken },
    changePage,
  } = useVoiceCallMail();

  useEffect(() => {
    getVoiceCallMail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [audioSrcs, setAudioSrcs] = useState({}); // State to hold audio sources per row
  const [open, setOpen] = useState(false);
  const [blockId, setBlockId] = useState("");

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getVoiceCallMail({
      search: query,
      page: newPage + 1,
      perPage: meta?.per_page,
    });
  };

  const handleChangeRowsPerPage = (newPageSize) => {
    dispatch({
      type: CHANGE_PER_PAGE_CALL_LOG,
      perPage: parseInt(newPageSize),
    });
    getVoiceCallMail({
      search: query,
      page: meta.current_page,
      perPage: newPageSize,
    });
  };

  const voiceCallResults = results?.map((voiceData, index) => ({
    id: voiceData?.id,
    name: voiceData.from?.name ?? "Unknown",
    phoneNumber: `${
      voiceData?.from?.phoneNumber || voiceData?.from?.extensionNumber
    }`,
    messageStatus: voiceData?.messageStatus,
    creationTime: voiceData?.creationTime,
    direction: voiceData?.direction,
    uri: voiceData?.attachments?.[0]?.uri, // Fetch only the first attachment URI
    attachments: voiceData?.attachments,
  }));

  useEffect(() => {
    // Only fetch the audio file once for each row
    voiceCallResults.forEach((item) => {
      if (item?.attachments?.[0]?.uri && !audioSrcs[item.id]) {
        fetchAudio(item?.attachments[0]?.uri, accessToken, item.id);
      }
    }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  async function fetchAudio(uri, token, rowId) {
    const response = await fetch(uri, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    setAudioSrcs((prevState) => ({
      ...prevState,
      [rowId]: url, // Store the audio URL for the specific row
    }));
  }

  const columns = (renderAction) => [
    { field: "name", headerName: "Name", width: 200 },
    {
      field: "phoneNumber",
      headerName: "Phone",
      width: 300,
      editable: true,
    },
    {
      field: "messageStatus",
      headerName: "Status",
      width: 300,
      editable: true,
    },
    {
      field: "uri",
      headerName: "Duration",
      width: 400,
      editable: true,
      renderCell: (params) => {
        const audioSrc = audioSrcs[params?.row?.id];
        return audioSrc ? (
          <audio controls src={audioSrc} />
        ) : (
          "Loading audio..."
        );
      },
    },
    {
      field: "creationTime",
      headerName: "Date",
      sortable: false,
      width: 300,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    {
      field: "BlockContact",
      headerName: "Action",
      sortable: false,
      width: 200,
      renderCell: renderAction,
    },
  ];

  const blockTheContact = (id) => {
    setBlockId(id);
    setOpen(true);
  };

  const renderAction = (data) => (
    <Button
      sx={{ ml: 2 }}
      size="small"
      color="primary"
      style={{ backgroundColor: "red", color: "#fff" }}
      onClick={() => blockTheContact(data?.id)}
    >
      BLOCK
    </Button>
  );

  return (
    <>
      {open && (
        <BlockedReason blockId={blockId} open={open} setOpen={setOpen} />
      )}
      {loading ? (
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <Box sx={{ overflow: "auto" }}>
          <DataGrid
            columns={columns(renderAction)}
            rows={voiceCallResults}
            pageSize={Number(meta?.per_page)}
            onPageChange={handleChangePage}
            onPageSizeChange={handleChangeRowsPerPage}
            paginationMode="server"
            rowCount={meta?.total}
            page={meta?.current_page - 1}
            disableSelectionOnClick
            autoHeight
            rowsPerPageOptions={[2, 10, 20, 50, 100]}
          />
        </Box>
      )}
    </>
  );
};
export default Voicemail;

Voicemail.defaultProps = {
  fileable_type: "Membe",
  fileable_id: "1asfdsfsfdsfdsf",
};
