import { useDispatch, useSelector } from "react-redux";
import {
  REMITTANCE_DATA_LOADING,
  REMITTANCE_DATA_RESULTS,
  CHANGE_PAGE,
} from "redux/actions/reportActions";

import { getAllRemittanceData } from "api/reports";

const useReports = () => {
  const dispatch = useDispatch();
  const reportsReducer = useSelector((state) => state.reports);

  const getRemittanceData = async (params) => {
    dispatch({ type: REMITTANCE_DATA_LOADING, loading: true });
    try {
      const res = await getAllRemittanceData({ ...params });
      dispatch({ type: REMITTANCE_DATA_RESULTS, results: res?.data });
    } finally {
      if (reportsReducer.loading) {
        dispatch({ type: REMITTANCE_DATA_LOADING, loading: false });
      }
    }
  };

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  return {
    getRemittanceData,
    changePage,
    reportsReducer,
  };
};

export default useReports;
