import { SEARCH_JOBS_LOADING } from "redux/actions/jobsActions";
import axios from "utils/axios";

export const ACCEPT_ESTIMATE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/estimate/accept`;
export const CHANGE_JOB_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/change`;
export const REQUEST_ESTIMATE_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/request-estimate`;
export const CL_REQUEST_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/job-request`;
export const GET_JOB_CATEGORIES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/job-categories`;
export const GET_JOB_CODES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/utils/job-codes`;
export const JOB_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs`;
export const JOB_SEARCH_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/list`;
export const UPDATE_JOB_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs`;
export const JOB_ESTIMATES_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/:job_id/estimates`;
export const JOB_DISPATCH_REQUEST_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/dispatch-request`;
export const REMOVE_CONTRACTOR_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/remove-contractor`;
export const NEW_JOBS = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs/open-jobs`;
export const GET_JOB_ROUTE = `${process.env.REACT_APP_API_DOMAIN}/api/v1/jobs`;

export const getCategoriesRequest = () => axios.get(GET_JOB_CATEGORIES_ROUTE);

export const deleteJobRequest = (params, uuid = null) =>
  axios.delete(`${UPDATE_JOB_ROUTE}/delete/id?id=${params}`);

export const getEstimatesRequest = (job_id) =>
  axios.get(JOB_ESTIMATES_ROUTE.replace(":job_id", job_id));

export const getCodesRequest = (category_id) =>
  axios.get(`${GET_JOB_CODES_ROUTE}/${category_id}`);

export const createJobRequest = (data) => axios.post(`${JOB_ROUTE}`, data);

export const getJobsRequest = (params, page = 1, per_page = 10) => {
  const paginationParams = {
    page,
    per_page,
  };
  return axios
    .get(JOB_SEARCH_ROUTE, {
      params: { ...params, ...paginationParams },
    })
    .then((response) => {
      return response;
    });
};

export const changeJobStatusRequest = (data) =>
  axios.post(`${JOB_ROUTE}/change-status`, data);

export const getJob = (id) => axios.get(`${JOB_ROUTE}/${id}`);

export const acceptEstimate = (data) => axios.post(ACCEPT_ESTIMATE, data);

export const changeJobRequest = (id, data) =>
  axios.post(`${CHANGE_JOB_ROUTE}/${id}`, { fields: data });

export const jobDispatchRequest = (id, data) =>
  axios.post(`${JOB_DISPATCH_REQUEST_ROUTE}/${id}`, data);

export const sendEstimateRequest = (id, data) =>
  axios.post(`${REQUEST_ESTIMATE_ROUTE}/${id}`, data);
export const jobRequestByClient = (data) =>
  axios.post(`${CL_REQUEST_ROUTE}`, data);
export const updateJobRequest = (id, data) =>
  axios.post(`${UPDATE_JOB_ROUTE}/${id}`, data);

export const removeContractorRequest = (job_id) =>
  axios.post(`${REMOVE_CONTRACTOR_ROUTE}/${job_id}`);

export const viewJobRequest = (id) => axios.get(`${GET_JOB_ROUTE}/${id}`);

export const emailJobRequest = (id) =>
  axios.get(`${GET_JOB_ROUTE}/send-email/${id}`);

export const downloadJobRequest = (id) =>
  axios.get(`${GET_JOB_ROUTE}/get-pdf/${id}`, {
    responseType: "blob",
  });

export const newJobsRequest = (params, page, perPage) => {
  return (dispatch) => {
    dispatch({
      type: SEARCH_JOBS_LOADING,
      loading: true,
    });
    return axios.get(NEW_JOBS, {
      params: {
        ...params,
        page,
        per_page: perPage,
      },
    });
  };
};
