import React, { useEffect, useState } from "react";
import { Button, Box, Tab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PaperBlock from "components/PaperBlock";
import SearchInput from "components/SearchInput";
import useJobs from "hooks/useJobs";
import CreateJobModal from "components/CreateJobModal";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import JobsData from "./components/JobsData";
import OpenJobsData from "./components/OpenJobsData";
import { useLocation } from "react-router-dom";
import useApplication from "hooks/useApplication";

const Members = () => {
  const [tabValue, setTabValue] = useState("0");
  const location = useLocation();
  const { setPage } = useApplication();

  useEffect(() => {
    if (location.state?.tabValue) {
      setTabValue(location.state.tabValue);
    }
  }, [location.state]);
  const [open, setOpen] = useState(false);
  const {
    jobsReducer: { query },
    getJobsData,
    getOpenJobs,
  } = useJobs();

  useEffect(() => {
    setPage({
      title: "Jobs Directory",
      path: "/app/jobs",
    });
    fetchJons();
    // eslint-disable-next-line
  }, []);

  const fetchJons = () => {
    getJobsData(query);
  };
  useEffect(() => {
    getJobsData(query);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (search) => {
    getJobsData({
      search,
    });
    getOpenJobs({
      search,
    });
  };

  return (
    <PaperBlock
      title="Jobs Directory"
      actions={
        <>
          <SearchInput
            defaultValue={query}
            onChange={handleSearch}
            placeholder="By ID, First Name, Last Name, Phone or Email"
          />
          <Button
            sx={{ ml: 2, whiteSpace: "nowrap", minWidth: "auto" }}
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            Add Job Request
          </Button>

          {open && (
            <CreateJobModal
              open={open}
              handleClose={() => setOpen(false)}
              fetchJons={fetchJons}
            />
          )}
        </>
      }
    >
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={(_event, newValue) => setTabValue(newValue)}>
              <Tab label="Jobs" value="0" />
              <Tab label="Open Jobs" value="1" />
            </TabList>
          </Box>
          <TabPanel value="0">
            <JobsData fetchJons={fetchJons} />
          </TabPanel>
          <TabPanel value="1">
            <OpenJobsData />
          </TabPanel>
        </TabContext>
      </Box>
    </PaperBlock>
  );
};
export default Members;
