export const SET_ESTIMATES_VALUES = "app/task/ESTIMATES/SET_VALUES";
export const CHANGE_ESTIMATES_VALUES =
  "app/task/ESTIMATES/CHANGE_ESTIMATES_VALUES";
export const ESTIMATES_RESET = "app/task/ESTIMATES/ESTIMATES_RESET";
export const QUEUE_SET_DATA = "app/QUEUE_SET_DATA";
export const CHANGE_TASK_STATUS = "app/CHANGE_TASK_STATUS";
export const CHANGE_TASK_USER = "app/CHANGE_TASK_USER";
export const SEARCH_TASK = "app/SEARCH_TASK";
export const QUEUE_CHANGE_PROP = "app/QUEUE_CHANGE_PROP";
export const QUEUE_CHANGE_PROP_MULTIPLE = "app/QUEUE_CHANGE_PROP_MULTIPLE";
export const QUEUE_VIEW_TASK = "app/QUEUE_VIEW_TASK";
export const CHANGE_TASK_VALUE = "app/CHANGE_TASK_VALUE";
export const CHANGE_PAGE = "app/CHANGE_PAGE";
export const CHANGE_PER_PAGE = "app/CHANGE_PER_PAGE";
export const SEARCH_TASK_LOADING = "app/SEARCH_TASK_LOADING";
export const NEW_TASK = "app/NEW_TASK";
export const CLOSED_TASKS = "app/CLOSED_TASKS";
export const MY_TASKS = "app/MY_TASKS";
export const SEARCH_TASKQUEUE_RESULTS = "app/SEARCH_TASKQUEUE_RESULTS";
