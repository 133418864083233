import { useDispatch, useSelector } from "react-redux";
import {
  SEARCH_VENDORS,
  SEARCH_VENDORS_RESULTS,
  CHANGE_PAGE,
  CHANGE_PER_PAGE,
  SEARCH_VENDORS_LOADING,
} from "redux/actions/vendors";
import { getVendorsRequest, getVendorRequest } from "api/vendors";
import debounce from "lodash.debounce";

const useVendors = () => {
  const dispatch = useDispatch();
  const vendorsReducer = useSelector((state) => state.vendors);

  const getVendors = (params) => {
    dispatch({ type: SEARCH_VENDORS_LOADING, loading: true });
    return getVendorsRequest(params)
      .then((res) => {
        dispatch({ type: SEARCH_VENDORS_RESULTS, results: res.data });
      })
      .finally(() => {
        if (vendorsReducer.loading) {
          dispatch({ type: SEARCH_VENDORS_LOADING, loading: false });
        }
      });
  };

  const search = debounce((query) => {
    dispatch({ type: SEARCH_VENDORS, query });
    getVendorsRequest({ search: query }).then((res) => {
      dispatch({ type: SEARCH_VENDORS_RESULTS, results: res.data });
    });
  }, 600);

  const changePage = (newPage) => {
    dispatch({ type: CHANGE_PAGE, page: newPage + 1 });
  };

  const changePerPage = (event) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(event.target.value) });
  };

  const getVendor = (vendorId) => getVendorRequest(vendorId);
  const updateVendor = (vendorId) => getVendorRequest(vendorId);
  const deleteVendor = (vendorId) => getVendorRequest(vendorId);

  return {
    vendorsReducer,
    getVendors,
    getVendor,
    updateVendor,
    deleteVendor,
    search,
    changePage,
    changePerPage,
  };
};

export default useVendors;
