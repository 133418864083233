import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, Button } from "@mui/material";
import useMembership from "hooks/useMembership";
import Swal from "sweetalert2";
import { deleteMemberRequest } from "api/membership";
import { DataGrid } from "@mui/x-data-grid";
import columns from "./MemberColumnData";
import useAccessControl from "hooks/useAccessControl";
import useStyles from "components/TaskPreview/styles-jss";
import { CHANGE_PER_PAGE } from "redux/actions/membersActions";
import { useDispatch } from "react-redux";

const Member = () => {
  const { canUpdateMember } = useAccessControl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const {
    search,
    getMemberships,
    membershipReducer: { results, loading, query, meta },
    changePage,
  } = useMembership();

  useEffect(() => {
    getMemberships({
      search: query,
      page: meta.current_page,
      per_page: meta.per_page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (newPage) => {
    changePage(newPage);
    getMemberships({
      search: query,
      page: newPage + 1,
      per_page: meta.per_page,
    });
  };

  const handleChangeRowsPerPage = (newPageSize) => {
    dispatch({ type: CHANGE_PER_PAGE, perPage: parseInt(newPageSize) });
    getMemberships({
      search: query,
      page: meta.current_page,
      per_page: newPageSize,
    });
  };

  const deleteMemeberHandler = (membership_id) => {
    try {
      Swal.fire({
        title: "Cancel member",
        text: "Are you sure you wish to continue?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        customClass: {
          title: "swal-text",
          popup: "swal-text",
        },
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Cancel it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteMemberRequest(membership_id)
            .then(() => {
              search();
              Swal.fire({
                toast: true,
                timer: 3000,
                position: "top-right",
                title: "Member Canceled Successfully!",
                showConfirmButton: false,
                icon: "success",
              });
            })
            .catch((e) => {});
        }
        navigate("/app/members");
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  const MemberResults = results?.map((memberData) => ({
    id: memberData.id,
    name: memberData.name,
    address: memberData.address,
    cell_phone: memberData.cell_phone,
    email: memberData.email,
    status: memberData.status,
    membership_id: memberData.membership_id,
  }));

  const renderAction = (data) => (
    <div style={{ display: "flex", mt: 1.5 }}>
      {canUpdateMember() && (
        <Button
          variant="contained"
          color="primary"
          size="small"
          sx={{ mr: 2.0 }}
          onClick={() => navigate(`/app/members/${data.row.membership_id}`)}
        >
          View
        </Button>
      )}
      <Button
        variant="contained"
        color="error"
        size="small"
        onClick={() => deleteMemeberHandler(data.id)}
      >
        Cancel
      </Button>
    </div>
  );

  return (
    <>
      {loading ? ( // Display loader when data is being fetched
        <CircularProgress style={{ margin: "20px auto", display: "block" }} />
      ) : (
        <>
          <div style={{ overflow: "auto" }}>
            <DataGrid
              columns={columns(renderAction)}
              rows={MemberResults}
              pageSize={Number(meta?.per_page)}
              onPageChange={handleChangePage}
              onPageSizeChange={handleChangeRowsPerPage}
              paginationMode="server"
              rowCount={meta?.total}
              page={meta?.current_page - 1}
              disableSelectionOnClick
              autoHeight
              className={classes.dataGrid}
              rowsPerPageOptions={[2, 10, 20, 50, 100]}
            />
          </div>
        </>
      )}
    </>
  );
};
export default Member;
