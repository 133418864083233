import {
  CHANGE_PAGE_ALL_CALL_LOG,
  SEARCH_MEMBERS_ALL_CALL_LOG,
  SEARCH_MEMBERS_LOADING_ALL_CALL_LOG,
  SEARCH_MEMBERS_RESULTS_ALL_CALL_LOG,
  CHANGE_PER_PAGE,
} from "../actions/allCallLogsAction";

const initialState = {
  loading: true,
  query: "",
  results: [],
  meta: {
    current_page: 1,
    from: null,
    last_page: 1,
    per_page: 10,
    to: null,
    total: 0,
  },
};

const membershipCall = (state = initialState, action = {}) => {
  switch (action.type) {
    case SEARCH_MEMBERS_RESULTS_ALL_CALL_LOG: {
      return {
        ...state,
        results: action.results.result,
        meta: action.results.meta,
        loading: false,
      };
    }
    case SEARCH_MEMBERS_LOADING_ALL_CALL_LOG:
      return {
        ...state,
        loading: action.loading,
      };
    case SEARCH_MEMBERS_ALL_CALL_LOG:
      return {
        ...state,
        query: action.query,
        loading: true,
      };
    case CHANGE_PER_PAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current_page: action.page,
        },
      };
    case CHANGE_PAGE_ALL_CALL_LOG:
      return {
        ...state,
        meta: {
          ...state.meta,
          per_page: action.perPage,
        },
      };
    default:
      return state;
  }
};
export default membershipCall;
