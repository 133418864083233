import Toast from "components/Toast";
import useAuth from "hooks/useAuth";
import useQueue from "hooks/useQueue";
import { useEffect, useState } from "react";
import PusherClient from "./PusherClient";
import BroadcastingContext from "./BroadcastingContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useBatch from "hooks/useBatch";
import ".././../utils/sweetalert.css";

let dataSyncChannel = null;

const Provider = ({ children }) => {
  const { user } = useAuth();
  const { queue, getTasks } = useQueue();
  const { getPendingBatch } = useBatch();
  const navigate = useNavigate();
  const [dataSync, setDataSync] = useState(null);

  useEffect(() => {
    if (!dataSyncChannel) {
      dataSyncChannel = PusherClient.subscribe("data-sync");
      dataSyncChannel.bind("user.created", refreshUsers);
      dataSyncChannel.bind("task.created", refreshTasks);
      dataSyncChannel.bind("task.updated", updateTask);
      dataSyncChannel.bind("batch.event.finished", handleBatchFinished);
      setDataSync(dataSyncChannel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatedNotification = (message) => {
    Toast.fire({
      title: message,
      icon: "success",
    });
  };
  const refreshUsers = () => {};

  const refreshTasks = (data) => {
    if (parseInt(data.user_id) !== parseInt(user.data.id)) {
      getTasks(queue.search, true, () => {
        !data.silent && updatedNotification("Task List Updated");
      });
    }
  };

  const updateTask = (data) => {
    if (parseInt(data.user_id) !== parseInt(user.data.id)) {
      getTasks(queue.search, true, () => {
        !data.silent && updatedNotification("Task List Updated");
      });
    }
  };

  const handleBatchFinished = (data) => {
    if (
      parseInt(data.user_id) !== parseInt(user.data.id) ||
      !data.had_feedback
    ) {
      setTimeout(() => {
        Swal.fire({
          title: "Batch Import Finished",
          text: "You can review the results in the batch invoice list",
          icon: "success",
          showCancelButton: true,
          customClass: {
            title: "swal-text",
            popup: "swal-text",
          },
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "View Invoices",
          cancelButtonText: "Close",
        }).then((result) => {
          if (result.value) {
            navigate(`/app/invoices/batch/${data.batch_id}/process`);
          }
        });
        getTasks(queue.search, true, () => {
          // !data.silent && updatedNotification("Task List Updated");
        });
      }, 1000);
    }
    getPendingBatch();
  };
  return (
    <BroadcastingContext.Provider value={dataSync}>
      {children}
    </BroadcastingContext.Provider>
  );
};

export default Provider;
