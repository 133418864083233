import React, { useEffect, useState } from "react";
import { Box, Table } from "@mui/material";
import "../../../../../../../src/components/Callincoming/index.css";
import { getMemberInvRequest } from "api/membership";
import { useParams } from "react-router-dom";
import useInvoices from "hooks/useInvoices";

export default function BillingTableComponent() {
  const { id } = useParams();
  const { viewInvoice } = useInvoices();
  const [invoiceData, setInvoiceData] = useState([]);
  const [stateData, setStateData] = useState();

  useEffect(() => {
    viewInvoice(id)
      .then((res) => {
        setStateData(res.data);
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
        }
      });
  }, [id, viewInvoice]);
  useEffect(() => {
    getMemberInvRequest(stateData?.membership_id).then((response) => {
      setInvoiceData(response.data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateData]);

  return (
    <Box className="headingtable">
      <Table>
        <tr>
          <td>
            <table style={{ width: "100%" }}>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <img src="http://localhost:3000/images/hoc-logo.png" alt="" />
                </td>
                <td style={{ paddingLeft: "23px" }}>
                  <table>
                    <tr>
                      <td style={{ fontWeight: "600" }}>
                        {invoiceData?.street}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ fontWeight: "600" }}>
                        {invoiceData?.city}, {invoiceData?.pin}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td className="memberInformation">
            <table style={{ width: "100%", marginBottom: "5px" }}>
              <tr>
                <td style={{ width: "70%" }}>
                  <table>
                    <tr className="memberAddress">
                      <td>Account : {invoiceData?.memberID}</td>
                    </tr>
                    <tr className="memberAddress">
                      <td>{invoiceData?.memberName}</td>
                    </tr>
                    <tr className="memberAddress">
                      <td>{invoiceData?.street}</td>
                    </tr>
                    <tr className="memberAddress">
                      <td>
                        {invoiceData?.city}, {invoiceData?.pin}
                      </td>
                    </tr>
                    <tr className="memberAddressStatment">
                      <td>
                        Please return this part of statment and note any change
                        in address or phone number.
                      </td>
                    </tr>
                  </table>
                </td>
                <td style={{ width: "30%" }}>
                  <table className="memberTable">
                    <tr>
                      <td className="date">4/4/2024</td>
                    </tr>
                    <tr>
                      <td>
                        <table className="balance">
                          <tr>
                            <th className="balanceTable"> BALANCE DUE </th>
                          </tr>
                          <tr>
                            <td className="balanceAmount">
                              {" "}
                              ${invoiceData?.totalBalance?.toFixed(2)}{" "}
                            </td>
                          </tr>
                          <tr>
                            <th className="balanceTable"> AMOUNT ENCLOSED</th>
                          </tr>
                          <tr>
                            <td className="balanceAmount">$_______________</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr>
          <td>
            <table className="memberTableRow">
              <thead>
                <tr style={{ backgroundColor: "#116089", color: "#fff" }}>
                  <th>DATE</th>
                  <th>DESCRIPTION</th>
                  <th>CHARGES</th>
                  <th>CREDITS</th>
                  <th>BALANCE</th>
                  <th>SERVICE PERFORMED</th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.jobDetails?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      {
                        new Date(item?.creation_date)
                          .toISOString()
                          .split("T")[0]
                      }
                    </td>
                    <td>{item?.description}</td>
                    <td>{item?.charges}</td>
                    <td>{item?.credit}</td>
                    <td>{item?.balance}</td>
                    <td>Tree/Yard Spray</td>
                  </tr>
                ))}
                <tr style={{ fontWeight: "600" }}>
                  <td></td>
                  <td></td>
                  <td>$ {invoiceData?.totalCharges?.toFixed(2)}</td>
                  <td>$ {invoiceData?.totalCredit?.toFixed(2)}</td>
                  <td>$ {invoiceData?.totalBalance?.toFixed(2)}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      </Table>
    </Box>
  );
}

BillingTableComponent.defaultProps = {
  widthActions: false,
  deleteService: () => {},
  deleteServices: () => {},
  discardNewService: () => {},
  saveNewService: () => {},
  error: "",
};
