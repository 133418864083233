import React from "react";
import { Box } from "@mui/material";

const QBEOauth = () => {
  return (
    <Box>
      Successfully connected to QuickBooks. You can close this popup now.
    </Box>
  );
};
export default QBEOauth;
