import { SET_UTILS, SET_UTILS_MULTIPLE } from "redux/actions";

const initialState = {
  membership_type: [],
  membership_typeLoaded: false,
  serviceLocations: [],
  serviceLocationsLoaded: false,
  states: [],
  statesLoaded: false,
  users: [],
  usersLoaded: false,
  roles: [],
  rolesLoaded: false,
  vendor_types: [],
  vendor_typesLoaded: false,
  categories: [],
  categoriesLoaded: false,
  communication_prefs: [],
  communication_prefsLoaded: false,
  terms: [],
};

const utils = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_UTILS: {
      const newState = { ...state };
      newState[action.prop] = action.values;
      newState[`${action.prop}Loaded`] = true;
      return newState;
    }
    case SET_UTILS_MULTIPLE: {
      const newState = { ...state };
      newState[action.prop] = action.values;
      newState[`${action.prop}Loaded`] = true;
      return newState;
    }
    default:
      return state;
  }
};
export default utils;
