import { TableRow, TableFooter, TablePagination } from "@mui/material";

const TableFooterWithPagination = ({
  count,
  rowsPerPage,
  page,
  onPageChange,
  onRowsPerPageChange,
  colSpan,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          sx={{
            ".MuiTablePagination-toolbar": {
              alignItems: "baseline",
            },
          }}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {
              "aria-label": "rows per page",
            },
            native: true,
          }}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      </TableRow>
    </TableFooter>
  );
};

export default TableFooterWithPagination;
