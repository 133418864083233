import axios from "axios";
import cookies from "js-cookie";
import { uniqueDeviceId } from "./functions";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common.Accept = "application/json";
axios.defaults.headers.common.Timezone =
  Intl.DateTimeFormat().resolvedOptions().timeZone;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(async (config) => {
  let device_id = cookies.get("device_id");
  if (!device_id) {
    device_id = uniqueDeviceId();
    cookies.set("device_id", device_id);
  }
  config.headers["X-Device-ID"] = device_id;
  const token = cookies.get("access_token");
  if (token) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  (resp) => {
    return resp;
  },
  (error) => {
    if (error?.response?.status === 401) {
      // logout
      cookies.remove("access_token");
      cookies.remove("refresh_token");
    }
    return Promise.reject(error);
  }
);

export default axios;
