import React from "react";
import { Typography, Box, Divider, Grid } from "@mui/material";
import { Contacts, InvoiceDetails, PropertyDetails } from "./components";
import TaskStatus from "../TaskStatus";

const MembershipTask = ({ data, handleChange }) => {
  const { members, properties, invoices } = data?.taskable;

  return (
    <Grid container spacing={4}>
      <Grid item sm={8} md={9}>
        <Box mb={2}>
          <Box mb={1}>
            <Typography fontWeight="bold" variant="body1" color="darkgrey">
              Membership Information
            </Typography>
            <Divider width={200} />
          </Box>
        </Box>
        <Box mb={2}>
          <Box mb={1}>
            <Typography fontWeight="bold" variant="body1" color="darkgrey">
              Contacts
            </Typography>
            <Divider width={200} />
          </Box>
          <Contacts members={members} />
        </Box>
        <Box mb={2}>
          <Box mb={1}>
            <Typography fontWeight="bold" variant="body1" color="darkgrey">
              Property Details
            </Typography>
            <Divider width={200} />
          </Box>
          <PropertyDetails properties={properties} />
        </Box>
        <Box>
          <Box mb={1}>
            <Typography fontWeight="bold" variant="body1" color="darkgrey">
              Invoice Details
            </Typography>
            <Divider width={200} />
          </Box>
          <InvoiceDetails invoice={invoices[0]} />
        </Box>
      </Grid>
      <Grid item sm={4} md={3}>
        <TaskStatus data={data} handleChange={handleChange} />
      </Grid>
    </Grid>
  );
};

export default MembershipTask;
