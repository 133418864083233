import React, { useEffect, useState } from "react";
import ContentBlock from "components/ContentBlock";
import useApplication from "hooks/useApplication";
import { Button } from "@mui/material";
import LinkBtn from "components/LinkBtn";
import MemberApplicationContext from "components/MembershipForm/MemberApplicationFormContext";
import {
  contact,
  initialValuesMethoods,
  property,
} from "components/MembershipForm/formConfig";
import { checkZipRequest } from "api/utilities";
import { saveApplicationRequest } from "api/membership";
import useStyles from "./styles-jss";
import MembershipForm from "components/MembershipForm/MembershipForm";
import { useNavigate } from "react-router-dom";
import Toast from "components/Toast";
import * as Yup from "yup";

const memberValidationSchema = Yup.object().shape({
  first_name: Yup.string().required("This field is required"),
  last_name: Yup.string().required("This field is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("This field is required"),
  home_phone: Yup.string(),
  cell_phone: Yup.string().required("This field is required"),
  work_phone: Yup.string(),
  work_phone_extension: Yup.string(),
  occupation: Yup.string(),
  company: Yup.string(),
});

const propertiesValidationSchema = Yup.object().shape({
  address: Yup.string().required("This field is required"),
  state: Yup.string(),
  zip: Yup.string(),
});

const membershipValidationSchema = Yup.object().shape({
  membership_card: Yup.string(),
  newsletter: Yup.string(),
  communications: Yup.string(),
  invoice_preference: Yup.string(),
  membership_type: Yup.string(),
  about_club: Yup.string(),
  mortgage_holder: Yup.string(),
  years_in_home: Yup.string(),
  checking_savings: Yup.string(),
  branch: Yup.string(),
  subscription_type: Yup.string(),
  subscription_no_years: Yup.number(),
  payment_method: Yup.string(),
  paperless_communication: Yup.boolean(),
  terms_acceptance: Yup.boolean("This field is required"),
  mailing_address_as_home: Yup.boolean().required("This field is required"),
  out_of_network: Yup.boolean(),
  date: Yup.string(),
});

const membersFormSchema = Yup.object().shape({
  members: Yup.array().of(memberValidationSchema),
  properties: Yup.array().of(propertiesValidationSchema),
  membership: membershipValidationSchema,
});

const CreateMember = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setPage } = useApplication();
  useEffect(() => {
    setPage({
      title: "Member Application",
      path: "/app/members",
    });
    const fetchZipData = async () => {
      try {
        const response = await checkZipRequest({ zip: state.zip });
        setState({ zipData: response.data.details });
      } catch (error) {
        console.error("Error fetching zip data", error.response?.data);
        setState({ zipData: error.response?.data || {} });
      }
    };

    fetchZipData();
    // eslint-disable-next-line
  }, []);

  const [state, _setState] = useState({
    zip: "98112",
    showModal: false,
    zipData: {},
    formLoading: false,
    completed: false,
  });

  const [validationErrors, setErrors] = useState({});
  const [form, _setForm] = useState(initialValuesMethoods());
  const setForm = (values) => _setForm({ ...form, ...values });
  const setState = (values) => _setState({ ...state, ...values });

  const addMember = () => {
    const _members = form.members;
    _members.push({ ...contact });
    setForm({ members: _members });
  };

  const addProperty = () => {
    const _properties = form.properties;
    _properties.push({ ...property });
    setForm({ properties: _properties });
  };

  const validateField = async (name, value) => {
    try {
      let copy = { ...validationErrors };
      await membersFormSchema.validateAt(name, form);
      delete copy[name];
      setErrors({ ...copy });
    } catch (error) {
      setErrors({ ...validationErrors, [name]: error.message });
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await membersFormSchema.validate(form, { abortEarly: false });
      setState({ formLoading: true });

      const formData = new FormData();
      // Append members data
      form.members.forEach((item, index) => {
        for (let key in item) {
          formData.append(`members[${index}][${key}]`, item[key]);
        }
      });

      form.properties.forEach((item, index) => {
        for (let key in item) {
          if (key === "city") {
            for (let ke in item[key]) {
              formData.append(
                `properties[${index}][${key}][${ke}]`,
                item[key][ke]
              );
            }
          } else {
            formData.append(`properties[${index}][${key}]`, item[key]);
          }
        }
      });
      // Append mailing_address data
      for (let key in form.mailing_address) {
        if (key === "city") {
          for (let ke in form.mailing_address[key]) {
            formData.append(
              `mailing_address[${key}][${ke}]`,
              form.mailing_address[key][ke] || ""
            );
          }
        } else {
          formData.append(
            `mailing_address[${key}]`,
            form.mailing_address[key] || ""
          );
        }
      }
      //Append member contact phone
      for (let key in form.member_contact_phone) {
        if (key === "city") {
          for (let ke in form.member_contact_phone[key]) {
            formData.append(
              `member_contact_phone[${key}][${ke}]`,
              form.member_contact_phone[key][ke] || ""
            );
          }
        } else {
          formData.append(
            `member_contact_phone[${key}]`,
            form.member_contact_phone[key] || ""
          );
        }
      }
      // Append membership data
      delete form.membership.fileURL;
      for (let key in form.membership) {
        formData.append(`membership[${key}]`, form.membership[key]);
      }

      saveApplicationRequest(formData)
        .then((res) => {
          setState({ formLoading: false, completed: res.data.membership });
          if (res.data.membership.invoice?.payment_method === "STRIPE") {
            navigate(`/app/members`);
          } else {
            navigate(`/app/members/${res.data.membership.membership_id}`);
          }
          Toast.fire({
            position: "top-right",
            timer: 4000,
            icon: "success",
            title: "Member Application Completed!",
          });
        })
        .catch((error) => {
          setState({ formLoading: false });
          Toast.fire({
            position: "top-right",
            timer: 4000,
            icon: "error",
            title:
              error?.response?.data?.message || "Email has already been taken.",
          });
        });
    } catch (error) {
      let newErrors = {};
      error &&
        error.inner &&
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      setErrors(newErrors);
    }
  };

  return (
    <ContentBlock
      title="Member Application"
      rightActions={
        <Button
          component={LinkBtn}
          to="/app/members"
          color="primary"
          size="small"
          variant="contained"
          sx={{
            ":hover": { color: "white" },
          }}
        >
          Cancel
        </Button>
      }
    >
      <section className={classes.formSection}></section>
      <section className={classes.formSection}>
        <MemberApplicationContext.Provider
          value={{
            form,
            setForm,
            defaultLocation: state.zipData,
            addMember,
            addProperty,
            handleSubmit,
            validationErrors,
            formLoading: state.formLoading,
            validateField,
          }}
        >
          <MembershipForm />
        </MemberApplicationContext.Provider>
      </section>
    </ContentBlock>
  );
};
export default CreateMember;
