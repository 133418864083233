import { Box, Grid, TextField } from "@mui/material";

const AdditionalFields = ({
  invoice = {},
  onChange = () => {},
  withActions = false,
  errors = {},
}) => {
  return (
    <>
      <Box mb={4}>
        <Box mb={4}>
          Additional Details
          <hr />
        </Box>
        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="RefNumber"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.ref_number || ""}
              onChange={(e) => onChange({ ref_number: e.target.value })}
              error={Boolean(errors.ref_number)}
              helperText={errors.ref_number}
              disabled={!withActions}
            />
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              size="small"
              label="Item"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              value={invoice.item || ""}
              onChange={(e) => onChange({ item: e.target.value })}
              error={Boolean(errors.item)}
              helperText={errors.item}
              disabled={!withActions}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AdditionalFields;
