import React, { useState, useEffect } from "react";
import Icon from "@mdi/react";
import { mdiTextBoxCheckOutline } from "@mdi/js";
import { Paper, Box, Typography, Grid, CircularProgress } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useParams } from "react-router-dom";
import { getInvoiceDetails } from "api/utilities";
import CheckoutForm from "components/CheckoutForm";
import brand from "theme/brand";
import useStyles from "./styles-jss";

const Checkout = () => {
  const classes = useStyles();
  const { order_uuid } = useParams();
  const [invoice, setInvoice] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInvoiceDetails(order_uuid).then((res) => {
      setInvoice(res.data.invoice);

      const property = {};
      const primaryPropertyIndex =
        res.data?.invoice?.membership?.properties.findIndex(
          (property) => property.is_primary === 1
        );
      if (primaryPropertyIndex > -1) {
        const primaryProperty =
          res.data?.invoice?.membership?.properties[primaryPropertyIndex];
        property.address = primaryProperty.address;
        property.city = primaryProperty.city;
        property.city_id = primaryProperty.city.id;
        property.state_code = primaryProperty.state;
        property.zip = primaryProperty.zip;
      }

      const member = {};
      const primaryMemberIndex =
        res.data?.invoice?.membership?.members.findIndex(
          (member) => member.is_primary === 1
        );
      if (primaryMemberIndex > -1) {
        const primaryMember =
          res.data?.invoice?.membership?.members[primaryMemberIndex];
        member.company_name = primaryMember.company;
      }

      setPaymentMethod({ ...res.data.payment_method, ...property, ...member });
      setLoading(false);
    });
  }, [order_uuid]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={"100vh"}
      >
        <CircularProgress color="primary" size={40} />
      </Box>
    );
  }

  const renderDetails = () => {
    return (
      <Box display="flex" justifyContent="space-between" pl={1.5} mt={4}>
        <Box>
          <Typography
            className={classes.caption}
            component="p"
            variant="caption"
          >
            Invoice #HOC {invoice.id}
          </Typography>
          <Typography variant="h5">
            ${(invoice.amount / 100).toFixed(2)} USD
          </Typography>
          <Typography
            className={classes.caption}
            component="p"
            variant="caption"
          >
            To: {paymentMethod.name}
          </Typography>
          <Typography
            className={classes.caption}
            component="p"
            variant="caption"
          >
            From: Home Owners Club
          </Typography>
          <Typography
            className={classes.caption}
            component="p"
            variant="caption"
          >
            Description: {invoice.membership.subscription_no_years} Year
            {invoice.membership.subscription_no_years > 1 ? "s" : ""} Membership
            Dues{" "}
          </Typography>
        </Box>
        <Icon path={mdiTextBoxCheckOutline} size={5} color="lightgray" />
      </Box>
    );
  };
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={classes.userFormWrap}>
          <Paper className={classes.checkoutForm}>
            <Box textAlign="center" borderBottom="solid 1px #ddd" mb={3}>
              <img width={130} src={brand.logo} alt="logo" />
              <Typography
                marginTop={-3}
                paddingBottom={1}
                variant="h6"
                color="primary"
              >
                HOC Membership Application Checkout
              </Typography>
            </Box>
            {loading && (
              <Box
                height={200}
                justifyContent="center"
                display="flex"
                alignItems="center"
              >
                <CircularProgress />
              </Box>
            )}
            {!loading && (
              <>
                <Grid item md={12}>
                  <CheckoutForm
                    invoice={invoice}
                    data={paymentMethod}
                    handleChange={(values) =>
                      setPaymentMethod({ ...paymentMethod, ...values })
                    }
                    invoiceDetails={renderDetails}
                  />
                </Grid>
              </>
            )}
          </Paper>
        </div>
      </div>
    </div>
  );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const StripedCheckout = () => {
  return (
    <Elements stripe={stripePromise}>
      <Checkout />
    </Elements>
  );
};

export default StripedCheckout;
