import React, { useEffect, useState } from "react";
import ContentBlock from "components/ContentBlock";
import { Box, Button, CircularProgress, Table } from "@mui/material";
import { getContractorsInvRequest } from "api/contractors";
import { useParams } from "react-router-dom";
import { images } from "utils/Images.js";
import "../../../../../src/components/Callincoming/index.css";
import Toast from "components/Toast";
import "../../../../components/Callincoming/index.css";
import useInvoices from "hooks/useInvoices";

const ViewInvConreactor = () => {
  const { id, conid } = useParams();
  const [invViewData, setInvViewData] = useState([]);
  const [isPrinting, setIsPrinting] = useState(false);
  const { downloadInvoicePdf } = useInvoices();
  const [downloadLoader, setDownloadLoader] = useState(false);
  useEffect(() => {
    getContractorsInvRequest(conid, id).then((resp) => {
      if (resp.data) {
        setInvViewData(resp.data);
      }
    });
    // eslint-disable-next-line
  }, [id]);

  /* Download contractor invoice PFD file functionality */
  const downloadDocument = async (id) => {
    setDownloadLoader(true);
    try {
      const blob = await downloadInvoicePdf(id);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `contractor-invoice-${id}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      Toast.fire({
        title: "PDF Downloaded Successfully",
        icon: "success",
        position: "top-right",
        timer: 3000,
      });
    } catch (error) {
      console.error("Download failed:", error);
      Toast.fire({
        title: "Download Failed",
        icon: "error",
        position: "top-right",
        timer: 3000,
      });
    } finally {
      setDownloadLoader(false);
    }
  };

  /* View contractoe invoice print functionality */
  const handlePrint = async () => {
    try {
      setIsPrinting(true);
      const invoiceTable = document.querySelector("table");
      const printInvoice = window.open("", "", "height=700,width=700");
      printInvoice.document.write(invoiceTable.outerHTML);
      printInvoice.document.close();
      printInvoice.print();
      printInvoice.close();
    } catch (error) {
      console.error("Error printing document:", error);
    } finally {
      setIsPrinting(false);
    }
  };

  return (
    <>
      <ContentBlock title="View Contractor Invoice ">
        {invViewData?.invoice?.map((invView) => {
          return (
            <React.Fragment key={invView.id}>
              <Table key={invView.id}>
                <tbody>
                  <tr>
                    <td style={{ textAlign: "end" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mb: 2 }}
                        onClick={() => downloadDocument(invView.id)}
                      >
                        Download Contractor Invoice
                        {downloadLoader && (
                          <Box>
                            <CircularProgress color="success" size={16} />
                          </Box>
                        )}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ mb: 2, ml: 2 }}
                        onClick={handlePrint}
                      >
                        Print
                        {isPrinting && (
                          <Box pl={2}>
                            <CircularProgress size={16} />
                          </Box>
                        )}
                      </Button>
                      <table style={{ width: "100%" }}>
                        <tbody>
                          <tr>
                            <td>
                              <table className="invoices_table">
                                <tbody>
                                  <tr>
                                    <td className="invoices_td">
                                      INVOICE NO. : {invView?.hoc_inv_num}
                                    </td>
                                    <td className="invoices_td">
                                      TRACKING NO. : {invView?.tracking || "NA"}
                                    </td>
                                    <td className="invoices_td">
                                      Job NO. : {invView?.job_number || "NA"}
                                    </td>
                                    <td className="invoices_td">CLUB NO.</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <table className="serivecs">
                                <tbody>
                                  <tr>
                                    <td className="serivecs_td">
                                      <table>
                                        <tbody>
                                          <tr>
                                            <th>SERVICE PERFORMANCE FOR:</th>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>MEMBER: </span>
                                              <span>{invView?.mem_name}</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>STREET: </span>
                                              <span>{invView?.mem_street}</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>CITY: </span>
                                              <span>{invView?.mem_city}</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>DATE: </span>
                                              <span>{invView?.inv_date}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td
                                      style={{
                                        width: "50%",
                                        textAlign: "right",
                                      }}
                                    >
                                      <table style={{ width: "100%" }}>
                                        <tbody>
                                          <tr>
                                            <th>SERVICE PERFORMANCE FOR: </th>
                                            <td> {invView?.con_lic}</td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>{invView?.con_biz}</span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>
                                                {invView?.con_addy1},{" "}
                                                {invView?.con_addy2}
                                              </span>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <span>{invView?.con_csz}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table className="tableRow">
                        <thead>
                          <tr
                            style={{
                              backgroundColor: "#116089",
                              color: "#fff",
                            }}
                          >
                            <th>MATERIAL</th>
                            <th>AMOUNT</th>
                            <th>DESCRIPTION OF WORK / LABOR</th>
                            <th>AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          {Array.from({ length: 25 }, (_, index) => {
                            const material =
                              invView[`material_${index + 1}`] || "NA";
                            const materialAmt =
                              invView[`material_amt_${index + 1}`] || "NA";
                            const labor = invView[`labor_${index + 1}`] || "NA";
                            const laborAmt =
                              invView[`labor_amt_${index + 1}`] || "NA";
                            if (material !== "NA" || labor !== "NA") {
                              return (
                                <tr key={`item-${index}`}>
                                  <td>{material}</td>
                                  <td>{materialAmt}</td>
                                  <td>{labor}</td>
                                  <td>{laborAmt}</td>
                                </tr>
                              );
                            }
                            return null; // Return null for rows that should not be rendered
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th className="remark">REMARK: {invView?.remarks}</th>
                      <td style={{ width: "25%" }}>
                        <table style={{ marginRight: "15px" }}>
                          <tbody>
                            <tr>
                              <td>
                                <table className="arrived_table">
                                  <thead>
                                    <tr>
                                      <th>ARRIVED</th>
                                      <th>DEPARTED</th>
                                      <th>TOTAL TIME</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{invView?.time_arrive || "NA"}</td>
                                      <td>{invView?.time_depart || "NA"}</td>
                                      <td>{invView?.time_total || "NA"}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                              <td className="table_img">
                                <img
                                  className=""
                                  src={images?.loginLogo?.url}
                                  alt="logo"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="amountTable">
                        <table>
                          <tbody>
                            <tr>
                              <th>TOTAL LABOR</th>
                              <td>{invView?.inv_total_labor}</td>
                            </tr>
                            <tr>
                              <th> TOTAL MATERIALS</th>
                              <td>{invView?.inv_total_material}</td>
                            </tr>
                            <tr>
                              <th> NET SALE </th>
                              <td>{invView?.inv_net_sale}</td>
                            </tr>
                            <tr>
                              <th> Rate {invView?.tax} TAX</th>
                              <td>
                                {(
                                  (invView?.tax / 100) *
                                  invView?.inv_net_sale
                                ).toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <th className="total-amt">
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "17px",
                                    fontWeight: "500",
                                  }}
                                >
                                  TOTAL AMOUNT $
                                </span>{" "}
                              </th>
                              <td className="total-amt">
                                <span
                                  style={{
                                    color: "white",
                                    fontSize: "17px",
                                    fontWeight: "500",
                                  }}
                                >
                                  {invView?.total}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table style={{ width: "100%" }}>
                  <tbody>
                    <tr>
                      <th className="remark">
                        CSA Comments: {invView?.comment}
                      </th>
                    </tr>
                  </tbody>
                </table>
              </Table>
            </React.Fragment>
          );
        })}
      </ContentBlock>
    </>
  );
};

export default ViewInvConreactor;
