import React from "react";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Box, CircularProgress } from "@mui/material";

const MessageCard = ({ onSend, CellPhone, loading }) => {
  const [message, setMessage] = React.useState("");

  const handleSendMessage = () => {
    if (message.trim() !== "") {
      onSend(message, CellPhone);
      setMessage("");
    }
  };

  return (
    <CardContent>
      <Typography variant="h5" component="div">
        Send a Message
      </Typography>
      <TextField
        label="Type your message"
        variant="outlined"
        fullWidth
        multiline
        rows={3}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        style={{ margin: "10px 0" }}
      />
      <Button
        disabled={loading}
        variant="contained"
        onClick={handleSendMessage}
      >
        Send
        {loading && (
          <Box pl={2}>
            <CircularProgress size={16} />
          </Box>
        )}
      </Button>
    </CardContent>
  );
};

export default MessageCard;
