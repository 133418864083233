import { useState, useRef, useEffect } from "react";
import classNames from "classnames";
import { IconButton, Box, InputAdornment } from "@mui/material";
import FormField from "components/FormField";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";

function SearchInput(props) {
  const classes = useStyles();
  const [searchValue, setValue] = useState("");
  const inputRef = useRef();

  useEffect(() => {
    if (props.defaultValue) {
      setValue(props.defaultValue);
    }
  }, [props.defaultValue]);

  const clearSearch = () => {
    setValue("");
    props.onChange("");
  };

  const onChange = (value) => {
    setValue(value);
    props.onChange(value);
  };

  return (
    <Box className={classNames(classes.displayInput, classes.inputContainer)}>
      <FormField
        value={searchValue || ""}
        placeholder="Search..."
        inputRef={inputRef}
        onChange={(event) => onChange(event.target.value)}
        InputProps={{
          endAdornment: searchValue.length > 0 && (
            <InputAdornment position="end">
              <IconButton
                aria-label="delete"
                onClick={clearSearch}
                color="secondary"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
}

SearchInput.defaultProps = {
  placeholder: "",
  value: null,
};

export default SearchInput;
